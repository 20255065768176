import { ContactType } from "../types/contactTypes";
import { client } from "./AxiosClient";

export const getAllContactsAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_CONTACTS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as ContactType[];
};
