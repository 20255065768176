import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useActiveModalContext } from "../context/ModalContext";

const StyledModalContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 20;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(80%);
  inset: 0;
`;

const StyledModal = styled.div`
  position: fixed;
  border-radius: var(--border-radius-xl);
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 99;
  padding: 0 2rem;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
`;

const StyledTitleClose = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 4rem 2rem 0;

  & h1 {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 700;

    & span {
      font-size: 1.4rem;
      font-weight: 400;
      font-style: italic;
      text-transform: none;
    }
  }
`;

function createWrapperAndAppendToBody(wrapperId: string) {
  if (document.getElementById(wrapperId))
    return document.getElementById(wrapperId) as HTMLDivElement;
  else {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  }
}

function ReactPortal({
  children,
  wrapperId = "react-portal-wrapper",
}: {
  children: React.ReactNode;
  wrapperId: string;
}) {
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );
  useLayoutEffect(() => {
    setWrapperElement(createWrapperAndAppendToBody(wrapperId));
    return () => {
      createWrapperAndAppendToBody(wrapperId)?.remove();
    };
  }, [wrapperId]);
  return wrapperElement ? createPortal(children, wrapperElement) : null;
}

function Modal({
  children,
  isOpen,
  wrapperId,
  title,
  type,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  wrapperId: string;
  title: string;
  type?: string;
}) {
  const nodeRef = useRef(null);
  const { currentModalName } = useActiveModalContext();

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (currentModalName !== wrapperId) return null;

  return (
    <ReactPortal wrapperId={wrapperId}>
      {isOpen && currentModalName && (
        <StyledModalContainer>
          <StyledModal ref={nodeRef}>
            <StyledTitleClose>
              <h1>
                {title} {type && <span>  [{type}] </span>}{" "}
              </h1>
            </StyledTitleClose>
            <div>{children}</div>
          </StyledModal>
        </StyledModalContainer>
      )}
    </ReactPortal>
  );
}
export default Modal;
