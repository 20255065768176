import {
  ArcElement,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Plugin,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import GaugeComponent from "react-gauge-component";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Swiper as SwiperType } from "swiper/types";

import Category from "../../assets/Category.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Plus from "../../assets/Plus.svg?react";
import ProgramsFolder from "../../assets/ProgramsFolder.svg?react";
import QuickLinkImage from "../../assets/QuickLink.svg?react";
import Search from "../../assets/Search.svg?react";
import TickSquare from "../../assets/TickSquare.svg?react";
import TimeSquare from "../../assets/TimeSquare.svg?react";

import {
  MODAL_NAMES,
  PINNED_ITEM_TYPES,
  TASK_STATUS,
} from "../../common/Constants";
import { quickLinksMap } from "../../common/QuickLinksMap";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import Spinner from "../../ui/Spinner";
import ProfileLink from "../navigation/ProfileLink";
import { useProjects } from "../projects/useProjects";
import { useGetAllMilestones } from "./useGetMilestones";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import moment from "moment";
import { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import NoDataCard from "../../ui/NoDataCard";
import { NextButton, PrevButton } from "../../utils/CarouselButtons";
import { getFormattedCurrency } from "../../utils/common";
import { useGetCurrentUser } from "../authentication/useGetCurrentUser";
import "./Swiper.css";
import Modal from "../../ui/Modal";
import CreateEditProject from "../projects/CreateProject";
import { useActiveModalContext } from "../../context/ModalContext";
import { Project } from "../../types/projectTypes";

ChartJS.register(Legend, Tooltip, ArcElement);
// ChartJS.

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 4.2rem 0;
`;

const HomeLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
`;

const QuickLinks = styled.div``;

const SectionHeader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: end;
  margin-bottom: 2rem;
  & h1 {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const QuickLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  justify-items: stretch;
`;

const QuickLink = styled.div<{ $bgColor?: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  height: 14rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--border-radius-xl);
`;

const QuickLinkTitle = styled.h1`
  font-size: 1.2rem;
  margin-top: 1rem;
`;

const FrequentlyVisited = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ReportsTasksAndMilestones = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
`;

const ReportsContainer = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: var(--border-radius-xl);
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const TitleSeeAll = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GaugeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ProgressContainer = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  gap: 2rem;
  align-items: center;

  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const CompletedDiv = styled.div`
  border-right: 3px dashed #ebeff6;
`;

const CountDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  & p {
    font-size: 4rem;
    line-height: 1;
  }
  align-items: center;
`;

const CountSpan = styled.span`
  font-size: 1rem;
  color: var(--color-grey-300);
`;

const ViewAllLink = styled(Link)`
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--color-button-secondary);
  justify-self: end;
`;

const OverdueDiv = styled.div`
  & span {
    color: red;
    font-weight: 600;
  }
`;

const ProgramsAndProjects = styled.div`
  background-color: white;
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ProgramDetails = styled.div`
  border-top: 3px dashed #ebeff6;
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 7fr 1.3fr;
  gap: 1rem;
`;

const ProgramPercentage = styled.div`
  height: 6rem;
  width: 7rem;
`;

const ProgramTitleDesc = styled.div`
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 1.6rem;
    font-weight: 600;
  }

  & p {
    font-size: 1.2rem;
  }
`;

const ProgramDuration = styled.div`
  display: flex;
  flex-direction: column;
  & h1 {
    font-size: 2rem;
    font-weight: 600;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const ProjectsContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
  position: relative;
`;

const EachProject = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  background-color: var(--color-projects);
  cursor: pointer;
`;

const ProjectDetails = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ProgramFlex = styled(FlexContainer)`
  gap: 0.5rem;
`;

const ProjectTitle = styled(ProgramFlex)`
  font-size: 1.2rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
  }
  gap: 0.8rem;
`;

const ProjectFinancials = styled(ProgramFlex)`
  font-size: 1.2rem;
  & h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
  }
  & span {
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-grey-500);
  }
`;

const StatusSpan = styled.span`
  color: var(--color-in-progress);
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span`
  height: 0.8rem;
  width: 0.8rem;
  // todo: create a map for status colors
  background-color: var(--color-in-progress);
  border-radius: var(--border-radius-2l);
`;

const ChartDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
`;

const SliderButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0;
`;

/**
 * @description Used to render the home page and it's contents
 * @returns Home overview
 */
const HomeOverview = () => {
  const navigate = useNavigate();
  const { setCurrentModalName } = useActiveModalContext();

  const [isNewProjectOpen, setIsNewProjectOpen] = useState(false);

  // swiper state
  const [swiperControl, setSwiperControl] = useState<SwiperType>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  // API data
  const { isMilestonesLoading, milestones } = useGetAllMilestones();
  const {
    isLoading: isProjectsLoading,
    projects,
    error: projectsError,
  } = useProjects();

  const { user } = useGetCurrentUser();

  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || ""
  );

  const pinnedProjectIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.PROJECT)
        return { value: item.value, id: item.id };
    }) || [];

  const projectOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        title: { padding: 10, display: true },
        labels: {
          boxHeight: 12,
          boxWidth: 12,
          color: "#22252A",
          font: {
            size: 10,
          },
          usePointStyle: true,
        },
      },
    },
    maintainAspectRatio: false,
    cutout: "60%",
  };

  const programData = {
    datasets: [
      {
        // todo: change to actual data from project
        data: [75, 25],
        backgroundColor: ["#FE642B", "#D9E1E1"],
      },
    ],
  };

  const programOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    rotation: 90,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const textCenter: Plugin<"doughnut"> = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = "8px";
      ctx.fillStyle = "#22252A";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${data.datasets[0].data[0]}%`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };

  const getTextCenter = (text: string | number) => {
    const textCenter: Plugin<"doughnut"> = {
      id: "textCenter",
      beforeDatasetsDraw(chart) {
        const { ctx } = chart;
        ctx.save();
        ctx.font = "18px bolder Rubik";
        ctx.fillStyle = "#22252A";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          `${text}%`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
      },
    };
    return textCenter;
  };

  const tasks =
    (milestones?.length &&
      milestones
        .map((milestone) => milestone.tasks)
        ?.flat()
        ?.filter((task) => !task.is_deleted)) ||
    [];

  const handleLinkClick = (pathName: string) => {
    navigate(pathName);
  };

  if (isMilestonesLoading || isProjectsLoading || isPinnedItemsLoading)
    return <Spinner />;

  if (projectsError) throw new Error("Unable to fetch projects, please retry.");

  const finalProjects = projects?.filter((project) =>
    pinnedProjectIds.some((item) => item?.value && +item.value === project.id)
  );

  return (
    <>
      {/* todo: look at making side container, project header reusable */}
      <ProjectHeader>
        <h1>Home</h1>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA
            $buttonType="secondary"
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.PROJECT);
              setIsNewProjectOpen(true);
            }}
          >
            <span>new project</span>
            <Plus />
          </ButtonCTA>
          <Modal
            isOpen={isNewProjectOpen}
            title="Create Project"
            wrapperId={MODAL_NAMES.PROJECT}
          >
            <CreateEditProject
              handleClose={() => {
                setCurrentModalName("");
                setIsNewProjectOpen(false);
              }}
              isEditingMode={false}
            />
          </Modal>
        </SideContainer>
      </ProjectHeader>
      <HomeLayout>
        <FrequentlyVisited>
          <QuickLinks>
            <SectionHeader>
              <QuickLinkImage />
              <h1>Frequently Visited</h1>
            </SectionHeader>
            <QuickLinksContainer>
              {quickLinksMap.map((link, index) => {
                const Comp = link.imageComponent();
                return (
                  <QuickLink
                    key={index}
                    onClick={() => handleLinkClick(link.to)}
                  >
                    <Comp width={"3.2rem"} height={"3.2rem"} />
                    <QuickLinkTitle>{link.title + ` >`}</QuickLinkTitle>
                  </QuickLink>
                );
              })}
            </QuickLinksContainer>
          </QuickLinks>
          <ReportsTasksAndMilestones>
            <ReportsContainer>
              <TitleSeeAll>
                <h1>REPORTS</h1>
                <ViewAllLink to="/reports">{"See All >"}</ViewAllLink>
              </TitleSeeAll>
              {/* todo: get value from API */}
              <GaugeContainer>
                <GaugeComponent
                  // style={{ width: "90%" }}
                  value={15}
                  type="radial"
                  arc={{
                    colorArray: ["#EA4228", "#5BE12C"],
                    subArcs: subArcLimits(),
                    padding: 0.06,
                    width: 0.15,
                  }}
                  pointer={{
                    type: "arrow",
                    color: "#22252A",
                    width: 10,
                    animate: false,
                  }}
                  labels={{
                    valueLabel: {
                      style: {
                        fontSize: "20px",
                        fill: "#22252A",
                        textShadow: "none",
                      },
                    },
                    tickLabels: { hideMinMax: true },
                  }}
                />
              </GaugeContainer>
            </ReportsContainer>
            <FlexContainer>
              <ProgressContainer>
                <h1>Milestones</h1>
                <ViewAllLink to="/milestones">{"See All >"}</ViewAllLink>
                <CompletedDiv>
                  <CountDiv>
                    <TickSquare />
                    <p>
                      {
                        milestones?.filter(
                          (milestone) =>
                            milestone.status.title === TASK_STATUS.INPROGRESS
                        ).length
                      }
                    </p>
                  </CountDiv>
                  <CountSpan>IN-PROGRESS</CountSpan>
                </CompletedDiv>
                <OverdueDiv>
                  <CountDiv>
                    <TimeSquare />
                    <p>
                      {
                        milestones?.filter(
                          (milestone) =>
                            moment(milestone.end_year).isBefore(
                              moment(),
                              "days"
                            ) &&
                            ![
                              TASK_STATUS.CANCELED,
                              TASK_STATUS.COMPLETED,
                            ].includes(milestone.status.title)
                        ).length
                      }
                    </p>
                  </CountDiv>
                  <CountSpan>OVERDUE</CountSpan>
                </OverdueDiv>
              </ProgressContainer>
              <ProgressContainer>
                <h1>Tasks</h1>
                <ViewAllLink to="/tasks">{"See All >"}</ViewAllLink>
                <CompletedDiv>
                  <CountDiv>
                    <TickSquare />
                    <p>
                      {
                        tasks.filter(
                          (task) => task.status.title === TASK_STATUS.INPROGRESS
                        ).length
                      }
                    </p>
                  </CountDiv>
                  <CountSpan>IN-PROGRESS</CountSpan>
                </CompletedDiv>
                <OverdueDiv>
                  <CountDiv>
                    <TimeSquare />
                    <p>
                      {
                        tasks.filter(
                          (task) =>
                            moment(task.due_date).isBefore(moment(), "days") &&
                            ![
                              TASK_STATUS.CANCELED,
                              TASK_STATUS.COMPLETED,
                            ].includes(task.status.title)
                        ).length
                      }
                    </p>
                  </CountDiv>
                  <CountSpan>OVERDUE</CountSpan>
                </OverdueDiv>
              </ProgressContainer>
            </FlexContainer>
          </ReportsTasksAndMilestones>
        </FrequentlyVisited>
        <SwiperContainer>
          <TitleSeeAll>
            {/* todo: fetch programs data from API */}
            <SectionHeader>
              <Category />
              <h1>Pinned Projects[{finalProjects?.length}]</h1>
            </SectionHeader>
            <ViewAllLink to="/projects" style={{marginBottom: '2rem'}}>{"See All >"}</ViewAllLink>
          </TitleSeeAll>
          {/* Main Carousel for Programs */}
          {finalProjects?.length ? (
            <ProgramsAndProjects>
              <ProjectsContainer>
                <Swiper
                  cssMode
                  navigation
                  modules={[Navigation]}
                  className="mySwiper1"
                  slidesPerView={1}
                  onSwiper={(swiper) => setSwiperControl(swiper)}
                  onSlideChange={() =>
                    setActiveSlideIndex(swiperControl?.activeIndex || 0)
                  }
                  id="home-swiper"
                  loop={
                    finalProjects?.length ? finalProjects.length > 1 : false
                  }
                  enabled={finalProjects?.length > 1}
                >
                  {finalProjects?.map((project) => (
                    <SwiperSlide key={project.id}>
                      <EachProject
                        onClick={() =>
                          navigate(`/projects/overview/${project.id}`)
                        }
                      >
                        <ProjectDetails>
                          <ProjectTitle>
                            <h1>{project.title}</h1>
                            <h1>{project.project_definition}</h1>
                            <p>{project.project_type.title}</p>
                          </ProjectTitle>
                          <ProjectFinancials>
                            <p>FINANCIALS</p>
                            <h1>
                              {getFormattedCurrency(
                                getProjectFinancialData(project).actualAmount
                              )}{" "}
                              <span>{`/${getFormattedCurrency(
                                getProjectFinancialData(project).budgetAmount
                              )}`}</span>
                            </h1>
                          </ProjectFinancials>
                        </ProjectDetails>
                        <ChartDiv>
                          <Doughnut
                            data={getProjectFinancialPieData(project)}
                            options={projectOptions}
                            plugins={[
                              getTextCenter(project.percentage_complete || 0),
                            ]}
                            height={225}
                            width={225}
                          />
                        </ChartDiv>
                      </EachProject>
                    </SwiperSlide>
                  ))}
                  <SliderButtonDiv>
                    <PrevButton
                      onClick={() =>
                        swiperControl?.slideTo(activeSlideIndex - 1)
                      }
                    />
                    <NextButton onClick={() => swiperControl?.slideNext()} />
                  </SliderButtonDiv>
                </Swiper>
              </ProjectsContainer>
              <div>
                <TitleSeeAll>
                  {/* todo: fetch programs data from API, per individual program */}
                  <SectionHeader>
                    <ProgramsFolder />
                    <h1>Related Program</h1>
                  </SectionHeader>
                  <ViewAllLink to="/programs" style={{marginBottom: '2rem'}}>{"See All >"}</ViewAllLink>
                </TitleSeeAll>
                <ProgramDetails>
                  <ProgramPercentage>
                    <ChartDiv>
                      <Doughnut
                        data={programData}
                        options={programOptions}
                        plugins={[textCenter]}
                        height={60}
                        width={70}
                      />
                    </ChartDiv>
                  </ProgramPercentage>
                  <ProgramTitleDesc>
                    <h1>Strategic and Integrated Planning and Reporting</h1>
                    <p>
                      A suite of applications to develop and manage the
                      organization's strategic planning, financial management,
                      asset management and workforce planning. To provide
                      transparency and demonstrate fiscal sustainability.
                    </p>
                  </ProgramTitleDesc>
                  <ProgramFlex>
                    <ProgramDuration>
                      <h2>TIME SPENT</h2>
                      <h1>320d</h1>
                    </ProgramDuration>
                    <ProgramDuration>
                      <h2>STATUS</h2>
                      <StatusP>
                        <StatusCircle />
                        <StatusSpan>On-Time</StatusSpan>
                      </StatusP>
                    </ProgramDuration>
                  </ProgramFlex>
                </ProgramDetails>
                {/* <NoDataCard title="Related Program" createLink="/programs/create"/> */}
              </div>
            </ProgramsAndProjects>
          ) : (
            <NoDataCard
              title="Pinned projects"
              createLink="/projects"
              height="90%"
            />
          )}
        </SwiperContainer>
      </HomeLayout>
    </>
  );
};

export default HomeOverview;

const subArcLimits = () => {
  const steps = 10;
  const subArcs = [];
  for (let i = 1; i <= steps; i++) {
    subArcs.push({ limit: i * 10 });
  }
  return subArcs;
};

const getProjectFinancialData = (project: Project | undefined) => {
  const financials = project?.financials || [];

  const budgetAmount = financials?.reduce(
    (acc, item) => (item.budgeted_amount || 0) + acc,
    0
  );

  const commitedAmount = financials?.reduce(
    (acc, item) => (item.commited_amount || 0) + acc,
    0
  );

  const actualAmount = financials?.reduce(
    (acc, item) => (item.actual_amount || 0) + acc,
    0
  );

  return { budgetAmount, commitedAmount, actualAmount };
};
const getProjectFinancialPieData = (project: Project | undefined) => {
  const { actualAmount, commitedAmount, budgetAmount } =
    getProjectFinancialData(project);

  const projectData = {
    labels: ["Actual", "Committed", "Balance"],
    datasets: [
      {
        // todo: change to actual data from project
        data: [actualAmount, commitedAmount, budgetAmount - actualAmount],
        backgroundColor: ["#6A6E77", "#FF5500", "#FC90F7"],
      },
    ],
  };

  return projectData;
};
