import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type ActiveModalContextType = {
  currentModalName: string;
  setCurrentModalName: React.Dispatch<React.SetStateAction<string>>;
};

const defaultActiveModalContext = {
  currentModalName: "",
  setCurrentModalName: () => {},
};

export const ActiveModalContext = createContext<ActiveModalContextType>(
  defaultActiveModalContext
);

export const ActiveModalContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [currentModalName, setCurrentModalName] = useState("");
  return (
    <ActiveModalContext.Provider
      value={{ currentModalName, setCurrentModalName }}
    >
      {children}
    </ActiveModalContext.Provider>
  );
};

export const useActiveModalContext = () => {
  const context = useContext(ActiveModalContext);
  return context;
};
