export const SORT_OPTIONS = {
  PAST: "Past",
  RECENT: "Recent",
  PRIORITY: "Priority",
};

export const DEFAULT_SORT_OPTIONS = [
  { id: "1", name: SORT_OPTIONS.RECENT },
  { id: "2", name: SORT_OPTIONS.PAST },
  { id: "3", name: SORT_OPTIONS.PRIORITY },
];

export const BUTTON_SIZES = {
  SMALL: "small",
  LARGE: "large",
  MEDIUM_LARGE: "medium",
};

export const BUTTON_TYPES = {
  SECONDARY: "secondary",
};

export const TASK_STATUS = {
  TODO: "Not Started",
  PENDING: "Pending",
  COMPLETED: "Complete",
  INPROGRESS: "In Progress",
  HOLD: "On Hold",
  ASSIGNED: "Assigned",
  CANCELED: "Cancelled",
};

export const QUERY_KEYS = {
  PROJECT: "project",
  PROJECTS: "projects",
  PROJECT_TYPE: "project-type",
  TASKS: "tasks",
  MILESTONES: "milestones",
  RELATED_PROJECTS: "related-projects",
  PINNED_ITEMS: "pinned-items",
  DROPDOWN_ITEMS: "dropdown-items",
  CONTACTS: "contacts",
  STATUS: "status",
  SERVICE: "service",
  PROJECT_SCOPE: "project-scope",
  PROJECT_SUCCESS: "project-success",
  PROJECT_DETAILS: "project-details",
  PROJECT_STAKEHOLDERS: "project-stakeholders",
  PROJECT_RISKS: "project-risks",
  PROJECT_IMPACTS: "project-impacts",
  PROJECT_CONSULTATION: "project-consultation",
  PROJECT_WORKFORCE: "project-workforce",
  PROJECT_FINANCE: "project-finance",
};

export const PINNED_ITEM_TYPES = {
  PROJECT: "project",
  MILESTONE: "milestone",
  TASK: "task",
  SUBTASK: "subtask",
};

export const DROPDOWN_CATEGORIES = {
  FUNDING_SOURCE: "Funding source",
  EXPENSE_TYPE: "Expense type",
  RESOURCE_TYPE: "Resource types",
  RISK_RATINGS: "Risk ratings",
  STAKEHOLDERS: "Stakeholders",
  PROJECT_TYPE: "Project types",
  RISK_TYPE: "Risk types",
  PROJECT_STATUS_TYPE: "Project status types",
};

export const PROJECT_DETAILS_API_VALUES = {
  SCOPE: "VITE_PROJECT_SCOPE",
  GOALS: "VITE_PROJECT_GOALS",
  ASSUMPTION: "VITE_PROJECT_ASSUMPTION",
  DEPENDENCY: "VITE_PROJECT_DEPENDENCY",
  CONSTRAINT: "VITE_PROJECT_CONSTRAINT",
  SUCCESS: "VITE_PROJECT_SUCCESS",
  SOLUTION: "VITE_PROJECT_SOLUTION",
  BACKGROUND: "VITE_PROJECT_BACKGROUND",
};

export const MODAL_NAMES = {
  PROJECT: "PROJECT",
  SCOPE: "SCOPE",
  GOALS: "GOALS",
  ASSUMPTIONS: "ASSUMPTIONS",
  DEPENDENCIES: "DEPENDENCIES",
  CONSTRAINTS: "CONSTRAINTS",
  SUCCESS: "SUCCESS CRITERIA",
  SOLUTIONS: "SOLUTIONS",
  STAKEHOLDERS: "STAKEHOLDERS",
  CONSULTATION: "CONSULTATION",
  RISKS: "RISKS",
  IMPACTS: "IMPACTS",
  WORKFORCE: "WORKFORCE",
  FINANCE: "FINANCIALS",
  MILESTONES: "MILESTONES",
  TASKS: "TASKS",
  RATIONALE: "BACKGROUND/ RATIONALE",
};

export const DATE_MODAL_NAMES = {
  EST_START: 'est-start',
  EST_END: 'est-end',
  START: "start-date",
  END: "end-date",
};

export const DAY_FORMATS = {
  YEAR_FIRST: "YYYY-MM-DD",
  DAY_FIRST: "DD-MM-YYYY",
};

export const MODAL_TYPE = {
  NEW: "New",
  EDIT: "Edit",
};
