import styled from "styled-components";

/**
 * @description use this for buttons that are used as toggles
 * @param $buttonSize - optional, default 13rem, small - 10.3rem
 * @param $buttonActive - optional, if true BG color - #22252A
 */
const ToggleButton = styled.button<ButtonProps>`
  width: ${(props) =>
    props.$buttonSize === "small"
      ? "10.3rem"
      : props.$buttonSize === "xl"
      ? "19rem"
      : "13rem"};
  height: 4rem;
  color: ${(props) => (props.$buttonActive ? "white" : "#7E869B")};
  background-color: ${(props) =>
    props.$buttonActive ? "#22252A" : "transparent"};
  border-radius: var(--border-radius-2l);
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  font-size: 1.4rem;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0 0.5rem;
`;

type ButtonProps = {
  $buttonSize?: string;
  $buttonActive?: boolean;
};

export default ToggleButton;
