import styled from "styled-components";

/**
 * @description for all Icons 48x48 size across the app
 * @param $bgColor - default white
 * @param disabled - default false
 */
const Icons = styled.div<{
  $bgColor?: string;
  disabled?: boolean;
  $height?: string;
  $width?: string;
  $borderColor?: string;
}>`
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "white")};
  border-radius: var(--border-radius-max);
  border: ${(props) =>
    props.$borderColor ? `2px solid ${props.$borderColor}` : ""};
  min-height: 4.8rem;
  min-width: 4.8rem;
  height: ${(props) => (props.$height ? props.$height : "4.8rem")};
  width: ${(props) => (props.$width ? props.$width : "4.8rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  & svg {
    &:hover {
      transform: scale(1.07);
    }
  }
`;

export default Icons;
