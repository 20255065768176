import Filter from "../../assets/Filter.svg?react";
import More from "../../assets/More.svg?react";
import Notification from "../../assets/Notification.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import Plus from "../../assets/Plus.svg?react";
import Search from "../../assets/Search.svg?react";
import Trash from "../../assets/Trash.svg?react";
import Jump from "../../assets/Jump.svg?react";
import Edit from "../../assets/Edit.svg?react";
import Upload from "../../assets/Upload.svg?react";
import PushPinSlash from "../../assets/PushPinSlash.svg?react";
import PushPin from "../../assets/PushPin.svg?react";

import styled from "styled-components";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import NumberSpan from "../../ui/NumberSpan";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { InputHTMLAttributes, useEffect, useMemo, useState } from "react";
import {
  DAY_FORMATS,
  DEFAULT_SORT_OPTIONS,
  PINNED_ITEM_TYPES,
  SORT_OPTIONS,
} from "../../common/Constants";
import { Task } from "../../types/milestoneTypes";
import ContextMenu from "../../ui/ContextMenu";
import Dropdown from "../../ui/Dropdown";
import PageNumbers from "../../ui/PageNumbers";
import Spinner from "../../ui/Spinner";
import { getOffset } from "../../utils/GetOffset";
import { useGetAllTasks } from "../Home/useGetAllTasks";
import ProfileLink from "../navigation/ProfileLink";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useGetCurrentUser } from "../authentication/useGetCurrentUser";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { PinHandler } from "../../types/savedItemTypes";
import toast from "react-hot-toast";

const HeaderSpan = styled.span`
  font-size: 1.6rem;
  color: var(--color-grey-300);
`;

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 4.2rem;
`;

const SearchFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 3.5rem; */
`;

const SearchInput = styled.input`
  width: 37rem;
  height: 4.8rem;
  border: unset;
  border-radius: var(--border-radius-2l);
  background: url("/Search.svg") no-repeat right;
  background-color: white;
  border-right: inset 1rem transparent;
  padding: 0 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const TableRowsContainer = styled.div`
  height: 62dvh;
  overflow: auto;
`;

const TableHeader = styled.div`
  display: grid;
  margin-top: 1.5rem;
  padding-left: 2.5rem;
  grid-template-columns: 2fr 2fr repeat(5, 1fr) 0.4fr;
  gap: 1.2rem;
  background-color: var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 700;
  font-size: 1.2rem;
  justify-items: start;
`;

const TableRow = styled.div`
  display: grid;
  margin: 1.5rem 0;
  padding-left: 2.5rem;
  grid-template-columns: 2fr 2fr repeat(5, 1fr) 0.2fr 0.2fr;
  gap: 1rem;
  background-color: white;
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 2rem 0;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0 3.5rem;
  align-items: center;
`;

/**
 * @returns Tasks overview with a table of all tasks
 */
const AllTasks = () => {
  // state
  const [sorting, setSorting] = useState<SortingState>([
    { id: "isPinned", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // API data
  const { pinItem } = useSavePinItem();
  const { removePinItem } = useRemovePinItem();
  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || ""
  );
  const pinnedTaskIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.TASK)
        return { value: item.value, id: item.id };
    }) || [];
  const { isTasksLoading, tasks } = useGetAllTasks(pinnedTaskIds);

  // tasks table setup
  const columnHelper = createColumnHelper<Task>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("isPinned", {
        header: "isPinned",
        id: "isPinned",
      }),
      columnHelper.accessor("pinId", {
        header: "pinId",
        id: "pinId",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("start_date", {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor("due_date", {
        header: "Due Date",
        id: "due_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        }
      ),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_complete",
      }),
      columnHelper.accessor("status.title", {
        header: "Status",
        id: "status",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: tasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        isPinned: false,
        pinId: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  const handleSelect = (id: string) => {
    const sortOrder = DEFAULT_SORT_OPTIONS.find((item) => item.id === id);
    const startYearColumn = table.getColumn("start_year");
    const endYearColumn = table.getColumn("end_year");

    if (sortOrder?.name === SORT_OPTIONS.RECENT) {
      startYearColumn?.toggleSorting(false);
      endYearColumn?.clearSorting();
    } else if (sortOrder?.name === SORT_OPTIONS.PAST) {
      startYearColumn?.toggleSorting(true);
      endYearColumn?.clearSorting();
    } else {
      startYearColumn?.clearSorting();
      endYearColumn?.toggleSorting(true);
    }
  };

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        }
      );
    }
    if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        }
      );
    }
  };

  if (isTasksLoading || isPinnedItemsLoading) return <Spinner />;

  return (
    <>
      <ProjectHeader>
        <h1>
          Tasks
          <HeaderSpan>{` [${tasks?.length || 0}]`}</HeaderSpan>
        </h1>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA $buttonType="secondary">
            <span>new milestone</span>
            <Plus />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <SearchFilter>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
        </FlexContainer>
        <FlexContainer>
          <Dropdown
            id="milestones"
            title="Recent"
            data={DEFAULT_SORT_OPTIONS}
            onSelect={handleSelect}
            selectedId="1"
            search={false}
          />
          <Icons>
            <Filter />
          </Icons>
          <Icons>
            <Upload />
          </Icons>
          <Icons>
            <More height={20} width={20} />
          </Icons>
        </FlexContainer>
      </SearchFilter>
      <TableHeader id="tasks-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </TableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} id={`${row.id}-tasks`}>
            <span>{row.getValue("title")}</span>
            <span>{row.getValue("description") || "N/A"}</span>
            <span>
              {moment(row.getValue("start_date")).format(DAY_FORMATS.DAY_FIRST)}
            </span>
            <span>
              {moment(row.getValue("due_date")).format(DAY_FORMATS.DAY_FIRST)}
            </span>
            <span>{row.getValue("responsible")}</span>
            <span>{row.getValue("percentage_complete")}</span>
            <Pill
              $progressStatus={
                row.getValue("status") as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{row.getValue("status")}</span>
            </Pill>
            <ContextMenu
              listItems={getTaskContextMenu(
                row.getValue("id"),
                row.getValue("isPinned"),
                row.getValue("pinId"),
                handlePinning
              )}
              id="tasks-table-menu"
              top={() => getOffset("tasks-table-header", `${row.id}-tasks`)}
            />
            {!!row.getValue("isPinned") && <PinFilled />}
          </TableRow>
        ))}
      </TableRowsContainer>

      <Pagination>
        <ItemCount>
          <span style={{ fontSize: "1.2rem" }}>Show:</span>
          <NumberSpan
            $isSelected={table.getState().pagination.pageSize === 10}
            onClick={() => table.setPageSize(10)}
          >
            10
          </NumberSpan>
          <NumberSpan
            $isSelected={table.getState().pagination.pageSize === 20}
            onClick={() => table.setPageSize(20)}
          >
            20
          </NumberSpan>
          <NumberSpan
            $isSelected={table.getState().pagination.pageSize === 50}
            onClick={() => table.setPageSize(50)}
          >
            50
          </NumberSpan>
          <NumberSpan
            $isSelected={table.getState().pagination.pageSize === 100}
            onClick={() => table.setPageSize(100)}
          >
            100
          </NumberSpan>
        </ItemCount>
        <PageNumbers table={table} />
      </Pagination>
    </>
  );
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <SearchInput
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

const getTaskContextMenu = (
  taskId: number,
  isPinned: boolean,
  pinId?: number,
  pinningHandler?: PinHandler
) => {
  return [
    {
      title: isPinned ? "Unpin" : "Pin",
      to: "",
      imageComponent: () => (isPinned ? PushPinSlash : PushPin),
      pinningHandler,
      pinningDetails: {
        type: PINNED_ITEM_TYPES.TASK,
        value: taskId,
        savePin: !isPinned,
        pinId,
      },
    },
    {
      title: "Edit & Complete",
      to: `/tasks/${taskId}`,
      imageComponent: () => Edit,
    },
    {
      title: "Delete",
      to: `/tasks/${taskId}`,
      imageComponent: () => Trash,
    },
    {
      title: "Create Project",
      to: "/projects",
      imageComponent: () => Jump,
    },
  ];
};

export default AllTasks;
