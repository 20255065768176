import styled from "styled-components";
import CWLogo from "../assets/CWLogo.svg?react";
import CWLogoText from "../assets/CWLogoText.svg?react";
import AtenBare from "../assets/aten-bare.svg?react";
import Aten from "../assets/aten-logo.svg?react";

import { useOverview } from "../context/OverviewActiveContext";
import NavLinks from "../features/navigation/NavLinks";

const StyledContainer = styled.div`
  padding: 5rem 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  gap: 15rem;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const LogoWithoutText = styled.div<{ $isOverviewActive: boolean }>`
  opacity: ${(props) => (props.$isOverviewActive ? "1" : "0")};
  cursor: pointer;
`;

const FullLogo = styled.div<{ $isOverviewActive: boolean }>`
  opacity: ${(props) => (props.$isOverviewActive ? "0" : "1")};
  cursor: pointer;
`;
/**
 * @description use this for showing all side nav items
 * @returns Navigation menu
 */
const NavigationComponent = () => {
  const { isOverviewActive, setIsOverviewActive } = useOverview();

  return (
    <StyledContainer>
      <LogoDiv onClick={() => setIsOverviewActive((prev) => !prev)}>
        {isOverviewActive && (
          <LogoWithoutText $isOverviewActive={isOverviewActive}>
            <CWLogo />
          </LogoWithoutText>
        )}
        {!isOverviewActive && (
          <FullLogo $isOverviewActive={isOverviewActive}>
            <CWLogoText />
          </FullLogo>
        )}
      </LogoDiv>
      <NavLinks />
      <LogoDiv>
        {isOverviewActive ? <AtenBare /> : <Aten />}
        <span style={{ fontSize: "1.2rem", color: "var(--color-version)" }}>
          {isOverviewActive ? "v1.0" : "VERSION 1.0"}
        </span>
      </LogoDiv>
    </StyledContainer>
  );
};

export default NavigationComponent;
