import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Expand from "../../assets/Expand.svg?react";
import { DROPDOWN_CATEGORIES } from "../../common/Constants";
import {
  useCreateProjectRisk,
  useEditProjectRisk,
} from "../../hooks/projects/useProjectRisks";
import { useGetDropdownValues } from "../../hooks/useGetDropdownValues";
import { RiskFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import {
  getContactDropdownValues,
  getDropdownValues,
} from "../../utils/GetDropdownValue";
import { useGetContacts } from "../../hooks/useGetContacts";

const RiskForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: RiskFormValues;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setFocus,
  } = useForm<RiskFormValues>();

  useEffect(() => {
    setFocus("risk_type");
  }, [setFocus]);

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isCreating, createProjectRisk } = useCreateProjectRisk();
  const { isEditing, editProjectRisk } = useEditProjectRisk();
  const { isContactsLoading, contacts } = useGetContacts();

  const typeDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.RISK_TYPE,
    dropdownItems
  );

  const typeId =
    typeDropdown.find((value) => value.name === details?.risk_type)?.id || "";

  const ratingDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.RISK_RATINGS,
    dropdownItems
  );

  const ratingId =
    ratingDropdown.find((value) => value.name === details?.risk_rank)?.id || "";

  const onSubmit: SubmitHandler<RiskFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectRisk(
        {
          riskDetails: {
            ...data,
            risk_type:
              typeDropdown.find((value) => value.id === data.risk_type)?.name ||
              "",
            risk_rank:
              ratingDropdown.find((value) => value.id === data.risk_rank)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project risk saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectRisk(
        {
          riskDetails: {
            ...data,
            risk_type:
              typeDropdown.find((value) => value.id === data.risk_type)?.name ||
              "",
            risk_rank:
              ratingDropdown.find((value) => value.id === data.risk_rank)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project risk saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof RiskFormValues, id);
  };

  if (isDropdownLoading || isEditing || isCreating || isContactsLoading)
    return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <h1>Risks</h1>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Type *</label>
            <Controller
              control={control}
              name="risk_type"
              defaultValue={typeId}
              rules={{ required: "This field is required" }}
              render={({ field: { value } }) => (
                <Dropdown
                  id="risk_type"
                  title=""
                  data={typeDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                />
              )}
            />
            {errors?.risk_type && (
              <ErrorMessage>{errors.risk_type.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>Rating *</label>
            <Controller
              control={control}
              name="risk_rank"
              defaultValue={ratingId}
              rules={{ required: "This field is required" }}
              render={({ field: { value } }) => (
                <Dropdown
                  id="risk_rank"
                  title=""
                  data={ratingDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                />
              )}
            />
            {errors?.risk_rank && (
              <ErrorMessage>{errors.risk_rank.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
          <LabelAndInput>
            <label>Control</label>
            <StyledInput
              type="text"
              {...register("risk_control")}
              defaultValue={details?.risk_control}
              autoComplete="off"
            />
          </LabelAndInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Details *</label>
            <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isBackgroundFieldExpanded ? "20rem" : ""}
                {...register("details", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                defaultValue={details?.details}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
              >
                {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
            {errors?.details && (
              <ErrorMessage>{errors.details.message}</ErrorMessage>
            )}
          </LabelAndInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Owner</label>
            <Controller
              control={control}
              name="responsible_person_id"
              defaultValue={details?.responsible_person_id}
              render={({ field: { value } }) => (
                <Dropdown
                  id="responsible_person_id"
                  title=""
                  data={getContactDropdownValues(contacts)}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                />
              )}
            />
          </LabelAndInput>
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default RiskForm;
