import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import DeleteCon from "../assets/DeleteCon.svg?react";
import Edit from "../assets/EditPencil.svg?react";
import Rearrange from "../assets/Rearrange.svg?react";
import {
  ProjectDetailsType,
  ProjectScope,
  ProjectSuccessMeasure,
} from "../types/projectTypes";

export const StyledSection = styled.section`
  border-radius: var(--border-radius-2l);
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  margin-bottom: 2rem;
`;

export const SectionPart = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  border-bottom: 3px dashed var(--color-grey-200);
`;

export const StyledTableSection = styled(StyledSection)`
  gap: 0;
  padding: 0;
`;

export const StyledTableSectionPart = styled(SectionPart)`
  gap: 0;
  margin-bottom: 2rem;
  border: none;
`;

export const SectionTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const LabelAndInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & label {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;

    & span {
      font-size: 1.4rem;
      font-style: italic;
      font-weight: 500;
    }
  }
`;

export const StyledInput = styled.input`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  height: 4.8rem;
  font-weight: 500;
`;

export const StyledCheckbox = styled.input`
  height: 4.8rem;
  width: 2.4rem;
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  &:checked {
    accent-color: var(--color-grey-900);
  }
`;

export const StyledCheckboxLabel = styled(LabelAndInput)`
  flex-direction: row;
  height: 4.8rem;
  align-items: center;
  gap: 2rem;
  font-size: 1.4rem;
`;

export const StyledThreeFieldSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

export const StyledTwoFieldSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const StyledFullWidthInput = styled.textarea<{ height?: string }>`
  width: 100%;
  height: ${(props) => props.height || "4.8rem"};
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  resize: none;
`;

export const ExpandableDiv = styled.div<{ height?: string }>`
  position: relative;
  grid-column: 1/-1;
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  height: ${(props) => props.height || "4.8rem"};
`;

export const PositionedSvg = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 2rem;
  z-index: 10;
  cursor: pointer;
`;

export const PositionedInput = styled(LabelAndInput)`
  position: relative;
`;

export const PositionedCalendar = styled(PositionedSvg)`
  top: 3.8rem;
`;

export const PositionedPlaceholder = styled(PositionedSvg)`
  right: 5rem;
  top: 4rem;
  cursor: default;
`;

export const SecondaryPositionSvg = styled(PositionedSvg)`
  top: 25%;
`;

export const AllSortableItems = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  & span:first-child {
    font-size: 1.2rem;
    font-weight: 700;
  }
  touch-action: none;
`;

export const SortableItemsList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 400;
  overflow-y: auto;
`;

export const SortableItem = styled.div<{ disabled?: boolean }>`
  display: grid;
  margin-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 2rem;
  grid-template-columns: 1fr 5fr 0.3fr;
  background-color: white;
  border: 2px dashed var(--color-grey-400);
  height: 8rem;
  gap: 2rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  width: 100%;
  & svg {
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
  }
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
`;

const ScopeSortableItem = styled(SortableItem)`
  grid-template-columns: 0.5fr 2fr 3fr 1.2fr 0.3fr;
`;

const SortableSuccessItem = styled(SortableItem)`
  grid-template-columns: 1fr 2fr 3fr 0.3fr;
`;

export const SortActions = styled.div<{ disabled: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 2rem;
  & svg:last-child {
    pointer-events: ${(props) => (props.disabled ? "none" : "")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
  }
`;

export const AddItemP = styled.p`
  font-size: 1.4rem;
  color: var(--color-button-secondary);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin-top: 1rem;
`;

export const ErrorMessage = styled.p`
  color: var(--color-button-secondary);
  font-size: 1.2rem;
`;

export const ClearMessage = styled(ErrorMessage)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
`;

export const SortableListRenderer = ({
  item,
  index,
  length,
  detailName,
  handleEdit,
  handleDelete,
}: {
  item: ProjectDetailsType;
  index: number;
  length: number;
  detailName: string;
  handleEdit: (
    description: string,
    detailId: number,
    sequence: number,
    detailName: string
  ) => void;
  handleDelete: (detailId: number) => void;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: `${length === 1 ? "not-allowed" : "grab"}`,
  };

  return (
    <AllSortableItems key={item.id} style={style} ref={setNodeRef}>
      <SortableItem>
        <span>{index + 1}</span>
        <span>{item.description}</span>
        <SortActions disabled={length === 1}>
          <Edit
            onClick={() =>
              handleEdit(item.description, item.id, item.sequence, detailName)
            }
          />
          <DeleteCon onClick={() => handleDelete(item.id)} />
          <Rearrange style={svgStyle} {...attributes} {...listeners} />
        </SortActions>
      </SortableItem>
    </AllSortableItems>
  );
};

export const SortableScopeListRenderer = ({
  item,
  index,
  length,
  handleEdit,
  handleDelete,
}: {
  item: ProjectScope;
  index: number;
  length: number;
  handleEdit: (details: ProjectScope) => void;
  handleDelete: (detailId: number) => void;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: `${length === 1 ? "not-allowed" : "grab"}`,
  };

  return (
    <AllSortableItems key={item.id} style={style} ref={setNodeRef}>
      <ScopeSortableItem>
        <span>{index + 1}</span>
        <span>{item.title}</span>
        <span>{item.description}</span>
        <span>{item.in_scope ? "Yes" : "No"}</span>
        <SortActions disabled={length === 1}>
          <Edit onClick={() => handleEdit(item)} />
          <DeleteCon onClick={() => handleDelete(item.id)} />
          <Rearrange style={svgStyle} {...attributes} {...listeners} />
        </SortActions>
      </ScopeSortableItem>
    </AllSortableItems>
  );
};

export const SortableSuccessListRenderer = ({
  item,
  index,
  length,
  handleEdit,
  handleDelete,
}: {
  item: ProjectSuccessMeasure;
  index: number;
  length: number;
  handleEdit: (details: ProjectSuccessMeasure) => void;
  handleDelete: (detailId: number) => void;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: `${length === 1 ? "not-allowed" : "grab"}`,
  };

  return (
    <AllSortableItems key={item.id} style={style} ref={setNodeRef}>
      <SortableSuccessItem>
        <span>{index + 1}</span>
        <span>{item.description}</span>
        <span>{item.source_of_benefit}</span>
        <SortActions disabled={length === 1}>
          <Edit onClick={() => handleEdit(item)} />
          <DeleteCon onClick={() => handleDelete(item.id)} />
          <Rearrange style={svgStyle} {...attributes} {...listeners} />
        </SortActions>
      </SortableSuccessItem>
    </AllSortableItems>
  );
};

export const BottomNav = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  padding: 2rem 2rem 4rem;
`;
