import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createProjectDetailsAPI,
  updateProjectDetailsAPI,
  updateRankingAPI,
} from "../../services/apiProjects";
import { UpsertProjectDetails } from "../../types/projectTypes";
import { QUERY_KEYS } from "../../common/Constants";

type CreateDetailBody = {
  projectDetails: UpsertProjectDetails;
  detailId?: number;
};
/**
 * @returns Saving a pinned item
 */
export const useCreateProjectDetails = (type: string) => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: createProjectDetail,
    error,
  } = useMutation({
    mutationFn: ({ projectDetails }: CreateDetailBody) =>
      createProjectDetailsAPI(projectDetails, type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_DETAILS, type],
      });
    },
  });

  return { isLoading, createProjectDetail, error };
};

export const useEditProjectDetails = (type: string) => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: editProjectDetails,
    error,
  } = useMutation({
    mutationFn: ({ projectDetails, detailId }: CreateDetailBody) =>
      updateProjectDetailsAPI(detailId || 0, projectDetails, type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_DETAILS, type],
      });
    },
  });

  return { isLoading, editProjectDetails, error };
};

export const useUpdateRanking = (type: string) => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: editRanks,
    error,
  } = useMutation({
    mutationFn: (rankDetails: { id: number; sequence: number }[]) =>
      updateRankingAPI(rankDetails, type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_DETAILS, type],
      });
    },
  });

  return { isLoading, editRanks, error };
};
