import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectConsultationAPI,
  deleteProjectConsultationAPI,
  getProjectConsultationAPI,
  updateProjectConsultationAPI,
} from "../../services/apiProjects";
import { UpsertProjectConsultation } from "../../types/projectTypes";

export const useGetProjectConsultations = (id: string) => {
  const {
    isPending: isConsultationLoading,
    data: consultationDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectConsultationAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_CONSULTATION, id],
    retry: false,
  });

  return { isConsultationLoading, consultationDetails, error };
};

export const useCreateProjectConsultation = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectConsultation,
    error,
  } = useMutation({
    mutationFn: ({
      consultationDetails,
    }: {
      consultationDetails: UpsertProjectConsultation;
    }) => createProjectConsultationAPI(consultationDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_CONSULTATION],
      });
    },
  });

  return { isCreating, createProjectConsultation, error };
};

export const useEditProjectConsultation = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectConsultation,
    error,
  } = useMutation({
    mutationFn: ({
      consultationDetails,
      detailId,
    }: {
      consultationDetails: UpsertProjectConsultation;
      detailId: number;
    }) => updateProjectConsultationAPI(consultationDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_CONSULTATION],
      });
    },
  });

  return { isEditing, editProjectConsultation, error };
};

export const useDeleteProjectConsultation = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectConsultation,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectConsultationAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_CONSULTATION],
      });
    },
  });

  return { isDeleting, deleteProjectConsultation, error };
};
