import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createMilestoneAPI,
  deleteMilestoneAPI,
  updateMilestoneAPI,
} from "../../services/apiMilestones";
import { UpsertMilestone } from "../../types/milestoneTypes";

export const useCreateProjectMilestone = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectMilestone,
    error,
  } = useMutation({
    mutationFn: ({ milestoneDetails }: { milestoneDetails: UpsertMilestone }) =>
      createMilestoneAPI(milestoneDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MILESTONES],
      });
    },
  });

  return { isCreating, createProjectMilestone, error };
};

export const useEditProjectMilestone = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectMilestone,
    error,
  } = useMutation({
    mutationFn: ({
      milestoneDetails,
      milestoneId,
    }: {
      milestoneDetails: UpsertMilestone;
      milestoneId: number;
    }) => updateMilestoneAPI(milestoneDetails, milestoneId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MILESTONES],
      });
    },
  });

  return { isEditing, editProjectMilestone, error };
};

export const useDeleteProjectMilestone = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectMilestone,
    error,
  } = useMutation({
    mutationFn: ({ milestoneId }: { milestoneId: number }) =>
      deleteMilestoneAPI(milestoneId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MILESTONES],
      });
    },
  });

  return { isDeleting, deleteProjectMilestone, error };
};
