import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
    createProjectImpactAPI,
    deleteProjectImpactAPI,
    getProjectImpactAPI,
    updateProjectImpactAPI
} from "../../services/apiProjects";
import { UpsertProjectImpact } from "../../types/projectTypes";

export const useGetProjectImpacts = (id: string) => {
  const {
    isPending: isImpactLoading,
    data: impactDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectImpactAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_IMPACTS, id],
    retry: false,
  });

  return { isImpactLoading, impactDetails, error };
};

export const useCreateProjectImpact = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectImpact,
    error,
  } = useMutation({
    mutationFn: ({ impactDetails }: { impactDetails: UpsertProjectImpact }) =>
      createProjectImpactAPI(impactDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_IMPACTS],
      });
    },
  });

  return { isCreating, createProjectImpact, error };
};

export const useEditProjectImpact = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectImpact,
    error,
  } = useMutation({
    mutationFn: ({
      impactDetails,
      detailId,
    }: {
      impactDetails: UpsertProjectImpact;
      detailId: number;
    }) => updateProjectImpactAPI(impactDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_IMPACTS],
      });
    },
  });

  return { isEditing, editProjectImpact, error };
};

export const useDeleteProjectImpact = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectImpact,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectImpactAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_IMPACTS],
      });
    },
  });

  return { isDeleting, deleteProjectImpact, error };
};
