import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";
import {
  useCreateProjectConsultation,
  useEditProjectConsultation,
} from "../../hooks/projects/useProjectConsultation";
import { ConsultationFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";

import moment from "moment";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { DATE_MODAL_NAMES, DAY_FORMATS } from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import DayPickerModal from "../../ui/DayPickerModal";
import { getEndMonth, getStartMonth } from "../../utils/common";

const ConsultationForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: ConsultationFormValues;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const { setDayModalName } = useDayModalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    control,
    getValues,
    resetField,
    setValue,
  } = useForm<ConsultationFormValues>();

  useEffect(() => {
    setFocus("title");
  }, [setFocus]);

  const { isCreating, createProjectConsultation } =
    useCreateProjectConsultation();
  const { isEditing, editProjectConsultation } = useEditProjectConsultation();

  const onSubmit: SubmitHandler<ConsultationFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectConsultation(
        {
          consultationDetails: {
            ...data,
            when: moment(data.when).format("DD-MM-YYYY"),
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project consultation details saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectConsultation(
        {
          consultationDetails: {
            ...data,
            when: moment(data.when).format("YYYY-MM-DD"),
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project consultation details saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };

  if (isCreating || isEditing) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Title *</label>
            <StyledInput
              type="text"
              {...register("title", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.title}
              autoComplete="off"
            />
            {errors?.title && (
              <ErrorMessage>{errors.title.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
          <LabelAndInput>
            <label>Target Audience *</label>
            <StyledInput
              type="text"
              {...register("who", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.who}
            />
            {errors?.who && <ErrorMessage>{errors.who.message}</ErrorMessage>}
          </LabelAndInput>{" "}
          <PositionedInput>
            <label>By When</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("when")
                  ? moment(getValues("when")).format("DD-MM-YYYY")
                  : details?.when
                  ? moment(details?.when).format("DD-MM-YYYY")
                  : ""
              }
              readOnly
            />
            {errors?.when && <ErrorMessage>{errors.when.message}</ErrorMessage>}
            {getValues("when") || details?.when ? (
              <ClearMessage onClick={() => resetField("when")}>
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="when"
              defaultValue={details?.when}
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().when)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "when",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    startMonth={getStartMonth(details?.when)}
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().when ? new Date(getValues().when) : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Details *</label>
            <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isBackgroundFieldExpanded ? "20rem" : ""}
                {...register("details", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                defaultValue={details?.details}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
              >
                {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
            {errors?.details && (
              <ErrorMessage>{errors.details.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Methods of Communication</label>
            <StyledInput
              type="text"
              {...register("communication_method")}
              defaultValue={details?.communication_method}
              autoComplete="off"
            />
          </LabelAndInput>
          <LabelAndInput>
            <label>Link</label>
            <StyledInput
              type="text"
              {...register("message")}
              defaultValue={details?.message}
              autoComplete="off"
            />
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default ConsultationForm;
