import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";

import moment from "moment";
import { DayPicker } from "react-day-picker";
import styled from "styled-components";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useGetStatusValues } from "../../hooks/useGetStatusValues";
import { TaskFormValues } from "../../types/formFields";
import { Milestone } from "../../types/milestoneTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getItemDropdownValues,
} from "../../utils/GetDropdownValue";
import { useCreateProjectTask, useEditProjectTask } from "./useTasks";

const EachMilestone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const MilestoneTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const StatusSpan = styled.span<{ $progressStatus?: keyof typeof SCHEDULE_MAP }>`
  color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span<{
  $progressStatus?: keyof typeof SCHEDULE_MAP;
}>`
  height: 0.8rem;
  width: 0.8rem;
  background-color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  border-radius: var(--border-radius-2l);
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const StatusView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TaskForm = ({
  handleClose,
  isEditingMode,
  details,
  milestones,
}: {
  handleClose: () => void;
  isEditingMode: boolean;
  details?: TaskFormValues;
  milestones?: Milestone[] | undefined;
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setFocus,
    getValues,
    resetField,
  } = useForm<TaskFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { setDayModalName } = useDayModalContext();
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState<
    Milestone | undefined
  >(
    milestones?.find(
      (milestone) => milestone.id === (details?.milestone_id || 0)
    )
  );

  useEffect(() => {
    setFocus("title");
  }, [setFocus]);

  const { isCreating, createProjectTask } = useCreateProjectTask();
  const { isEditing, editProjectTask } = useEditProjectTask();
  const { isContactsLoading, contacts } = useGetContacts();
  const { isStatusLoading, statuses } = useGetStatusValues();
  const { isServiceLoading, services } = useGetServiceValues();

  const milestoneDropdown = useMemo(
    () => getItemDropdownValues(milestones),
    [milestones]
  );

  const statusDropdown = useMemo(
    () => getItemDropdownValues(statuses),
    [statuses]
  );

  const serviceDropdown = useMemo(
    () => getItemDropdownValues(services),
    [services]
  );

  const onSubmit: SubmitHandler<TaskFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectTask(
        {
          taskDetails: {
            ...data,
            parent_id: null,
            path: null,
            priority: "sequence",
            start_date: data.start_date || null,
            due_date: data.due_date || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project task saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectTask(
        {
          taskDetails: {
            ...data,
            parent_id: null,
            path: null,
            priority: "sequence",
            start_date: data.start_date || null,
            due_date: data.due_date || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
          taskId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project task saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof TaskFormValues, id);
    if (name === "milestone_id") {
      setSelectedMilestone(
        milestones?.find((milestone) => milestone.id === +id)
      );
    }
  };

  if (
    isCreating ||
    isEditing ||
    isContactsLoading ||
    isStatusLoading ||
    isServiceLoading
  )
    return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        {selectedMilestone && (
          <EachMilestone key={selectedMilestone.id}>
            <MilestoneTitle>
              {selectedMilestone.title}
              <StatusView>
                <StatusP>
                  <StatusCircle
                    $progressStatus={
                      selectedMilestone.status
                        ?.title as keyof typeof SCHEDULE_MAP
                    }
                  ></StatusCircle>
                  <StatusSpan
                    $progressStatus={
                      selectedMilestone.status
                        ?.title as keyof typeof SCHEDULE_MAP
                    }
                  >
                    {selectedMilestone.status?.title}
                  </StatusSpan>
                </StatusP>
              </StatusView>
            </MilestoneTitle>
            <ProjectInfoGrid>
              <ProjectGridItem>
                <h2>Start Date</h2>
                <h1>
                  {moment(selectedMilestone?.start_year).format("DD/MM/YYYY")}
                </h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>End Date</h2>
                <h1>
                  {moment(selectedMilestone.end_year).format("DD/MM/YYYY")}
                </h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Service</h2>
                <h1>{selectedMilestone.service?.title || "N/A"}</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>responsible Person</h2>
                <h1>{`${selectedMilestone?.responsible.first_name} ${selectedMilestone?.responsible.last_name}`}</h1>
              </ProjectGridItem>
            </ProjectInfoGrid>
          </EachMilestone>
        )}
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Milestone *</label>
            <Controller
              control={control}
              name="milestone_id"
              rules={{ required: "This field is required" }}
              defaultValue={details?.milestone_id}
              render={({ field: { value } }) => (
                <Dropdown
                  id="milestone_id"
                  title=""
                  data={milestoneDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                />
              )}
            />
            {errors?.milestone_id && (
              <ErrorMessage>{errors.milestone_id.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>% of Milestone</label>
            <StyledInput
              type="number"
              {...register("percentage_of_milestone", {
                max: { value: 100, message: "Max value allowed is 100" },
                min: { value: 0, message: "Min value allowed in 0" },
              })}
              defaultValue={details?.percentage_of_milestone || 0}
              autoComplete="off"
            />
            {errors?.percentage_of_milestone && (
              <ErrorMessage>
                {errors.percentage_of_milestone.message}
              </ErrorMessage>
            )}
          </LabelAndInput>{" "}
          <LabelAndInput>
            <label>Service</label>
            <Controller
              control={control}
              name="service_id"
              defaultValue={details?.service_id}
              render={({ field: { value } }) => (
                <Dropdown
                  id="service_id"
                  title=""
                  data={serviceDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                />
              )}
            />
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Title *</label>
            <StyledInput
              type="text"
              {...register("title", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.title}
              autoComplete="off"
            />
            {errors?.title && (
              <ErrorMessage>{errors.title.message}</ErrorMessage>
            )}
          </LabelAndInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Description *</label>
            <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isBackgroundFieldExpanded ? "20rem" : ""}
                {...register("description", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                defaultValue={details?.description}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
              >
                {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
            {errors?.description && (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Responsible Person *</label>
            <Controller
              control={control}
              name="responsible_person_id"
              rules={{ required: "This field is required" }}
              defaultValue={details?.responsible_person_id}
              render={({ field: { value } }) => (
                <Dropdown
                  id="responsible_person_id"
                  title=""
                  data={getContactDropdownValues(contacts)}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                  showOnTop
                />
              )}
            />
            {errors?.responsible_person_id && (
              <ErrorMessage>
                {errors.responsible_person_id.message}
              </ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>Status</label>
            <Controller
              control={control}
              name="status_id"
              defaultValue={details?.status_id || 1}
              render={({ field: { value } }) => (
                <Dropdown
                  id="status_id"
                  title=""
                  data={statusDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                  showOnTop
                />
              )}
            />
          </LabelAndInput>
          <LabelAndInput>
            <label>% complete</label>
            <StyledInput
              type="number"
              {...register("percentage_completed", {
                max: { value: 100, message: "Max value allowed is 100" },
                min: { value: 0, message: "Min value allowed in 0" },
              })}
              defaultValue={details?.percentage_completed || 0}
              autoComplete="off"
            />
            {errors?.percentage_completed && (
              <ErrorMessage>{errors.percentage_completed.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <PositionedInput>
            <label>Estimated Start Date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("est_start_date")
                  ? moment(getValues("est_start_date")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.est_start_date
                  ? moment(details?.est_start_date).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : ""
              }
              readOnly
            />
            {errors?.est_start_date && (
              <ErrorMessage>{errors.est_start_date.message}</ErrorMessage>
            )}
            {getValues("est_start_date") || details?.est_start_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("est_start_date");
                  if (details) {
                    details.est_start_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.EST_START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="est_start_date"
              defaultValue={details?.est_start_date}
              rules={{
                min: {
                  value: getMinDate(isEditingMode),
                  message: "Please enter a valid start date",
                },
                max: {
                  value: getValues().due_date
                    ? moment(getValues().due_date).format(
                        DAY_FORMATS.YEAR_FIRST
                      )
                    : "",
                  message: "Please enter a valid start date",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().est_start_date)}
                    onSelect={(e) => {
                      onChange(() => {
                        setValue(
                          "est_start_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        );
                      });
                      setDayModalName("");
                    }}
                    showOutsideDays
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().est_start_date
                        ? new Date(getValues().est_start_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <PositionedInput>
            <label>Estimated End date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("est_end_date")
                  ? moment(getValues("est_end_date")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.est_end_date
                  ? moment(details?.est_end_date).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.est_end_date && (
              <ErrorMessage>{errors.est_end_date.message}</ErrorMessage>
            )}
            {getValues("est_end_date") || details?.est_end_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("est_end_date");
                  if (details) {
                    details.est_end_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.EST_END);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="est_end_date"
              defaultValue={details?.est_end_date}
              rules={{
                min: {
                  value:
                    (getValues("start_date") &&
                      moment(getValues("start_date")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )) ||
                    getMinDate(isEditingMode),
                  message: "Please enter a valid due date",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_END}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().est_end_date)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "est_end_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    showOutsideDays
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().est_end_date
                        ? new Date(getValues().est_end_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <PositionedInput>
            <label>Actual Start Date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("start_date")
                  ? moment(getValues("start_date")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.start_date
                  ? moment(details?.start_date).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.start_date && (
              <ErrorMessage>{errors.start_date.message}</ErrorMessage>
            )}
            {getValues("start_date") || details?.start_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("start_date");
                  if (details) {
                    details.start_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="start_date"
              defaultValue={details?.start_date}
              rules={{
                min: {
                  value: getMinDate(isEditingMode),
                  message: "Please enter a valid start date",
                },
                max: {
                  value: getValues().due_date
                    ? moment(getValues().due_date).format(
                        DAY_FORMATS.YEAR_FIRST
                      )
                    : "",
                  message: "Please enter a valid start date",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().start_date)}
                    onSelect={(e) => {
                      onChange(() => {
                        setValue(
                          "start_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        );
                      });
                      setDayModalName("");
                    }}
                    showOutsideDays
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().start_date
                        ? new Date(getValues().start_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <PositionedInput>
            <label>Actual End date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("due_date")
                  ? moment(getValues("due_date")).format(DAY_FORMATS.DAY_FIRST)
                  : details?.due_date
                  ? moment(details?.due_date).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.due_date && (
              <ErrorMessage>{errors.due_date.message}</ErrorMessage>
            )}
            {getValues("due_date") || details?.due_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("due_date");
                  if (details) {
                    details.due_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.END);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="due_date"
              defaultValue={details?.due_date}
              rules={{
                min: {
                  value:
                    (getValues("start_date") &&
                      moment(getValues("start_date")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )) ||
                    getMinDate(isEditingMode),
                  message: "Please enter a valid due date",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().due_date)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "due_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    showOutsideDays
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().due_date
                        ? new Date(getValues().due_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA
          $buttonSize={BUTTON_SIZES.SMALL}
          type="button"
          onClick={handleClose}
        >
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA
          $buttonSize={BUTTON_SIZES.SMALL}
          $buttonType={BUTTON_TYPES.SECONDARY}
          type="submit"
        >
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default TaskForm;
