import styled from "styled-components";
import DeleteCon from "../../assets/DeleteCon.svg?react";

import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Edit from "../../assets/EditPencil.svg?react";

import { useEffect, useMemo, useState } from "react";
import Spinner from "../../ui/Spinner";

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import toast from "react-hot-toast";
import { DAY_FORMATS, MODAL_NAMES, MODAL_TYPE } from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useActiveModalContext } from "../../context/ModalContext";
import { useDeleteProjectDetails } from "../../hooks/projects/useDeleteProjectDetails";
import {
  useDeleteProjectConsultation,
  useGetProjectConsultations,
} from "../../hooks/projects/useProjectConsultation";
import {
  useDeleteProjectFinance,
  useGetProjectFinances,
} from "../../hooks/projects/useProjectFinance";
import {
  useDeleteProjectImpact,
  useGetProjectImpacts,
} from "../../hooks/projects/useProjectImpacts";
import {
  useDeleteProjectRisk,
  useGetProjectRisks,
} from "../../hooks/projects/useProjectRisks";
import {
  useDeleteProjectScope,
  useUpdateScopeRanking,
} from "../../hooks/projects/useProjectScope";
import {
  useDeleteProjectStakeholder,
  useGetProjectStakeholders,
} from "../../hooks/projects/useProjectStakeholders";
import {
  useDeleteProjectSuccess,
  useUpdateSuccessRanking,
} from "../../hooks/projects/useProjectSuccess";
import {
  useDeleteProjectWorkforce,
  useGetProjectWorkforces,
} from "../../hooks/projects/useProjectWorkforce";
import { useUpdateRanking } from "../../hooks/projects/useUpsertProjectDetails";
import {
  ConsultationFormValues,
  FinanceFormValues,
  ImpactFormValues,
  MilestoneFormValues,
  RiskFormValues,
  ScopeFormValues,
  StakeholderFormValues,
  SuccessMeasureFormValues,
  TaskFormValues,
  WorkforceFormValues,
} from "../../types/formFields";
import { Milestone, Task } from "../../types/milestoneTypes";
import {
  ProjectConsultationType,
  ProjectDetailsType,
  ProjectFinanceType,
  ProjectImpactType,
  ProjectRiskType,
  ProjectScope,
  ProjectStakeholdersType,
  ProjectSuccessMeasure,
  ProjectWorkforceType,
} from "../../types/projectTypes";
import {
  SortableItemsList,
  SortableListRenderer,
  SortableScopeListRenderer,
  SortableSuccessListRenderer,
} from "../../ui/FormElements";
import Modal from "../../ui/Modal";
import NoDataCard from "../../ui/NoDataCard";
import { getFormattedCurrency } from "../../utils/common";
import ConsultationForm from "../forms/ConsultationForm";
import DetailsForm from "../forms/DetailsForm";
import FinanceForm from "../forms/FinanceForm";
import ImpactForm from "../forms/ImpactForm";
import RiskForm from "../forms/RiskForm";
import ScopeForm from "../forms/ScopeForm";
import StakeholderForm from "../forms/StakeholderForm";
import SuccessMeasureForm from "../forms/SuccessMeasureForm";
import WorkforceForm from "../forms/WorkforceForm";
import MilestoneForm from "../milestones/MilestoneForm";
import { useDeleteProjectMilestone } from "../milestones/useMilestones";
import TaskForm from "../tasks/TasksForm";
import { useDeleteProjectTask } from "../tasks/useTasks";

const DetailTableHeader = styled.div`
  display: grid;
  padding-left: 2.5rem;
  padding-right: 2rem;
  grid-template-columns: 1fr 5fr 0.3fr;
  background-color: var(--color-grey-400);
  height: 8rem;
  gap: 2rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 700;
  font-size: 1.2rem;
  justify-items: start;
  text-transform: capitalize;
  color: var(--color-grey-900);
`;

const DetailsTableRow = styled.div<{ disabled?: boolean }>`
  display: grid;
  margin-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 2rem;
  grid-template-columns: 1fr 5fr 0.3fr;
  gap: 2rem;
  background-color: white;
  border: 2px dashed var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  & svg {
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
  }
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
`;

const EditDeleteContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const TableRowsContainer = styled.div`
  /* height: 20dvh;
  overflow: auto; */
`;

const TableTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: var(--color-grey-500);

  :first-child {
    font-size: 1.2rem;
    color: var(--color-grey-900);
    text-transform: capitalize;
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
`;

export const ActionItemP = styled.p`
  font-size: 1.4rem;
  color: var(--color-button-secondary);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  display: flex;
  padding: 0 0 1rem;
  margin-left: auto;
  align-items: center;
`;

const ScopeTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 0.5fr 2fr 3fr 1.2fr 0.5fr;
`;

const SuccessTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1fr 2fr 3fr 0.5fr;
`;

const StakeholderTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(3, 1fr) 3fr 0.25fr;
`;

const StakeholderTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(3, 1fr) 3fr 0.25fr;
  border: none;
  background-color: white;
`;

const RiskTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1fr 2fr 2fr repeat(2, 1fr) 0.3fr;
`;

const RiskTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1fr 2fr 2fr repeat(2, 1fr) 0.3fr;
  border: none;
  background-color: white;
`;

const ConsultationTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(4, 1fr) 1fr 3fr 0.4fr;
`;

const ConsultationTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(4, 1fr) 1fr 3fr 0.3fr;
  border: none;
  background-color: white;
`;

const ImpactTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(2, 1fr) 3.5fr 0.3fr;
`;

const ImpactTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(2, 1fr) 3.5fr 0.3fr;
  border: none;
  background-color: white;
`;

const WorkforceTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(6, 1fr) 0.3fr;
`;

const WorkforceTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(6, 1fr) 0.3fr;
  border: none;
  background-color: white;
`;

const FinancialTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(5, 1fr) 2.5fr 0.3fr;
`;

const FinancialTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(5, 1fr) 2.5fr 0.3fr;
  border: none;
  background-color: white;
`;

const MilestoneTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1.5fr 2fr repeat(5, 1fr) 0.5fr;
`;

const MilestoneTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1.5fr 2fr repeat(5, 1fr) 0.3fr;
  border: none;
  background-color: white;
`;

const TaskTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1.5fr 2fr 1.5fr repeat(3, 1fr) 1.3fr 0.5fr;
`;

const TaskTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1.5fr 2fr 1.5fr repeat(3, 1fr) 1.3fr 0.3fr;
  border: none;
  background-color: white;
`;

export const DetailsTableRenderer = ({
  projectId,
  type,
  detailName,
  projectDetails,
  error,
}: {
  projectId: string;
  type: string;
  detailName: string;
  projectDetails: ProjectDetailsType[] | undefined;
  error: Error | null;
}) => {
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState({
    detailId: -1,
    description: "",
    sequence: 1,
  });

  const [currentProjectDetails, setCurrentProjectDetails] = useState<
    ProjectDetailsType[]
  >([]);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (projectDetails) {
      setCurrentProjectDetails(projectDetails);
    }
  }, [projectDetails]);

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectDetails } = useDeleteProjectDetails(type);

  const { isLoading, editRanks } = useUpdateRanking(type);

  const handleDelete = (detailId: number) => {
    deleteProjectDetails(
      { detailId },
      {
        onSuccess: () => toast.success("Project detail removed successfully."),
      }
    );
  };

  const handleEdit = (
    description: string,
    detailId: number,
    sequence: number,
    detailName: string
  ) => {
    setDetails({
      description,
      detailId,
      sequence,
    });
    setCurrentModalName(`${detailName}`);
    setIsProjectDetailsOpen(true);
  };

  const handleSave = () => {
    const latestRanks = currentProjectDetails.map((details, index) => ({
      id: details.id,
      sequence: index + 1,
    }));
    editRanks(latestRanks, {
      onSuccess: () => {
        toast.success(
          `Project ${detailName.toLowerCase()} sequencing saved successfully.`
        );
        setIsSaveEnabled(false);
      },
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectDetailsType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor((row) => row.sequence, {
        header: "Rank",
        id: "rank",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: projectDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  // drag and drop setup
  const getCurrentIndexPos = (
    id: number | string,
    items: ProjectDetailsType[]
  ) => items.findIndex((item) => item.id === id);

  const handleDragEnd = (
    event: DragEndEvent,
    setItems: React.Dispatch<React.SetStateAction<ProjectDetailsType[]>>
  ) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setItems((items) => {
      const originalPos = getCurrentIndexPos(active.id, items);
      const newPos = getCurrentIndexPos(over?.id, items);
      return arrayMove(items, originalPos, newPos);
    });
    setIsSaveEnabled(true);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isDeleting || isLoading) return <Spinner />;

  if (error || !projectDetails?.length)
    return (
      <>
        <NoDataCard
          title={detailName + "s"}
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(`${detailName}`);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={`${detailName}`}
          type={MODAL_TYPE.NEW}
          wrapperId={`${detailName}`}
        >
          <DetailsForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            type={type}
            isEditing={false}
            detailLength={0}
          />
        </Modal>
      </>
    );

  return (
    <>
      {isSaveEnabled && (
        <ActionItemP onClick={handleSave}>
          {" "}
          <CheckMark
            height={"1.4rem"}
            width={"1.4rem"}
            fill={"var(--color-button-secondary)"}
            stroke={"var(--color-button-secondary)"}
          />{" "}
          Save{" "}
        </ActionItemP>
      )}
      <DetailTableHeader id={`project-details-${type}`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span key={header.id} style={{ cursor: "pointer" }}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </DetailTableHeader>
      <TableRowsContainer>
        <DndContext
          collisionDetection={closestCorners}
          onDragEnd={(e) => handleDragEnd(e, setCurrentProjectDetails)}
          sensors={sensors}
          id="ranks-container"
        >
          <SortableItemsList>
            {currentProjectDetails.map((item, index) => (
              <SortableContext
                items={currentProjectDetails}
                strategy={verticalListSortingStrategy}
                key={item.id}
              >
                <SortableListRenderer
                  item={item}
                  key={item.id}
                  index={index}
                  length={currentProjectDetails.length}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  detailName={detailName}
                />
              </SortableContext>
            ))}
          </SortableItemsList>
        </DndContext>
      </TableRowsContainer>
      <Modal
        isOpen={isProjectDetailsOpen}
        title={`${detailName}`}
        type={MODAL_TYPE.EDIT}
        wrapperId={`${detailName}`}
      >
        <DetailsForm
          handleClose={() => {
            setCurrentModalName("");
            setIsProjectDetailsOpen(false);
          }}
          projectId={projectId}
          type={type}
          details={details}
          isEditing
          detailLength={projectDetails.length}
        />
      </Modal>
    </>
  );
};

const initialScopeState: ScopeFormValues = {
  description: "",
  in_scope: true,
  title: "",
  id: 0,
  sequence: 0,
};

export const ScopeTableRenderer = ({
  projectId,
  scopeDetails,
  error,
}: {
  projectId: string;
  scopeDetails: ProjectScope[] | undefined;
  error: Error | null;
}) => {
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState(initialScopeState);

  const [currentProjectScope, setCurrentProjectScope] = useState<
    ProjectScope[]
  >([]);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (scopeDetails) {
      setCurrentProjectScope(scopeDetails);
    }
  }, [scopeDetails]);

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectScope } = useDeleteProjectScope();

  const { isLoading, editScopeRanks } = useUpdateScopeRanking();

  const handleEdit = (details: ProjectScope) => {
    setDetails({
      ...details,
    });
    setCurrentModalName(`Edit ${MODAL_NAMES.SCOPE}`);
    setIsProjectDetailsOpen(true);
  };

  const handleDelete = (detailId: number) => {
    deleteProjectScope(
      { detailId },
      {
        onSuccess: () => toast.success("Project scope removed successfully."),
      }
    );
  };

  const handleSave = () => {
    const latestRanks = currentProjectScope.map((details, index) => ({
      id: details.id,
      sequence: index + 1,
    }));
    editScopeRanks(latestRanks, {
      onSuccess: () => {
        toast.success(`Project scope sequencing saved successfully.`);
        setIsSaveEnabled(false);
      },
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectScope>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.sequence, {
        header: "Rank",
        id: "row-id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("in_scope", {
        header: "In Scope",
        id: "in_scope",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: scopeDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // drag and drop setup
  const getCurrentIndexPos = (
    id: number | string,
    items: ProjectDetailsType[]
  ) => items.findIndex((item) => item.id === id);

  const handleDragEnd = (
    event: DragEndEvent,
    setItems: React.Dispatch<React.SetStateAction<ProjectScope[]>>
  ) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setItems((items) => {
      const originalPos = getCurrentIndexPos(active.id, items);
      const newPos = getCurrentIndexPos(over?.id, items);
      return arrayMove(items, originalPos, newPos);
    });
    setIsSaveEnabled(true);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isLoading || isDeleting) return <Spinner />;

  if (error || !scopeDetails?.length)
    return (
      <>
        <NoDataCard
          title={"scope"}
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.SCOPE);
            setIsProjectDetailsOpen(true);
          }}
        />{" "}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.SCOPE}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.SCOPE}
        >
          <ScopeForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
            detailLength={0}
          />
        </Modal>
      </>
    );

  return (
    <>
      {isSaveEnabled && (
        <ActionItemP onClick={handleSave}>
          {" "}
          <CheckMark
            height={"1.4rem"}
            width={"1.4rem"}
            fill={"var(--color-button-secondary)"}
            stroke={"var(--color-button-secondary)"}
          />{" "}
          Save{" "}
        </ActionItemP>
      )}
      <ScopeTableHeader id={`project-scope`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </ScopeTableHeader>
      <TableRowsContainer>
        <DndContext
          collisionDetection={closestCorners}
          onDragEnd={(e) => handleDragEnd(e, setCurrentProjectScope)}
          sensors={sensors}
          id="ranks-container"
        >
          <SortableItemsList>
            {currentProjectScope.map((item, index) => (
              <SortableContext
                items={currentProjectScope}
                strategy={verticalListSortingStrategy}
                key={item.id}
              >
                <SortableScopeListRenderer
                  item={item}
                  key={item.id}
                  index={index}
                  length={currentProjectScope.length}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              </SortableContext>
            ))}
          </SortableItemsList>
        </DndContext>
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.SCOPE}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.SCOPE}`}
        >
          <ScopeForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            detailLength={scopeDetails.length}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialSuccessState: SuccessMeasureFormValues = {
  source_of_benefit: "",
  description: "",
  sequence: 0,
};
export const SuccessTableRenderer = ({
  projectId,
  successDetails,
  error,
}: {
  projectId: string;
  successDetails: ProjectSuccessMeasure[] | undefined;
  error: Error | null;
}) => {
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] =
    useState<SuccessMeasureFormValues>(initialSuccessState);

  const [currentProjectSuccessMeasure, setCurrentProjectSuccessMeasure] =
    useState<ProjectSuccessMeasure[]>([]);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (successDetails) {
      setCurrentProjectSuccessMeasure(successDetails);
    }
  }, [successDetails]);
  const { isLoading, editSuccessRanks } = useUpdateSuccessRanking();

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectSuccessMeasure } = useDeleteProjectSuccess();

  const handleEdit = (details: ProjectSuccessMeasure) => {
    setDetails({
      ...details,
    });
    setCurrentModalName(`Edit ${MODAL_NAMES.SUCCESS}`);
    setIsProjectDetailsOpen(true);
  };

  const handleDelete = (detailId: number) => {
    deleteProjectSuccessMeasure(
      { detailId },
      {
        onSuccess: () =>
          toast.success("Project success measure removed successfully."),
      }
    );
  };

  const handleSave = () => {
    const latestRanks = currentProjectSuccessMeasure.map((details, index) => ({
      id: details.id,
      sequence: index + 1,
    }));
    editSuccessRanks(latestRanks, {
      onSuccess: () => {
        toast.success(`Project success sequencing saved successfully.`);
        setIsSaveEnabled(false);
      },
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectSuccessMeasure>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.sequence, {
        header: "Rank",
        id: "row-id",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("source_of_benefit", {
        header: "Source of Benefit",
        id: "source_of_benefit",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: successDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // drag and drop setup
  const getCurrentIndexPos = (
    id: number | string,
    items: ProjectDetailsType[]
  ) => items.findIndex((item) => item.id === id);

  const handleDragEnd = (
    event: DragEndEvent,
    setItems: React.Dispatch<React.SetStateAction<ProjectSuccessMeasure[]>>
  ) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setItems((items) => {
      const originalPos = getCurrentIndexPos(active.id, items);
      const newPos = getCurrentIndexPos(over?.id, items);
      return arrayMove(items, originalPos, newPos);
    });
    setIsSaveEnabled(true);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isDeleting || isLoading) return <Spinner />;

  if (error || !successDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project success measures"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.SUCCESS);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.SUCCESS}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.SUCCESS}
        >
          <SuccessMeasureForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
            detailLength={0}
          />
        </Modal>
      </>
    );

  return (
    <>
      {isSaveEnabled && (
        <ActionItemP onClick={handleSave}>
          {" "}
          <CheckMark
            height={"1.4rem"}
            width={"1.4rem"}
            fill={"var(--color-button-secondary)"}
            stroke={"var(--color-button-secondary)"}
          />{" "}
          Save{" "}
        </ActionItemP>
      )}
      <SuccessTableHeader id={`project-success-measure`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </SuccessTableHeader>
      <TableRowsContainer>
        <DndContext
          collisionDetection={closestCorners}
          onDragEnd={(e) => handleDragEnd(e, setCurrentProjectSuccessMeasure)}
          sensors={sensors}
          id="ranks-container"
        >
          <SortableItemsList>
            {currentProjectSuccessMeasure.map((item, index) => (
              <SortableContext
                items={currentProjectSuccessMeasure}
                strategy={verticalListSortingStrategy}
                key={item.id}
              >
                <SortableSuccessListRenderer
                  item={item}
                  key={item.id}
                  index={index}
                  length={currentProjectSuccessMeasure.length}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              </SortableContext>
            ))}
          </SortableItemsList>
        </DndContext>
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.SUCCESS}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.SUCCESS}`}
        >
          <SuccessMeasureForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
            detailLength={successDetails.length}
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialStakeholderState = {
  details: "",
  email: "",
  name: "",
  type: "",
  team_role: "",
};

export const StakeholderTableRenderer = ({
  projectId,
}: {
  projectId: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<StakeholderFormValues>(
    initialStakeholderState
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isStakeholderLoading, stakeholderDetails, error } =
    useGetProjectStakeholders(projectId);

  const { isDeleting, deleteProjectStakeholder } =
    useDeleteProjectStakeholder();

  const handleDelete = (detailId: string) => {
    deleteProjectStakeholder(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project stakeholder removed successfully."),
      }
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectStakeholdersType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row-id",
      }),
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
      }),
      columnHelper.accessor("email", {
        header: "Email",
        id: "email",
      }),
      columnHelper.accessor("stakeholder_type", {
        header: "Stakeholder Type",
        id: "type",
      }),
      columnHelper.accessor("project_team_role", {
        header: "Team Role",
        id: "role",
      }),
      columnHelper.accessor("details", {
        header: "Details",
        id: "details",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: stakeholderDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
        email: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (isStakeholderLoading) return <Spinner />;
  if (error || !stakeholderDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project stakeholders"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.STAKEHOLDERS);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.STAKEHOLDERS}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.STAKEHOLDERS}
        >
          <StakeholderForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
          />
        </Modal>
      </>
    );

  return (
    <>
      <StakeholderTableHeader id={`project-stakeholders`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </StakeholderTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <StakeholderTableRow
            key={row.id}
            id={`${row.id}-details`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("name")}</span>
            </TableTitle>
            <span>{row.getValue("type")}</span>
            <span>{row.getValue("role")}</span>
            <span>{row.getValue("details")}</span>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    type: row.getValue("type"),
                    name: row.getValue("name"),
                    team_role: row.getValue("role"),
                    details: row.getValue("details"),
                    email: row.getValue("email"),
                    id: row.getValue("row-id"),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.STAKEHOLDERS}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </StakeholderTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.STAKEHOLDERS}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.STAKEHOLDERS}`}
        >
          <StakeholderForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialConsultationState: ConsultationFormValues = {
  title: "",
  details: "",
  communication_method: "",
  message: "",
  who: "",
  when: "",
};

export const ConsultationTableRenderer = ({
  projectId,
}: {
  projectId: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ConsultationFormValues>(
    initialConsultationState
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isConsultationLoading, consultationDetails, error } =
    useGetProjectConsultations(projectId);

  const { isDeleting, deleteProjectConsultation } =
    useDeleteProjectConsultation();

  const handleDelete = (detailId: string) => {
    deleteProjectConsultation(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project consultation plan removed successfully."),
      }
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectConsultationType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row-id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("communication_method", {
        header: "Communication Method",
        id: "communication_method",
      }),
      columnHelper.accessor("who", {
        header: "Target Audience",
        id: "who",
      }),
      columnHelper.accessor("when", {
        header: "Date",
        id: "when",
      }),
      columnHelper.accessor("message", {
        header: "Message",
        id: "message",
      }),
      columnHelper.accessor("details", {
        header: "Details",
        id: "details",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: consultationDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
      },
    },
    onSortingChange: setSorting,
  });

  if (isConsultationLoading) return <Spinner />;
  if (error || !consultationDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project consultation plan"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.CONSULTATION);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.CONSULTATION}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.CONSULTATION}
        >
          <ConsultationForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
          />
        </Modal>
      </>
    );

  return (
    <>
      <ConsultationTableHeader id={`project-consultations`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </ConsultationTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <ConsultationTableRow
            key={row.id}
            id={`${row.id}-details`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("title")}</span>
            </TableTitle>
            <span>{row.getValue("communication_method")}</span>
            <span>{row.getValue("who")}</span>
            <span>
              {moment(row.getValue("when")).format(DAY_FORMATS.DAY_FIRST)}
            </span>
            <span>{row.getValue("message")}</span>
            <span>{row.getValue("details")}</span>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    title: row.getValue("title"),
                    who: row.getValue("who"),
                    when: moment(row.getValue("when")).format(
                      DAY_FORMATS.YEAR_FIRST
                    ),
                    details: row.getValue("details"),
                    communication_method: row.getValue("communication_method"),
                    message: row.getValue("message"),
                    id: row.getValue("row-id"),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.CONSULTATION}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </ConsultationTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.CONSULTATION}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.CONSULTATION}`}
        >
          <ConsultationForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialRiskState: RiskFormValues = {
  risk_control: "",
  details: "",
  responsible_person_id: 0,
  risk_rank: "",
  risk_type: "",
};
export const RiskTableRenderer = ({ projectId }: { projectId: string }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<RiskFormValues>(initialRiskState);

  const { setCurrentModalName } = useActiveModalContext();
  const { isRisksLoading, riskDetails, error } = useGetProjectRisks(projectId);
  const { isDeleting, deleteProjectRisk } = useDeleteProjectRisk();

  const handleDelete = (detailId: string) => {
    deleteProjectRisk(
      { detailId: +detailId },
      {
        onSuccess: () => toast.success("Project risk removed successfully."),
      }
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectRiskType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row-id",
      }),
      columnHelper.accessor("risk_type", {
        header: "Type",
        id: "risk_type",
      }),
      columnHelper.accessor("details", {
        header: "Details",
        id: "details",
      }),
      columnHelper.accessor("risk_control", {
        header: "Control",
        id: "risk_control",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Owner",
          id: "responsible",
        }
      ),
      columnHelper.accessor("risk_rank", {
        header: "Rating",
        id: "risk_rank",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: riskDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
        responsible_person_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (isRisksLoading) return <Spinner />;
  if (error || !riskDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project risks"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.RISKS);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.RISKS}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.RISKS}
        >
          <RiskForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
          />
        </Modal>
      </>
    );

  return (
    <>
      <RiskTableHeader id={`project-risks`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </RiskTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <RiskTableRow
            key={row.id}
            id={`${row.id}-details`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("risk_type")}</span>
            </TableTitle>
            <span>{row.getValue("details")}</span>
            <span>{row.getValue("risk_control")}</span>
            <span>{row.getValue("responsible")}</span>
            <span>{row.getValue("risk_rank")}</span>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    risk_type: row.getValue("risk_type"),
                    risk_control: row.getValue("risk_control"),
                    details: row.getValue("details"),
                    risk_rank: row.getValue("risk_rank"),
                    id: row.getValue("row-id"),
                    responsible_person_id: row.getValue(
                      "responsible_person_id"
                    ),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.RISKS}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </RiskTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.RISKS}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.RISKS}`}
        >
          <RiskForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialImpactState: ImpactFormValues = {
  details: "",
  target: "",
  title: "",
};
export const ImpactTableRenderer = ({ projectId }: { projectId: string }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ImpactFormValues>(initialImpactState);

  const { setCurrentModalName } = useActiveModalContext();
  const { isImpactLoading, impactDetails, error } =
    useGetProjectImpacts(projectId);
  const { isDeleting, deleteProjectImpact } = useDeleteProjectImpact();

  const handleDelete = (detailId: string) => {
    deleteProjectImpact(
      { detailId: +detailId },
      {
        onSuccess: () => toast.success("Project impact removed successfully."),
      }
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectImpactType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row-id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("target", {
        header: "Target",
        id: "target",
      }),
      columnHelper.accessor("details", {
        header: "Details",
        id: "details",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: impactDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
      },
    },
    onSortingChange: setSorting,
  });

  if (isImpactLoading) return <Spinner />;
  if (error || !impactDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project impacts"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.IMPACTS);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.IMPACTS}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.IMPACTS}
        >
          <ImpactForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
          />
        </Modal>
      </>
    );

  return (
    <>
      <ImpactTableHeader id={`project-impacts`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </ImpactTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <ImpactTableRow
            key={row.id}
            id={`${row.id}-details`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("title")}</span>
            </TableTitle>
            <span>{row.getValue("target")}</span>
            <span>{row.getValue("details")}</span>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    title: row.getValue("title"),
                    target: row.getValue("target"),
                    details: row.getValue("details"),
                    id: row.getValue("row-id"),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.IMPACTS}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </ImpactTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.IMPACTS}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.IMPACTS}`}
        >
          <ImpactForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialWorkforceState: WorkforceFormValues = {
  resource_type: "",
  hours: 0,
  start_date: "",
  end_date: "",
  ongoing: "",
  id: 0,
  staff: "",
};
export const WorkforceTableRenderer = ({
  projectId,
}: {
  projectId: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<WorkforceFormValues>(
    initialWorkforceState
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isWorkforceLoading, workforceDetails, error } =
    useGetProjectWorkforces(projectId);
  const { isDeleting, deleteProjectWorkforce } = useDeleteProjectWorkforce();

  const handleDelete = (detailId: string) => {
    deleteProjectWorkforce(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project workforce removed successfully."),
      }
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectWorkforceType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row-id",
      }),
      columnHelper.accessor("resource_type", {
        header: "Type",
        id: "resource_type",
      }),
      columnHelper.accessor("staff", {
        header: "Name",
        id: "staff",
      }),
      columnHelper.accessor("hours", {
        header: "Estimated Effort",
        id: "hours",
      }),
      columnHelper.accessor("start_date", {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor("end_date", {
        header: "End Date",
        id: "end_date",
      }),
      columnHelper.accessor("ongoing", {
        header: "Ongoing",
        id: "ongoing",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: workforceDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
      },
    },
    onSortingChange: setSorting,
  });

  if (isWorkforceLoading) return <Spinner />;
  if (error || !workforceDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project workforces"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.WORKFORCE);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.WORKFORCE}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.WORKFORCE}
        >
          <WorkforceForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
          />
        </Modal>
      </>
    );

  return (
    <>
      <WorkforceTableHeader id={`project-workforces`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </WorkforceTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <WorkforceTableRow
            key={row.id}
            id={`${row.id}-details`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("resource_type")}</span>
            </TableTitle>
            <span>{row.getValue("staff")}</span>
            <span>{row.getValue("hours")}</span>
            <span>
              {moment(row.getValue("start_date")).format(DAY_FORMATS.DAY_FIRST)}
            </span>
            <span>
              {moment(row.getValue("end_date")).format(DAY_FORMATS.DAY_FIRST)}
            </span>
            <span>{row.getValue("ongoing")}</span>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    resource_type: row.getValue("resource_type"),
                    hours: row.getValue("hours"),
                    start_date: moment(row.getValue("start_date")).format(
                      DAY_FORMATS.YEAR_FIRST
                    ),
                    end_date: moment(row.getValue("end_date")).format(
                      DAY_FORMATS.YEAR_FIRST
                    ),
                    ongoing: row.getValue("ongoing"),
                    id: row.getValue("row-id"),
                    staff: row.getValue("staff"),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.WORKFORCE}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </WorkforceTableRow>
        ))}
      </TableRowsContainer>
      <Modal
        isOpen={isProjectDetailsOpen}
        title={MODAL_NAMES.WORKFORCE}
        type={MODAL_TYPE.EDIT}
        wrapperId={`Edit ${MODAL_NAMES.WORKFORCE}`}
      >
        <WorkforceForm
          handleClose={() => {
            setCurrentModalName("");
            setIsProjectDetailsOpen(false);
          }}
          projectId={projectId}
          details={details}
          isEditingMode
        />
      </Modal>
    </>
  );
};

const initialFinanceState: FinanceFormValues = {
  actual_amount: null,
  est_amount: null,
  commited_amount: null,
  budgeted_amount: null,
  comments: "",
  cost_type: "",
  details: "",
  end_year: "",
  financial_type: "",
  funding_source: "",
  ongoing: "",
  percentage_per_year: 0,
  start_year: "",
  id: 0,
  project_id: 0,
  section_id: null,
};
export const FinanceTableRenderer = ({ projectId }: { projectId: string }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] =
    useState<FinanceFormValues>(initialFinanceState);

  const { setCurrentModalName } = useActiveModalContext();
  const { isFinanceLoading, financeDetails, error } =
    useGetProjectFinances(projectId);
  const { isDeleting, deleteProjectFinance } = useDeleteProjectFinance();

  const handleDelete = (detailId: string) => {
    deleteProjectFinance(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project financials removed successfully."),
      }
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectFinanceType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row-id",
      }),
      columnHelper.accessor("financial_type", {
        header: "Type",
        id: "financial_type",
      }),
      columnHelper.accessor("est_amount", {
        header: "Estimated Amount",
        id: "est_amount",
      }),
      columnHelper.accessor("actual_amount", {
        header: "Actual Amount",
        id: "actual_amount",
      }),
      columnHelper.accessor("commited_amount", {
        header: "Committed Amount",
        id: "commited_amount",
      }),
      columnHelper.accessor("budgeted_amount", {
        header: "Budgeted Amount",
        id: "budgeted_amount",
      }),
      columnHelper.accessor("funding_source", {
        header: "Source",
        id: "funding_source",
      }),
      columnHelper.accessor("start_year", {
        header: "Start Year",
        id: "start_year",
      }),
      columnHelper.accessor("end_year", {
        header: "End Year",
        id: "end_year",
      }),
      columnHelper.accessor("percentage_per_year", {
        header: "% per year",
        id: "percentage_per_year",
      }),
      columnHelper.accessor("ongoing", {
        header: "Ongoing",
        id: "ongoing",
      }),
      columnHelper.accessor("cost_type", {
        header: "cost_type",
        id: "cost_type",
      }),
      columnHelper.accessor("details", {
        header: "Details",
        id: "details",
      }),
      columnHelper.accessor("comments", {
        header: "comments",
        id: "comments",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: financeDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
        cost_type: false,
        ongoing: false,
        percentage_per_year: false,
        comments: false,
        actual_amount: false,
        budgeted_amount: false,
        commited_amount: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (isFinanceLoading) return <Spinner />;
  if (error || !financeDetails?.length)
    return (
      <>
        <NoDataCard
          title="Project financials"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.FINANCE);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.FINANCE}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.FINANCE}
        >
          <FinanceForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </>
    );

  return (
    <>
      <FinancialTableHeader id={`project-financials`}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </FinancialTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <FinancialTableRow
            key={row.id}
            id={`${row.id}-details`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("financial_type")}</span>
            </TableTitle>
            <span>{getFormattedCurrency(row.getValue("est_amount"))}</span>
            <span>{row.getValue("funding_source")}</span>
            <span>{moment(row.getValue("start_year")).format("YYYY")}</span>
            <span>{moment(row.getValue("end_year")).format("YYYY")}</span>
            <span>{row.getValue("details")}</span>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    financial_type: row.getValue("financial_type"),
                    actual_amount: row.getValue("actual_amount"),
                    est_amount: row.getValue("est_amount"),
                    commited_amount: row.getValue("commited_amount"),
                    budgeted_amount: row.getValue("budgeted_amount"),
                    details: row.getValue("details"),
                    start_year: row.getValue("start_year"),
                    end_year: row.getValue("end_year"),
                    ongoing: row.getValue("ongoing"),
                    cost_type: row.getValue("cost_type"),
                    percentage_per_year: row.getValue("percentage_per_year"),
                    funding_source: row.getValue("funding_source"),
                    comments: row.getValue("comments"),
                    project_id: +projectId,
                    section_id: null,
                    id: row.getValue("row-id"),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.FINANCE}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </FinancialTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.FINANCE}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.FINANCE}`}
        >
          <FinanceForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialMilestoneState: MilestoneFormValues = {
  end_year: "",
  percentage_of_project: 0,
  responsible_person_id: 0,
  start_year: "",
  title: "",
  description: "",
  sequence: "",
  service_id: 0,
  status_id: 0,
  id: 0,
  est_end_date: "",
  est_start_date: "",
};
export const MilestoneTableRenderer = ({
  projectId,
  milestones,
  error,
}: {
  projectId: string;
  milestones: Milestone[] | undefined;
  error: Error | null;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<MilestoneFormValues>(
    initialMilestoneState
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectMilestone } = useDeleteProjectMilestone();

  const handleDelete = (milestoneId: string) => {
    deleteProjectMilestone(
      { milestoneId: +milestoneId },
      {
        onSuccess: () =>
          toast.success("Project milestone removed successfully."),
      }
    );
  };

  const columnHelper = createColumnHelper<Milestone>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "row-id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor((row) => row.start_year, {
        header: "Start Year",
        id: "start_year",
      }),
      columnHelper.accessor((row) => row.end_year, {
        header: "End Year",
        id: "end_year",
      }),
      columnHelper.accessor((row) => row.est_start_date, {
        header: "Estimated Start Year",
        id: "est_start_date",
      }),
      columnHelper.accessor((row) => row.est_end_date, {
        header: "Estimated End Year",
        id: "est_end_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        }
      ),
      columnHelper.accessor("percentage_of_project", {
        header: "% of Project",
        id: "percentage_of_project",
      }),

      columnHelper.accessor("status.title", {
        header: "Progress Status",
        id: "status",
      }),
      columnHelper.accessor("status_id", {
        id: "status_id",
      }),
      columnHelper.accessor("service_id", {
        id: "service_id",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: milestones ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
        status_id: false,
        service_id: false,
        responsible_person_id: false,
        est_start_date: false,
        est_end_date: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !milestones?.length)
    return (
      <>
        <NoDataCard
          title="Project milestones"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.MILESTONES);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.MILESTONES}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.MILESTONES}
        >
          <MilestoneForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            isEditingMode={false}
          />
        </Modal>
      </>
    );

  return (
    <>
      <MilestoneTableHeader id="project-milestones-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </MilestoneTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <MilestoneTableRow
            key={row.id}
            id={`${row.id}-milestones`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("title")}</span>
            </TableTitle>
            <span>{row.getValue("description") || "N/A"}</span>
            <span>
              {row.getValue("start_year")
                ? moment(row.getValue("start_year")).format("YYYY")
                : "N/A"}
            </span>
            <span>
              {" "}
              {row.getValue("end_year")
                ? moment(row.getValue("end_year")).format("YYYY")
                : "N/A"}
            </span>
            <span>{row.getValue("responsible")}</span>
            <span>{row.getValue("percentage_of_project")}</span>

            <Pill
              $progressStatus={
                row.getValue("status") as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{row.getValue("status")}</span>
            </Pill>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    title: row.getValue("title"),
                    description: row.getValue("description"),
                    status_id: row.getValue("status_id"),
                    start_year: row.getValue("start_year")
                      ? moment(row.getValue("start_year")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    end_year: row.getValue("end_year")
                      ? moment(row.getValue("end_year")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    est_start_date: row.getValue("est_start_date")
                      ? moment(row.getValue("est_start_date")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    est_end_date: row.getValue("est_end_date")
                      ? moment(row.getValue("est_end_date")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    id: row.getValue("row-id"),
                    sequence: "sequence",
                    service_id: row.getValue("service_id"),
                    percentage_of_project: row.getValue(
                      "percentage_of_project"
                    ),
                    responsible_person_id: row.getValue(
                      "responsible_person_id"
                    ),
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.MILESTONES}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </MilestoneTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.MILESTONES}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.MILESTONES}`}
        >
          <MilestoneForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};

const initialTaskState: TaskFormValues = {
  due_date: "",
  milestone_id: 0,
  percentage_completed: 0,
  percentage_of_milestone: 0,
  priority: "",
  responsible_person_id: 0,
  service_id: 0,
  start_date: "",
  status_id: 0,
  title: "",
  description: "",
  id: 0,
  est_end_date: "",
  est_start_date: "",
};

export const TaskTableRenderer = ({
  milestones,
}: {
  milestones: Milestone[] | undefined;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<TaskFormValues>(initialTaskState);

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectTask } = useDeleteProjectTask();

  const handleDelete = (taskId: string) => {
    deleteProjectTask(
      { taskId: +taskId },
      {
        onSuccess: () => toast.success("Project task removed successfully."),
      }
    );
  };

  const tasks = useMemo(
    () =>
      milestones
        ?.map((milestone) => milestone.tasks)
        ?.flat()
        ?.filter((task) => !task.is_deleted)
        ?.sort((a, b) =>
          moment(a.due_date).isBefore(moment(b.due_date)) ? -1 : 1
        ),
    [milestones]
  );

  const columnHelper = createColumnHelper<Task>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "row-id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor(
        (row) =>
          milestones?.find((milestone) => milestone.id === row.milestone_id)
            ?.title || "N/A",
        {
          header: "Milestone",
          id: "milestone",
        }
      ),
      columnHelper.accessor((row) => row.start_date, {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor((row) => row.due_date, {
        header: "Due Date",
        id: "due_date",
      }),
      columnHelper.accessor((row) => row.est_start_date, {
        header: "Estimated Start Date",
        id: "est_start_date",
      }),
      columnHelper.accessor((row) => row.est_end_date, {
        header: "Estimated End Date",
        id: "est_end_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        }
      ),
      columnHelper.accessor("percentage_of_milestone", {
        header: "% of Milestone",
        id: "percentage_of_milestone",
      }),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_completed",
      }),
      columnHelper.accessor("status.title", {
        header: "Progress Status",
        id: "status",
      }),
      columnHelper.accessor("status_id", {
        id: "status_id",
      }),
      columnHelper.accessor("service_id", {
        id: "service_id",
      }),
      columnHelper.accessor("milestone_id", {
        id: "milestone_id",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
    ],
    [columnHelper, milestones]
  );

  // React table config
  const table = useReactTable({
    data: tasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        "row-id": false,
        status_id: false,
        service_id: false,
        milestone_id: false,
        percentage_of_milestone: false,
        responsible_person_id: false,
        start_date: false,
        est_start_date: false,
        est_end_date: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (!tasks?.length)
    return (
      <>
        <NoDataCard
          title="Project tasks"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.TASKS);
            setIsProjectDetailsOpen(true);
          }}
        />
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.TASKS}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.TASKS}
        >
          <TaskForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            isEditingMode={false}
            milestones={milestones}
          />
        </Modal>
      </>
    );

  return (
    <>
      <TaskTableHeader id="project-tasks-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          ))
        )}
      </TaskTableHeader>
      <TableRowsContainer>
        {table.getRowModel().rows.map((row) => (
          <TaskTableRow
            key={row.id}
            id={`${row.id}-tasks`}
            disabled={isDeleting}
          >
            <TableTitle>
              <span>{row.getValue("title")}</span>
            </TableTitle>
            <span>{row.getValue("description") || "N/A"}</span>
            <span>{row.getValue("milestone")}</span>
            {/* <span>
              {row.getValue("start_date")
                ? moment(row.getValue("start_date")).format(
                    DAY_FORMATS.DAY_FIRST
                  )
                : "N/A"}
            </span> */}
            <span>
              {row.getValue("due_date")
                ? moment(row.getValue("due_date")).format(DAY_FORMATS.DAY_FIRST)
                : "N/A"}
            </span>
            <span>{row.getValue("responsible")}</span>
            <span>{row.getValue("percentage_completed")}</span>
            <Pill
              $progressStatus={
                row.getValue("status") as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{row.getValue("status")}</span>
            </Pill>
            <EditDeleteContainer>
              <Edit
                onClick={() => {
                  setDetails({
                    title: row.getValue("title"),
                    description: row.getValue("description"),
                    status_id: row.getValue("status_id"),
                    start_date: row.getValue("start_date")
                      ? moment(row.getValue("start_date")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    due_date: row.getValue("due_date")
                      ? moment(row.getValue("due_date")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    est_start_date: row.getValue("est_start_date")
                      ? moment(row.getValue("est_start_date")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    est_end_date: row.getValue("est_end_date")
                      ? moment(row.getValue("est_end_date")).format(
                          DAY_FORMATS.YEAR_FIRST
                        )
                      : "",
                    id: row.getValue("row-id"),
                    service_id: row.getValue("service_id"),
                    percentage_of_milestone: row.getValue(
                      "percentage_of_milestone"
                    ),
                    percentage_completed: row.getValue("percentage_completed"),
                    milestone_id: row.getValue("milestone_id"),
                    responsible_person_id: row.getValue(
                      "responsible_person_id"
                    ),
                    priority: "priority",
                  });
                  setCurrentModalName(`Edit ${MODAL_NAMES.TASKS}`);
                  setIsProjectDetailsOpen(true);
                }}
              />
              <DeleteCon onClick={() => handleDelete(row.getValue("row-id"))} />
            </EditDeleteContainer>
          </TaskTableRow>
        ))}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.TASKS}
          type={MODAL_TYPE.EDIT}
          wrapperId={`Edit ${MODAL_NAMES.TASKS}`}
        >
          <TaskForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            details={details}
            isEditingMode
            milestones={milestones}
          />
        </Modal>
      </TableRowsContainer>
    </>
  );
};
