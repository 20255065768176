import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import { getAllStatusValuesAPI } from "../services/apiStatus";

export const useGetStatusValues = () => {
  const {
    isPending: isStatusLoading,
    data: statuses,
    error,
  } = useQuery({
    queryFn: () => getAllStatusValuesAPI({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.STATUS],
  });

  return { isStatusLoading, statuses, error };
};

