import { ContactType } from "../types/contactTypes";
import { DropdownType } from "../types/dropdownTypes";
import { Milestone } from "../types/milestoneTypes";
import { ServiceType, StatusType } from "../types/statusTypes";

export const getDropdownValues = (
  type: string,
  allValues: DropdownType[] | undefined
) =>
  (
    allValues &&
    allValues
      .filter((value) => value.category === type)
      ?.map((value) => ({
        id: `${value.id}`,
        name: value.title,
      }))
  )?.sort((a, b) => a.name.localeCompare(b.name)) || [];

export const getDropdownSelectedId = (
  dropdownItems: DropdownType[] | undefined,
  fieldValue: string
) => `${dropdownItems?.find((val) => val.id === +fieldValue)?.id}`;

export const getContactDropdownValues = (contacts: ContactType[] | undefined) =>
  (
    contacts &&
    contacts?.map((contact) => ({
      id: `${contact.id}`,
      name:
        contact.first_name + " " + contact.last_name + ` (${contact.email})`,
    }))
  )?.sort((a, b) => a.name.localeCompare(b.name)) || [];

export const getItemDropdownValues = (
  items: StatusType[] | ServiceType[] | Milestone[] | undefined
) =>
  (
    items &&
    items?.map((item) => ({
      id: `${item.id}`,
      name: item.title,
    }))
  )?.sort((a, b) => a.name.localeCompare(b.name)) || [];

export const getBooleanDropdownValues = () => [
  { id: "1", name: "Yes" },
  { id: "2", name: "No" },
];

export const getCastedBooleanValue = (id: string) => id === "1";
