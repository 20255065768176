import styled from "styled-components";
import ArrowUpRight from "../../assets/ArrowUpRight.svg?react";
import BackButton from "../../assets/BackButton.svg?react";
import Check from "../../assets/CheckCircle.svg?react";
import Dollar from "../../assets/CurrencyCircleDollar.svg?react";
import Hourglass from "../../assets/Hourglass.svg?react";
import Location from "../../assets/LocationBlack.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Search from "../../assets/Search.svg?react";

import moment from "moment";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import NoDataCard from "../../ui/NoDataCard";
import ProgressBar from "../../ui/ProgressBar";
import Spinner from "../../ui/Spinner";
import ToggleButton from "../../ui/ToggleButton";
import { getFormattedCurrency } from "../../utils/common";
import ProfileLink from "../navigation/ProfileLink";
import { useGetMilestoneByProjectId } from "./useGetMilestoneByProjectId";
import { useGetProjectById } from "./useGetProjectById";

import Edit from "../../assets/Edit.svg?react";
import Filter from "../../assets/Filter.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import PushPinSlash from "../../assets/PushPinSlash.svg?react";
import SortIcon from "../../assets/SortIcon.svg?react";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

ChartJS.register(Legend, Tooltip, CategoryScale, LinearScale, BarElement);

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Swiper.css";

import toast from "react-hot-toast";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  BUTTON_TYPES,
  PINNED_ITEM_TYPES,
  TASK_STATUS,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { Task } from "../../types/milestoneTypes";
import { PinHandler } from "../../types/savedItemTypes";
import ContextMenu from "../../ui/ContextMenu";
import NumberSpan from "../../ui/NumberSpan";
import PageNumbers from "../../ui/PageNumbers";
import { NextButton, PrevButton } from "../../utils/CarouselButtons";
import { getOffset } from "../../utils/GetOffset";
import { useGetCurrentUser } from "../authentication/useGetCurrentUser";

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 4.2rem 0;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  gap: 2rem;
  & span {
    font-size: 1.4rem;
    font-weight: 400;
  }
  & h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const ProjectEstimateContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  align-items: center;
  gap: 2rem;
`;

const ProjectCostEstimate = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: 400;
  & span {
    font-size: 4rem;
    color: var(--color-grey-300);
  }
  & p {
    font-size: 8rem;
    font-weight: 300;
  }
  letter-spacing: -4px;
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const LocationDiv = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const BudgetContainer = styled.div<{
  $frame1: number;
  $frame2: number;
  $frame3: number;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    `${props.$frame1}fr ${props.$frame2}fr ${props.$frame3}fr`};
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  & p {
    text-transform: uppercase;
  }
`;

const ProgressDiv = styled.div`
  background-color: #cfd5e3;
  border-radius: var(--border-radius-2xl);
  width: 100%;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const MilestonesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 80dvw;

  @media (min-width: 90em) {
    max-width: 90dvw;
  }
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
`;

const ProjectInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 2rem 0;
  align-items: center;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const MilestoneHeader = styled(ProjectInfoHeader)<{ disabled?: boolean }>`
  justify-content: unset;
  & span {
    font-style: italic;
  }
  gap: 1rem;
  padding: unset;
  margin-top: 4rem;
  opacity: ${(props) => props.disabled && "0.4"};
`;

const EachMilestone = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  border: 0.2rem dashed
    ${(props) => (props.$isActive ? "var(--color-grey-900)" : "transparent")};
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 17rem;
  cursor: pointer;
`;

const MilestoneTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const StatusSpan = styled.span<{ $progressStatus?: keyof typeof SCHEDULE_MAP }>`
  color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span<{
  $progressStatus?: keyof typeof SCHEDULE_MAP;
}>`
  height: 0.8rem;
  width: 0.8rem;
  background-color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  border-radius: var(--border-radius-2l);
`;

const ViewSpan = styled.span`
  color: var(--color-button-secondary);
  font-weight: 800;
  justify-self: end;
  align-self: end;
  cursor: pointer;
`;

const ProjectPara = styled.p`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StatusView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TasksSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TaskToggleSection = styled.div`
  display: flex;
  gap: 6rem;
`;

const TaskToggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr) 0.4fr;
  background-color: var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 500;
  font-size: 1.2rem;
  justify-items: start;
  padding: 0 2.5rem;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr) 0.2fr 0.2fr;
  background-color: white;
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  padding: 0 2.5rem;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const KanbanGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 40rem;
  gap: 1rem;
`;

const KanbanGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-grey-400-50);
  border-radius: var(--border-radius-xl);
  padding: 1rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem;
  }
`;

const TaskCard = styled(EachMilestone)<{ $isCompleted?: boolean }>`
  background-color: ${(props) => props.$isCompleted && "unset"};
  border: 2px dashed rgba(126, 134, 155, 0.5);
  cursor: unset;
  & svg {
    cursor: pointer;
  }
  height: 15rem;
`;

const BottomDate = styled.span`
  color: var(--color-ref-no);
  font-weight: 700;
  font-size: 1.2rem;
`;

const NoTasks = styled.div`
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
  font-style: italic;
  color: var(--color-grey-300);
`;

const SliderButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
`;

const StyledPinButton = styled.button`
  :disabled {
    cursor: not-allowed;
  }
  border: none;
  background-color: transparent;
`;

const ReportAndActivityContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  margin-top: 4rem;
`;

const ChartDiv = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 43rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-2l);
  padding: 2rem;
`;

const ActivityContainer = styled.div`
  background-color: white;
  border-radius: var(--border-radius-2l);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;

const DateDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;

  & p {
    color: var(--color-grey-400);
    &:first-child {
      color: var(--color-percent-complete-sec);
    }
  }
`;

const PercentDiv = styled.div`
  & p:last-child {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
`;

/**
 * @returns individual project summary by project ID
 */
const ProjectOverview = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  // state
  const [activeMilestoneId, setActiveMilestoneId] = useState<number | null>(
    null
  );
  const [tasks, setTasks] = useState<Task[]>();
  const [currentTaskView, setCurrentTaskView] = useState<
    "table" | "kanban" | "gantt"
  >("table");
  const [sorting, setSorting] = useState<SortingState>([
    { id: "isPinned", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  // swiper state
  const [swiperControl, setSwiperControl] = useState<SwiperType>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  // API data
  const { isLoading: isProjectLoading, project } = useGetProjectById(
    projectId || "1"
  );
  const { isLoading: isSavingPin, pinItem } = useSavePinItem();
  const { isLoading: isRemovingPin, removePinItem } = useRemovePinItem();

  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || ""
  );
  const pinnedMilestoneIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.MILESTONE)
        return { value: item.value, id: item.id };
    }) || [];

  const pinnedTaskIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.TASK)
        return { value: item.value, id: item.id };
    }) || [];

  const {
    isLoading: isMilestoneLoading,
    milestones,
    error: milestoneError,
  } = useGetMilestoneByProjectId(
    projectId || "1",
    pinnedMilestoneIds,
    pinnedTaskIds
  );

  // tasks table setup
  const columnHelper = createColumnHelper<Task>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("isPinned", {
        header: "isPinned",
        id: "isPinned",
      }),
      columnHelper.accessor("pinId", {
        header: "pinId",
        id: "pinId",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor((row) => moment(row.start_date).format("YYYY"), {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor((row) => moment(row.due_date).format("YYYY"), {
        header: "Due Date",
        id: "due_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        }
      ),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_complete",
      }),
      columnHelper.accessor("status.title", {
        header: "Status",
        id: "status",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: tasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        isPinned: false,
        pinId: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  // handlers
  const handleMilestoneClick = useCallback(
    (milestoneId: number) => {
      if (milestoneId === activeMilestoneId) {
        setActiveMilestoneId(null);
        setTasks([]);
      } else {
        setActiveMilestoneId(milestoneId);
        const updatedTasks =
          milestones
            ?.find((milestone) => milestone.id === milestoneId)
            ?.tasks.flat()
            ?.filter((task) => !task.is_deleted) || [];
        setTasks(updatedTasks);
      }
    },
    [activeMilestoneId, milestones]
  );

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLSpanElement>,
    pinId?: number
  ) => {
    event?.stopPropagation();
    handlePinningFromContext(type, value, savePin, pinId);
  };

  const handlePinningFromContext = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        }
      );
    } else if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        }
      );
    }
    // need to manually update tasks, since it's a stable reference
    if (type === PINNED_ITEM_TYPES.TASK) {
      const newTasks = tasks?.map((task) => {
        if (task.id === +value) {
          task.isPinned = savePin;
          task.pinId = pinId;
        }
        return task;
      });
      setTasks(newTasks);
    }
  };

  const financials = project?.financials || [];

  const budgetAmount = financials?.reduce(
    (acc, item) => (item.budgeted_amount || 0) + acc,
    0
  );

  const commitedAmount = financials?.reduce(
    (acc, item) => (item.commited_amount || 0) + acc,
    0
  );

  const actualAmount = financials?.reduce(
    (acc, item) => (item.actual_amount || 0) + acc,
    0
  );

  const projectEstimate = getFormattedCurrency(budgetAmount)
    .replace("$", "")
    .split(".");

  const disablePin = isSavingPin || isRemovingPin;

  // bar chart setup
  const barOptions: ChartOptions<"bar"> = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  // The following colors will be used sequentially for the chart bars
  const barData = {
    labels: ["Budget", "Actual", "Committed", "Balance"],
    datasets: [
      {
        // todo: change to actual data from project
        data: [
          budgetAmount,
          actualAmount,
          commitedAmount,
          budgetAmount - actualAmount,
        ],
        backgroundColor: ["#0067A0", "#FAC300", "#16B25E", "#EB3C7C"],
        barThickness: 30,
        barPercentage: 0.7,
        label: "Finance Report",
      },
    ],
  };

  const secondBarOptions: ChartOptions<"bar"> = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        max: 100,
        min: 0,
        title: { display: true, text: "Percentage Complete" },
      },
    },
  };

  const lineData = {
    labels: tasks?.map((_, index) => `Task ${index + 1}`),
    datasets: [
      {
        // todo: change to actual data from project
        data: tasks?.map((task) => task.percentage_completed),
        backgroundColor: tasks?.map((task) => {
          if (task.percentage_completed < 40) return "red";
          else if (task.percentage_completed < 75) return "#FAC300";
          else return "#16B25E";
        }),
        barThickness: 30,
      },
    ],
  };

  if (isProjectLoading || isMilestoneLoading || isPinnedItemsLoading)
    return <Spinner />;

  return (
    <>
      <ProjectHeader>
        <Heading>
          <Icons onClick={() => navigate(-1)}>
            <BackButton />
          </Icons>
          <div>
            <h1>
              Projects <span>| Overview </span>
            </h1>
            <h2>{project?.title}</h2>
          </div>
        </Heading>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            onClick={() => navigate(`/projects/details/${projectId}`)}
          >
            <span>Project Details</span>
            <ArrowUpRight />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <ProjectEstimateContainer>
        <ProjectGridItem>
          <h2>Project Budget</h2>
          <ProjectCostEstimate>
            <p>
              <span>$</span>
              {projectEstimate[0]}
              <span>
                {projectEstimate?.[1] ? `.${projectEstimate[1]}` : ".00"}
              </span>
            </p>
          </ProjectCostEstimate>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Cost centre</h2>
          {/* get cost centre from project */}
          <h1>Housing crisis review</h1>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Project owner</h2>
          <h1>{project?.owner.first_name + " " + project?.owner.last_name}</h1>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Project manager</h2>
          <h1>
            {project?.responsible.first_name +
              " " +
              project?.responsible.last_name}{" "}
          </h1>
        </ProjectGridItem>
        <LocationDiv>
          <Icons>
            <Location />
          </Icons>
          <ProjectGridItem>
            <h2>Location</h2>
            <h1>{project?.location}</h1>
          </ProjectGridItem>
        </LocationDiv>
      </ProjectEstimateContainer>
      {/* todo: send frame sizes using finance API */}
      <BudgetContainer
        $frame1={actualAmount}
        $frame2={commitedAmount}
        $frame3={budgetAmount - actualAmount}
      >
        <ProgressContainer>
          <p>Actual</p>
          <ProgressBar $bgColor="var(--color-budget-used-sec)">
            <Icons $bgColor="#22252A1A">
              <Check />
            </Icons>
            <p>{getFormattedCurrency(actualAmount)}</p>
          </ProgressBar>
        </ProgressContainer>
        <ProgressContainer>
          <p>Committed</p>
          <ProgressBar $bgColor="var(--color-budget-in-use)">
            <Icons $bgColor="#22252A1A">
              <Hourglass />
            </Icons>
            <p>{getFormattedCurrency(commitedAmount)}</p>
          </ProgressBar>
        </ProgressContainer>
        <ProgressContainer>
          <p>Balance</p>
          <ProgressBar $bgColor="var(--color-budget-remaining)">
            <Icons $bgColor="#22252A1A">
              <Dollar />
            </Icons>
            <p>{getFormattedCurrency(budgetAmount - actualAmount)}</p>
          </ProgressBar>
        </ProgressContainer>
      </BudgetContainer>
      <ReportAndActivityContainer>
        <ChartDiv>
          <Bar data={barData} options={barOptions} id="1" />
        </ChartDiv>
        <ChartDiv>
          <Bar data={lineData} options={secondBarOptions} id="2" />
          {!activeMilestoneId && (
            <p style={{ fontStyle: "italic", fontSize: "1.2rem" }}>
              Select a milestone to see the chart
            </p>
          )}
          {activeMilestoneId && !tasks?.length && (
            <p style={{ fontStyle: "italic", fontSize: "1.2rem" }}>
              No tasks available.
            </p>
          )}
        </ChartDiv>
        <ActivityContainer>
          <SectionHeader>
            <h1>Activity</h1>
            <StatusP>
              <StatusCircle></StatusCircle>
              <StatusSpan>
                Updated {moment().diff(moment(project?.updated), "days")} days
                ago
              </StatusSpan>
            </StatusP>
          </SectionHeader>
          <ProjectGridItem>
            <h2>Percentage Complete | Days Remaining</h2>
            <ProgressDiv>
              {/* todo: remove dummy values for % */}
              <ProgressBar
                $bgColor="#4BC7A2"
                width={project?.percentage_complete}
              >
                <Icons $bgColor="#22252A1A">
                  <Check />
                </Icons>
                {/* todo: add days remaining and bottom div for dates */}
                <PercentDiv>
                  <p>{project?.percentage_complete}%</p>
                  <p>
                    {project?.end_year
                      ? moment(project?.end_year).diff(moment(), "days")
                      : 0}{" "}
                    Days
                  </p>
                </PercentDiv>
              </ProgressBar>
            </ProgressDiv>
            <DateDiv>
              <p>
                {project?.start_year
                  ? moment(project?.start_year).format("DD/MM/YYYY")
                  : "N/A"}
              </p>
              <p>
                {project?.end_year
                  ? moment(project?.end_year).format("DD/MM/YYYY")
                  : "N/A"}
              </p>
            </DateDiv>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Project Status</h2>
            {/* get overall status from project */}
            <h1>{project?.status.title}</h1>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Progress Status</h2>
            <h1>{project?.status.title}</h1>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Project Definition</h2>
            <h1>{project?.project_definition}</h1>
          </ProjectGridItem>
          <ProjectGridItem>
            <h2>Project Type</h2>
            <h1>{project?.project_type.title}</h1>
          </ProjectGridItem>
        </ActivityContainer>
      </ReportAndActivityContainer>
      <MilestonesSection>
        <MilestoneHeader>
          <h1>Milestones {`[${milestones?.length || 0}]`}</h1>
          <span>{`(Click on a milestone to view it's tasks)`}</span>
        </MilestoneHeader>
        {milestoneError && (
          <NoDataCard
            title="milestones"
            createLink={`/projects/details/${projectId}?activeToggle=Milestones`}
          />
        )}
        <SwiperContainer>
          {milestones && (
            <Swiper
              cssMode
              navigation
              modules={[Navigation]}
              className="milestoneSwiper"
              spaceBetween={20}
              slidesPerView={3}
              onSwiper={(swiper) => setSwiperControl(swiper)}
              onSlideChange={() =>
                setActiveSlideIndex(swiperControl?.activeIndex || 0)
              }
              id="milestone-swiper"
              enabled={milestones.length > 3}
            >
              {milestones?.map((milestone) => (
                <SwiperSlide
                  key={milestone.id}
                  className="milestone-swiper-slide"
                >
                  <EachMilestone
                    key={milestone.id}
                    onClick={() => handleMilestoneClick(milestone.id)}
                    $isActive={milestone.id === activeMilestoneId}
                  >
                    <MilestoneTitle>
                      {milestone.title}
                      {milestone.isPinned ? (
                        <StyledPinButton
                          onClick={(event) =>
                            handlePinning(
                              PINNED_ITEM_TYPES.MILESTONE,
                              `${milestone.id}`,
                              false,
                              event,
                              milestone.pinId
                            )
                          }
                          disabled={disablePin}
                        >
                          <PinFilled />
                        </StyledPinButton>
                      ) : (
                        <StyledPinButton
                          onClick={(event) =>
                            handlePinning(
                              PINNED_ITEM_TYPES.MILESTONE,
                              `${milestone.id}`,
                              true,
                              event
                            )
                          }
                          disabled={disablePin}
                        >
                          <PushPin />
                        </StyledPinButton>
                      )}
                    </MilestoneTitle>
                    <ProjectPara>
                      <span>
                        {milestone.start_year
                          ? moment(milestone.start_year).format("DD/MM/YYYY")
                          : "N/A"}{" "}
                        -{" "}
                        {milestone.end_year
                          ? moment(milestone.end_year).format("DD/MM/YYYY")
                          : "N/A"}
                      </span>
                      <span>{milestone.service?.title}</span>
                      <span>
                        {milestone.responsible.first_name +
                          " " +
                          milestone.responsible.last_name}
                      </span>
                    </ProjectPara>
                    <StatusView>
                      <StatusP>
                        <StatusCircle
                          $progressStatus={
                            milestone.status?.title as keyof typeof SCHEDULE_MAP
                          }
                        ></StatusCircle>
                        <StatusSpan
                          $progressStatus={
                            milestone.status?.title as keyof typeof SCHEDULE_MAP
                          }
                        >
                          {milestone.status?.title}
                        </StatusSpan>
                      </StatusP>
                      <ViewSpan
                        onClick={() =>
                          navigate(
                            `/projects/details/${projectId}?activeToggle=Milestones`
                          )
                        }
                      >
                        {"View >"}
                      </ViewSpan>
                    </StatusView>
                  </EachMilestone>
                </SwiperSlide>
              ))}
              <SliderButtonDiv>
                <PrevButton
                  onClick={() => swiperControl?.slideTo(activeSlideIndex - 1)}
                />
                <NextButton onClick={() => swiperControl?.slideNext()} />
              </SliderButtonDiv>
            </Swiper>
          )}
        </SwiperContainer>
        {activeMilestoneId && tasks?.length ? (
          <TasksSection>
            <ProjectInfoHeader>
              <h1>Tasks {`[${tasks?.length || 0}]`}</h1>
              <SideContainer>
                <Icons>
                  <SortIcon />{" "}
                </Icons>
                <Icons>
                  <Filter />{" "}
                </Icons>
                <TaskToggleSection>
                  <TaskToggles>
                    <ToggleButton
                      $buttonActive={currentTaskView === "table"}
                      onClick={() => setCurrentTaskView("table")}
                    >
                      Table View
                    </ToggleButton>
                    <ToggleButton
                      $buttonActive={currentTaskView === "kanban"}
                      onClick={() => setCurrentTaskView("kanban")}
                    >
                      KanBan View
                    </ToggleButton>
                  </TaskToggles>
                </TaskToggleSection>
              </SideContainer>
            </ProjectInfoHeader>
            {
              <TaskViewRender
                table={table}
                currentView={currentTaskView}
                tasks={tasks}
                handlePinning={handlePinningFromContext}
                disablePin={disablePin}
                projectId={projectId ? +projectId : 0}
              />
            }
          </TasksSection>
        ) : (
          <MilestoneHeader disabled>
            <h1>Tasks</h1>
            <span>{`[no tasks to show]`}</span>
          </MilestoneHeader>
        )}
      </MilestonesSection>
    </>
  );
};

export default ProjectOverview;

const getTaskContextMenu = (
  taskId: number,
  isPinned: boolean,
  projectId: number,
  pinId?: number,
  pinningHandler?: PinHandler
) => {
  return [
    {
      title: isPinned ? "Unpin" : "Pin",
      to: "",
      imageComponent: () => (isPinned ? PushPinSlash : PushPin),
      pinningHandler,
      pinningDetails: {
        type: PINNED_ITEM_TYPES.TASK,
        value: taskId,
        savePin: !isPinned,
        pinId,
      },
    },
    {
      title: "Edit & Complete",
      to: `/projects/details/${projectId}?activeToggle=Tasks`,
      imageComponent: () => Edit,
    },
  ];
};

const TaskViewRender = ({
  table,
  currentView,
  tasks,
  projectId,
  handlePinning,
  disablePin,
}: {
  table: Table<Task>;
  currentView: string;
  tasks: Task[];
  projectId: number;
  handlePinning?: PinHandler;
  disablePin?: boolean;
}) => {
  const toDoTasks: Task[] = [],
    inProgressTasks: Task[] = [],
    onHoldTasks: Task[] = [],
    completedTasks: Task[] = [];

  for (const task of tasks) {
    // todo check if there can be more status values
    if (
      task.status.title === TASK_STATUS.TODO ||
      task.status.title === TASK_STATUS.PENDING
    )
      toDoTasks.push(task);
    else if (task.status.title === TASK_STATUS.HOLD) onHoldTasks.push(task);
    else if (task.status.title === TASK_STATUS.INPROGRESS)
      inProgressTasks.push(task);
    else if (task.status.title === TASK_STATUS.COMPLETED)
      completedTasks.push(task);
  }

  if (currentView === "table") {
    return (
      <>
        <TableHeader id="task-table-header">
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            ))
          )}
        </TableHeader>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} id={`${row.id}-task`}>
            <span>{row.getValue("title")}</span>
            <span>{moment(row.getValue("start_date")).format("YYYY")}</span>
            <span>{moment(row.getValue("due_date")).format("YYYY")}</span>
            <span>{row.getValue("responsible")}</span>
            <span>{row.getValue("percentage_complete")}</span>
            <Pill
              $progressStatus={
                row.getValue("status") as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{row.getValue("status")}</span>
            </Pill>
            <ContextMenu
              listItems={getTaskContextMenu(
                row.getValue("id"),
                row.getValue("isPinned"),
                projectId,
                row.getValue("pinId"),
                handlePinning
              )}
              id="tasks-table-menu"
              top={() => getOffset("task-table-header", `${row.id}-task`)}
            />
            {!!row.getValue("isPinned") && <PinFilled />}
          </TableRow>
        ))}
        <Pagination>
          <ItemCount>
            <span style={{ fontSize: "1.2rem" }}>Show:</span>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 5}
              onClick={() => table.setPageSize(5)}
            >
              5
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 10}
              onClick={() => table.setPageSize(10)}
            >
              10
            </NumberSpan>
          </ItemCount>
          <PageNumbers table={table} />
        </Pagination>
      </>
    );
  } else if (currentView === "kanban")
    return (
      <KanbanGrid>
        <KanbanGridItem>
          <h1>To-Do</h1>
          {toDoTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={toDoTasks}
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>In-progress</h1>
          {inProgressTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={inProgressTasks}
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>On-hold</h1>
          {onHoldTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={onHoldTasks}
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>Completed</h1>
          {completedTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={completedTasks}
              completed
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
      </KanbanGrid>
    );
  else return null;
};

const TaskCardRenderer = ({
  tasks,
  projectId,
  completed = false,
  handlePinning,
  disablePin,
}: {
  tasks: Task[];
  projectId: number;
  completed?: boolean;
  handlePinning?: PinHandler;
  disablePin?: boolean;
}) => {
  const navigate = useNavigate();

  const ContextMenuDiv = styled.div`
    display: flex;
    gap: 0.5rem;
  `;

  const handlePinningDirect = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLOrSVGElement>,
    pinId?: number
  ) => {
    event?.stopPropagation();
    handlePinning?.(type, value, savePin, pinId);
  };

  return (
    <>
      {tasks?.map((task) => (
        <TaskCard key={task.id} $isCompleted={completed} id="task-card-header">
          <MilestoneTitle>
            {task.title}
            <ContextMenuDiv>
              {task.isPinned ? (
                <StyledPinButton
                  onClick={(e) =>
                    handlePinningDirect(
                      PINNED_ITEM_TYPES.TASK,
                      `${task.id}`,
                      false,
                      e,
                      task.pinId
                    )
                  }
                  disabled={disablePin}
                >
                  <PinFilled />
                </StyledPinButton>
              ) : (
                <StyledPinButton
                  onClick={(e) =>
                    handlePinningDirect(
                      PINNED_ITEM_TYPES.TASK,
                      `${task.id}`,
                      true,
                      e
                    )
                  }
                  disabled={disablePin}
                >
                  <PushPin />
                </StyledPinButton>
              )}
            </ContextMenuDiv>
          </MilestoneTitle>
          <ProjectPara>
            <span>{task.service.title}</span>
          </ProjectPara>
          <span>{task.description}</span>
          <StatusView>
            <StatusP>
              <BottomDate>
                {task.start_date
                  ? moment(task.start_date).format("DD/MM/YYYY")
                  : "N/A"}{" "}
                -{" "}
                {task.due_date
                  ? moment(task.due_date).format("DD/MM/YYYY")
                  : "N/A"}
              </BottomDate>
            </StatusP>
            <ViewSpan
              onClick={() =>
                navigate(`/projects/details/${projectId}?activeToggle=Tasks`)
              }
            >
              {"View >"}
            </ViewSpan>
          </StatusView>
        </TaskCard>
      ))}
    </>
  );
};
