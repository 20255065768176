import { ServiceType, StatusType } from "../types/statusTypes";
import { client } from "./AxiosClient";

export const getAllStatusValuesAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_STATUS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as StatusType[];
};

export const getAllServiceValuesAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_SERVICE, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as ServiceType[];
};
