import moment from "moment";

export const getFormattedCurrency = (value: number) => {
  if (!value) return "$0";

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export function* chunks<T>(
  arr: T[] | undefined,
  n: number
): Generator<T[], void> {
  if (!arr || arr.length === 0) return;
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const getMinDate = (editing: boolean) =>
  editing ? "" : moment().format("YYYY-MM-DD");

export const getStartMonth = (currentDate: string | undefined) => {
  if (!currentDate) {
    return new Date(+moment().format("YYYY"), moment().month());
  }
  return new Date(
    +moment(currentDate).format("YYYY"),
    moment(currentDate).month()
  );
};

export const getEndMonth = () =>
  new Date(+moment().add(10, "years").format("YYYY"), moment().month());

export const getDisabledValue = (when?: string) => ({
  before: when ? new Date(when) : new Date(),
});
