import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useState,
  } from "react";
  
  type DayModalContextType = {
    currentModalName: string;
    setDayModalName: React.Dispatch<React.SetStateAction<string>>;
  };
  
  const defaultDayModalContext = {
    currentModalName: "",
    setDayModalName: () => {},
  };
  
  export const DayModalContext = createContext<DayModalContextType>(
    defaultDayModalContext
  );
  
  export const DayModalContextProvider: FC<PropsWithChildren> = ({
    children,
  }) => {
    const [currentModalName, setDayModalName] = useState("");
    return (
      <DayModalContext.Provider
        value={{ currentModalName, setDayModalName }}
      >
        {children}
      </DayModalContext.Provider>
    );
  };
  
  export const useDayModalContext = () => {
    const context = useContext(DayModalContext);
    return context;
  };
  