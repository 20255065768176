import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";

import { DayPicker } from "react-day-picker";
import { DATE_MODAL_NAMES, DAY_FORMATS } from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useGetStatusValues } from "../../hooks/useGetStatusValues";
import { MilestoneFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getItemDropdownValues,
} from "../../utils/GetDropdownValue";
import {
  useCreateProjectMilestone,
  useEditProjectMilestone,
} from "./useMilestones";

const MilestoneForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: MilestoneFormValues;
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setFocus,
    getValues,
    resetField,
  } = useForm<MilestoneFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { setDayModalName } = useDayModalContext();
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);

  useEffect(() => {
    setFocus("title");
  }, [setFocus]);

  const { isCreating, createProjectMilestone } = useCreateProjectMilestone();
  const { isEditing, editProjectMilestone } = useEditProjectMilestone();
  const { isContactsLoading, contacts } = useGetContacts();
  const { isStatusLoading, statuses } = useGetStatusValues();
  const { isServiceLoading, services } = useGetServiceValues();

  const onSubmit: SubmitHandler<MilestoneFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectMilestone(
        {
          milestoneDetails: {
            ...data,
            sequence: "sequence",
            project_id: +projectId,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project milestone saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectMilestone(
        {
          milestoneDetails: {
            ...data,
            sequence: "sequence",
            project_id: +projectId,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
          milestoneId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project milestone saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof MilestoneFormValues, id);
  };

  if (
    isCreating ||
    isEditing ||
    isContactsLoading ||
    isStatusLoading ||
    isServiceLoading
  )
    return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Title</label>
            <StyledInput
              type="text"
              {...register("title", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.title}
              autoComplete="off"
            />
            {errors?.title && (
              <ErrorMessage>{errors.title.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>Service</label>
            <Controller
              control={control}
              name="service_id"
              defaultValue={details?.service_id}
              render={({ field: { value } }) => (
                <Dropdown
                  id="service_id"
                  title=""
                  data={getItemDropdownValues(services)}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                />
              )}
            />
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <PositionedInput>
            <label>Estimated Start Date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("est_start_date")
                  ? moment(getValues("est_start_date")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.est_start_date
                  ? moment(details?.est_start_date).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : ""
              }
              readOnly
            />
            {errors?.est_start_date && (
              <ErrorMessage>{errors.est_start_date.message}</ErrorMessage>
            )}
            {getValues("est_start_date") || details?.est_start_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("est_start_date");
                  if (details) {
                    details.est_start_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.EST_START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="est_start_date"
              defaultValue={details?.est_start_date}
              rules={{
                min: {
                  value: getMinDate(isEditingMode),
                  message: "Please enter a valid start year",
                },
                max: {
                  value: getValues("end_year")
                    ? moment(getValues("end_year")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )
                    : "",
                  message: "Please enter a valid start year",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().est_start_date)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "est_start_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().est_start_date
                        ? new Date(getValues().est_start_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <PositionedInput>
            <label>Estimated End Date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("est_end_date")
                  ? moment(getValues("est_end_date")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.est_end_date
                  ? moment(details?.est_end_date).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.est_end_date && (
              <ErrorMessage>{errors.est_end_date.message}</ErrorMessage>
            )}
            {getValues("est_end_date") || details?.est_end_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("est_end_date");
                  if (details) {
                    details.est_end_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.EST_END);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="est_end_date"
              defaultValue={details?.est_end_date}
              rules={{
                min: {
                  value:
                    (getValues("start_year") &&
                      moment(getValues("start_year")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )) ||
                    getMinDate(isEditingMode),
                  message: "Please enter a valid end year",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_END}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().est_end_date)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "est_end_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().est_end_date
                        ? new Date(getValues().est_end_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <PositionedInput>
            <label>Start Year</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("start_year")
                  ? moment(getValues("start_year")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.start_year
                  ? moment(details?.start_year).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.start_year && (
              <ErrorMessage>{errors.start_year.message}</ErrorMessage>
            )}
            {getValues("start_year") || details?.start_year ? (
              <ClearMessage
                onClick={() => {
                  resetField("start_year");
                  if (details) {
                    details.start_year = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="start_year"
              defaultValue={details?.start_year}
              rules={{
                min: {
                  value: getMinDate(isEditingMode),
                  message: "Please enter a valid start year",
                },
                max: {
                  value: getValues("end_year")
                    ? moment(getValues("end_year")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )
                    : "",
                  message: "Please enter a valid start year",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().start_year)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "start_year",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().start_year
                        ? new Date(getValues().start_year)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <PositionedInput>
            <label>End Year</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("end_year")
                  ? moment(getValues("end_year")).format(DAY_FORMATS.DAY_FIRST)
                  : details?.end_year
                  ? moment(details?.end_year).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.end_year && (
              <ErrorMessage>{errors.end_year.message}</ErrorMessage>
            )}
            {getValues("end_year") || details?.end_year ? (
              <ClearMessage
                onClick={() => {
                  resetField("end_year");
                  if (details) {
                    details.end_year = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.END);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="end_year"
              defaultValue={details?.end_year}
              rules={{
                min: {
                  value:
                    (getValues("start_year") &&
                      moment(getValues("start_year")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )) ||
                    getMinDate(isEditingMode),
                  message: "Please enter a valid end year",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().end_year)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "end_year",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().end_year
                        ? new Date(getValues().end_year)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>% of Project</label>
            <StyledInput
              type="number"
              {...register("percentage_of_project", {
                max: { value: 100, message: "Max value allowed is 100" },
                
              })}
              defaultValue={details?.percentage_of_project || 0}
              autoComplete="off"
            />
            {errors?.percentage_of_project && (
              <ErrorMessage>
                {errors.percentage_of_project.message}
              </ErrorMessage>
            )}
          </LabelAndInput>{" "}
          <LabelAndInput>
            <label>Responsible Person</label>
            <Controller
              control={control}
              name="responsible_person_id"
              defaultValue={details?.responsible_person_id}
              render={({ field: { value } }) => (
                <Dropdown
                  id="responsible_person_id"
                  title=""
                  data={getContactDropdownValues(contacts)}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                />
              )}
            />
          </LabelAndInput>
          <LabelAndInput>
            <label>Status</label>
            <Controller
              control={control}
              name="status_id"
              defaultValue={details?.status_id || 1}
              render={({ field: { value } }) => (
                <Dropdown
                  id="status_id"
                  title=""
                  data={getItemDropdownValues(statuses)}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                  search
                />
              )}
            />
          </LabelAndInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Description *</label>
            <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isBackgroundFieldExpanded ? "20rem" : ""}
                {...register("description", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                defaultValue={details?.description}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
              >
                {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
            {errors?.description && (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default MilestoneForm;
