import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import {
  getAllDropdownsAPI,
  getProjectTypeDropdown,
} from "../services/apiDropdowns";

export const useGetDropdownValues = () => {
  const {
    isPending: isDropdownLoading,
    data: dropdownItems,
    error,
  } = useQuery({
    queryFn: () => getAllDropdownsAPI({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.DROPDOWN_ITEMS],
  });

  return { isDropdownLoading, dropdownItems, error };
};

export const useGetProjectTypeDropdownValues = () => {
  const {
    isPending: isProjectTypeLoading,
    data: projectTypeDropdown,
    error,
  } = useQuery({
    queryFn: () => getProjectTypeDropdown({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.PROJECT_TYPE],
  });

  return { isProjectTypeLoading, projectTypeDropdown, error };
};
