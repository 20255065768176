import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectScopeAPI,
  deleteProjectScopeAPI,
  getProjectScopeAPI,
  updateProjectScopeAPI,
  updateScopeRankingAPI,
} from "../../services/apiProjects";
import { UpsertProjectScope } from "../../types/projectTypes";

export const useGetProjectScope = (id: string) => {
  const {
    isPending: isScopeLoading,
    data: scopeDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectScopeAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_SCOPE, id],
    select: (data) => data.sort((a, b) => a.sequence - b.sequence),
    retry: false,
  });

  return { isScopeLoading, scopeDetails, error };
};

export const useCreateProjectScope = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectScope,
    error,
  } = useMutation({
    mutationFn: ({ scopeDetails }: { scopeDetails: UpsertProjectScope }) =>
      createProjectScopeAPI(scopeDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SCOPE],
      });
    },
  });

  return { isCreating, createProjectScope, error };
};

export const useEditProjectScope = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectScope,
    error,
  } = useMutation({
    mutationFn: ({
      scopeDetails,
      detailId,
    }: {
      scopeDetails: UpsertProjectScope;
      detailId: number;
    }) => updateProjectScopeAPI(scopeDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SCOPE],
      });
    },
  });

  return { isEditing, editProjectScope, error };
};

export const useDeleteProjectScope = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectScope,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectScopeAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SCOPE],
      });
    },
  });

  return { isDeleting, deleteProjectScope, error };
};

export const useUpdateScopeRanking = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: editScopeRanks,
    error,
  } = useMutation({
    mutationFn: (rankDetails: { id: number; sequence: number }[]) =>
      updateScopeRankingAPI(rankDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SCOPE],
      });
    },
  });

  return { isLoading, editScopeRanks, error };
};
