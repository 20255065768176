import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectFinanceAPI,
  deleteProjectFinanceAPI,
  getProjectFinancesAPI,
  updateProjectFinanceAPI,
} from "../../services/apiProjects";
import { UpsertProjectFinance } from "../../types/projectTypes";

export const useGetProjectFinances = (id: string) => {
  const {
    isPending: isFinanceLoading,
    data: financeDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectFinancesAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_FINANCE, id],
    retry: false,
  });

  return { isFinanceLoading, financeDetails, error };
};

export const useCreateProjectFinance = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectFinance,
    error,
  } = useMutation({
    mutationFn: ({
      financeDetails,
    }: {
      financeDetails: UpsertProjectFinance;
    }) => createProjectFinanceAPI(financeDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_FINANCE],
      });
    },
  });

  return { isCreating, createProjectFinance, error };
};

export const useEditProjectFinance = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectFinance,
    error,
  } = useMutation({
    mutationFn: ({
      financeDetails,
      detailId,
    }: {
      financeDetails: UpsertProjectFinance;
      detailId: number;
    }) => updateProjectFinanceAPI(financeDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_FINANCE],
      });
    },
  });

  return { isEditing, editProjectFinance, error };
};

export const useDeleteProjectFinance = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectFinance,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectFinanceAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_FINANCE],
      });
    },
  });

  return { isDeleting, deleteProjectFinance, error };
};
