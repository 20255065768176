import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Location from "../../assets/LocationBlack.svg?react";

import { DayPicker } from "react-day-picker";
import { DATE_MODAL_NAMES, DAY_FORMATS } from "../../common/Constants";

import { useNavigate } from "react-router-dom";
import { DROPDOWN_CATEGORIES } from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetContacts } from "../../hooks/useGetContacts";
import {
  useGetDropdownValues,
  useGetProjectTypeDropdownValues,
} from "../../hooks/useGetDropdownValues";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useGetStatusValues } from "../../hooks/useGetStatusValues";
import { ProjectFormValues } from "../../types/formFields";
import { Project } from "../../types/projectTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  SecondaryPositionSvg,
  SectionPart,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getDropdownValues,
  getItemDropdownValues,
} from "../../utils/GetDropdownValue";
import { useCreateProject, useEditProject } from "./useProjects";

const CreateEditProject = ({
  projectDetails,
  projectId,
  handleClose,
  isEditingMode,
}: {
  projectDetails?: Project;
  projectId?: string;
  handleClose: () => void;
  isEditingMode: boolean;
}) => {
  const navigate = useNavigate();
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const [isCommentFieldExpanded, setIsCommentFieldExpanded] = useState(false);

  const { setDayModalName } = useDayModalContext();

  // api data
  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isProjectTypeLoading, projectTypeDropdown } =
    useGetProjectTypeDropdownValues();
  const { isContactsLoading, contacts } = useGetContacts();
  const { isStatusLoading, statuses } = useGetStatusValues();
  const { isServiceLoading, services } = useGetServiceValues();
  // form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setFocus,
    getValues,
    resetField,
  } = useForm<ProjectFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    setFocus("title");
  }, [setFocus]);

  const { isCreating, createProject } = useCreateProject();
  const { isEditing, editProject } = useEditProject();

  const onSubmit: SubmitHandler<ProjectFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProject(
        {
          projectDetails: {
            ...data,
            is_CEO_KFA: true,
            is_director_KFA: true,
            is_incorporate_businessplan: true,
            parent_id: null,
            path: "",
            businesscase_id: null,
            theme_id: null,
            directorate_id: null,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
          },
        },
        {
          onSuccess: (data) => {
            toast.success(`Project saved successfully.`);
            navigate(`/projects/details/${data.id}`);
            handleClose();
          },
        }
      );
    } else {
      editProject(
        {
          projectDetails: {
            ...data,
            is_CEO_KFA: true,
            is_director_KFA: true,
            is_incorporate_businessplan: true,
            parent_id: null,
            path: "",
            businesscase_id: null,
            theme_id: null,
            directorate_id: null,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
          },
          projectId: projectId ? +projectId : 0,
        },
        {
          onSuccess: (data) => {
            toast.success(`Project saved successfully.`);
            navigate(`/projects/details/${data.id}`);
            handleClose();
          },
        }
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof ProjectFormValues, id);
  };

  if (
    isDropdownLoading ||
    isContactsLoading ||
    isStatusLoading ||
    isServiceLoading ||
    isProjectTypeLoading ||
    isCreating ||
    isEditing
  )
    return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <SectionPart>
            <h1>Details</h1>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Title *</label>
                <StyledInput
                  type="text"
                  {...register("title", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                  })}
                  defaultValue={projectDetails?.title}
                  autoComplete="off"
                />
                {errors?.title && (
                  <ErrorMessage> {errors?.title?.message} </ErrorMessage>
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Type *</label>
                <Controller
                  control={control}
                  name="project_type_id"
                  rules={{ required: "This field is mandatory" }}
                  defaultValue={projectDetails?.project_type_id}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="project_type_id"
                      title=""
                      data={getItemDropdownValues(projectTypeDropdown)}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                    />
                  )}
                />

                {errors?.project_type_id && (
                  <ErrorMessage>{errors.project_type_id.message}</ErrorMessage>
                )}
              </LabelAndInput>{" "}
              <LabelAndInput>
                <label>Location</label>
                <div style={{ position: "relative" }}>
                  <StyledInput
                    type="text"
                    style={{ width: "100%" }}
                    defaultValue={projectDetails?.location}
                    {...register("location")}
                    autoComplete="off"
                  />
                  <SecondaryPositionSvg>
                    <Location />
                  </SecondaryPositionSvg>
                </div>
              </LabelAndInput>
            </StyledThreeFieldSection>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Definition *</label>
              </LabelAndInput>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("project_definition", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                  })}
                  defaultValue={projectDetails?.project_definition}
                  autoComplete="off"
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.project_definition && (
                <ErrorMessage>{errors.project_definition.message}</ErrorMessage>
              )}
            </StyledThreeFieldSection>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>
                  Select Program{" "}
                  <span>(Is this project part of a program?)</span>{" "}
                </label>
                <Controller
                  control={control}
                  name="program_id"
                  defaultValue={projectDetails?.businesscase_id}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="program_id"
                      title=""
                      data={getDropdownValues(
                        DROPDOWN_CATEGORIES.RESOURCE_TYPE,
                        dropdownItems
                      )}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                    />
                  )}
                />
              </LabelAndInput>
              <LabelAndInput>
                <label>Service *</label>
                <Controller
                  control={control}
                  name="service_id"
                  defaultValue={projectDetails?.service_id}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="service_id"
                      title=""
                      data={getItemDropdownValues(services)}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      search
                    />
                  )}
                />
              </LabelAndInput>{" "}
            </StyledThreeFieldSection>
          </SectionPart>
          <SectionPart>
            <h1>Ownership</h1>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Project Owner *</label>
                <Controller
                  control={control}
                  name="owner_id"
                  defaultValue={projectDetails?.owner_id}
                  rules={{ required: "This field is mandatory" }}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="owner_id"
                      title=""
                      data={getContactDropdownValues(contacts)}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      search
                    />
                  )}
                />

                {errors?.owner_id && (
                  <ErrorMessage>{errors.owner_id.message}</ErrorMessage>
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Project Manager</label>
                <Controller
                  control={control}
                  name="responsible_person_id"
                  defaultValue={projectDetails?.responsible_person_id}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="responsible_person_id"
                      title=""
                      data={getContactDropdownValues(contacts)}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      search
                    />
                  )}
                />
              </LabelAndInput>
            </StyledThreeFieldSection>
          </SectionPart>
          <SectionPart>
            <h1>Duration</h1>
            <StyledThreeFieldSection>
              <PositionedInput>
                <label>Estimated Start Date</label>
                <StyledInput
                  aria-label="Date"
                  type="text"
                  value={
                    getValues("est_start_year")
                      ? moment(getValues("est_start_year")).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : projectDetails?.est_start_year
                      ? moment(projectDetails?.est_start_year).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : ""
                  }
                  readOnly
                />
                {errors?.est_start_year && (
                  <ErrorMessage>{errors.est_start_year.message}</ErrorMessage>
                )}
                {getValues("est_start_year") ||
                projectDetails?.est_start_year ? (
                  <ClearMessage
                    onClick={() => {
                      resetField("est_start_year");
                      if (projectDetails) {
                        projectDetails.est_start_year = "";
                      }
                    }}
                  >
                    Clear
                  </ClearMessage>
                ) : (
                  ""
                )}
                <PositionedCalendar>
                  <Calendar
                    onClick={() => {
                      setDayModalName(DATE_MODAL_NAMES.EST_START);
                    }}
                  />
                </PositionedCalendar>
                <Controller
                  control={control}
                  name="est_start_year"
                  defaultValue={projectDetails?.est_start_year}
                  rules={{
                    min: {
                      value: getMinDate(isEditingMode),
                      message: "Please enter a valid start date",
                    },
                    max: {
                      value: getValues("end_year")
                        ? moment(getValues("end_year")).format(
                            DAY_FORMATS.YEAR_FIRST
                          )
                        : "",
                      message: "Please enter a valid start date",
                    },
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_START}>
                      <DayPicker
                        mode="single"
                        selected={new Date(getValues().est_start_year)}
                        onSelect={(e) => {
                          onChange(() =>
                            setValue(
                              "est_start_year",
                              moment(e).format(DAY_FORMATS.YEAR_FIRST)
                            )
                          );
                          setDayModalName("");
                        }}
                        onDayClick={onBlur}
                        captionLayout="dropdown"
                        endMonth={getEndMonth()}
                        defaultMonth={
                          getValues().est_start_year
                            ? new Date(getValues().est_start_year)
                            : new Date()
                        }
                      />
                    </DayPickerModal>
                  )}
                />
              </PositionedInput>
              <PositionedInput>
                <label>Estimated End Date</label>
                <StyledInput
                  aria-label="Date"
                  type="text"
                  value={
                    getValues("est_end_year")
                      ? moment(getValues("est_end_year")).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : projectDetails?.est_end_year
                      ? moment(projectDetails?.est_end_year).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : ""
                  }
                  readOnly
                />
                {errors?.est_end_year && (
                  <ErrorMessage>{errors.est_end_year.message}</ErrorMessage>
                )}
                {getValues("est_end_year") || projectDetails?.est_end_year ? (
                  <ClearMessage
                    onClick={() => {
                      resetField("est_end_year");
                      if (projectDetails) {
                        projectDetails.est_end_year = "";
                      }
                    }}
                  >
                    Clear
                  </ClearMessage>
                ) : (
                  ""
                )}
                <PositionedCalendar>
                  <Calendar
                    onClick={() => {
                      setDayModalName(DATE_MODAL_NAMES.EST_END);
                    }}
                  />
                </PositionedCalendar>
                <Controller
                  control={control}
                  name="est_end_year"
                  defaultValue={projectDetails?.est_end_year}
                  rules={{
                    min: {
                      value:
                        (getValues("start_year") &&
                          moment(getValues("start_year")).format(
                            DAY_FORMATS.YEAR_FIRST
                          )) ||
                        getMinDate(isEditingMode),
                      message: "Please enter a valid end date",
                    },
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_END}>
                      <DayPicker
                        mode="single"
                        selected={new Date(getValues().est_end_year)}
                        onSelect={(e) => {
                          onChange(() =>
                            setValue(
                              "est_end_year",
                              moment(e).format(DAY_FORMATS.YEAR_FIRST)
                            )
                          );
                          setDayModalName("");
                        }}
                        onDayClick={onBlur}
                        captionLayout="dropdown"
                        endMonth={getEndMonth()}
                        defaultMonth={
                          getValues().est_end_year
                            ? new Date(getValues().est_end_year)
                            : new Date()
                        }
                      />
                    </DayPickerModal>
                  )}
                />
              </PositionedInput>
            </StyledThreeFieldSection>
            <StyledThreeFieldSection>
              <PositionedInput>
                <label>Start Date</label>
                <StyledInput
                  aria-label="Date"
                  type="text"
                  value={
                    getValues("start_year")
                      ? moment(getValues("start_year")).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : projectDetails?.start_year
                      ? moment(projectDetails?.start_year).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : ""
                  }
                  readOnly
                />
                {errors?.start_year && (
                  <ErrorMessage>{errors.start_year.message}</ErrorMessage>
                )}
                {getValues("start_year") || projectDetails?.start_year ? (
                  <ClearMessage
                    onClick={() => {
                      resetField("start_year");
                      if (projectDetails) {
                        projectDetails.start_year = "";
                      }
                    }}
                  >
                    Clear
                  </ClearMessage>
                ) : (
                  ""
                )}
                <PositionedCalendar>
                  <Calendar
                    onClick={() => {
                      setDayModalName(DATE_MODAL_NAMES.START);
                    }}
                  />
                </PositionedCalendar>
                <Controller
                  control={control}
                  name="start_year"
                  defaultValue={projectDetails?.start_year}
                  rules={{
                    min: {
                      value: getMinDate(isEditingMode),
                      message: "Please enter a valid start date",
                    },
                    max: {
                      value: getValues("end_year")
                        ? moment(getValues("end_year")).format(
                            DAY_FORMATS.YEAR_FIRST
                          )
                        : "",
                      message: "Please enter a valid start date",
                    },
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                      <DayPicker
                        mode="single"
                        selected={new Date(getValues().start_year)}
                        onSelect={(e) => {
                          onChange(() =>
                            setValue(
                              "start_year",
                              moment(e).format(DAY_FORMATS.YEAR_FIRST)
                            )
                          );
                          setDayModalName("");
                        }}
                        onDayClick={onBlur}
                        captionLayout="dropdown"
                        endMonth={getEndMonth()}
                        defaultMonth={
                          getValues().start_year
                            ? new Date(getValues().start_year)
                            : new Date()
                        }
                      />
                    </DayPickerModal>
                  )}
                />
              </PositionedInput>
              <PositionedInput>
                <label>End Date</label>
                <StyledInput
                  aria-label="Date"
                  type="text"
                  value={
                    getValues("end_year")
                      ? moment(getValues("end_year")).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : projectDetails?.end_year
                      ? moment(projectDetails?.end_year).format(
                          DAY_FORMATS.DAY_FIRST
                        )
                      : ""
                  }
                  readOnly
                />
                {errors?.end_year && (
                  <ErrorMessage>{errors.end_year.message}</ErrorMessage>
                )}
                {getValues("end_year") || projectDetails?.end_year ? (
                  <ClearMessage
                    onClick={() => {
                      resetField("end_year");
                      if (projectDetails) {
                        projectDetails.end_year = "";
                      }
                    }}
                  >
                    Clear
                  </ClearMessage>
                ) : (
                  ""
                )}
                <PositionedCalendar>
                  <Calendar
                    onClick={() => {
                      setDayModalName(DATE_MODAL_NAMES.END);
                    }}
                  />
                </PositionedCalendar>
                <Controller
                  control={control}
                  name="end_year"
                  defaultValue={projectDetails?.end_year}
                  rules={{
                    min: {
                      value:
                        (getValues("start_year") &&
                          moment(getValues("start_year")).format(
                            DAY_FORMATS.YEAR_FIRST
                          )) ||
                        getMinDate(isEditingMode),
                      message: "Please enter a valid end date",
                    },
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                      <DayPicker
                        mode="single"
                        selected={new Date(getValues().end_year)}
                        onSelect={(e) => {
                          onChange(() =>
                            setValue(
                              "end_year",
                              moment(e).format(DAY_FORMATS.YEAR_FIRST)
                            )
                          );
                          setDayModalName("");
                        }}
                        onDayClick={onBlur}
                        captionLayout="dropdown"
                        endMonth={getEndMonth()}
                        defaultMonth={
                          getValues().end_year
                            ? new Date(getValues().end_year)
                            : new Date()
                        }
                      />
                    </DayPickerModal>
                  )}
                />
              </PositionedInput>
            </StyledThreeFieldSection>
          </SectionPart>
          <SectionPart>
            <h1>Status</h1>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Initiative Decision</label>
                <Controller
                  control={control}
                  name="status_id"
                  defaultValue={projectDetails?.status_id || 1}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="status_id"
                      title=""
                      data={getItemDropdownValues(statuses)}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      search
                    />
                  )}
                />
              </LabelAndInput>
              {isEditingMode && (
                <>
                  <LabelAndInput>
                    <label>Percentage Complete</label>
                    <StyledInput
                      type="number"
                      {...register("percentage_complete", {
                        max: {
                          value: 100,
                          message: "Max value allowed is 100",
                        },
                        min: { value: 0, message: "Min value allowed in 0" },
                      })}
                      defaultValue={projectDetails?.percentage_complete || 0}
                    />
                    {errors?.percentage_complete && (
                      <ErrorMessage>
                        {errors.percentage_complete.message}
                      </ErrorMessage>
                    )}
                  </LabelAndInput>
                  <LabelAndInput>
                    <label>Progress Status</label>
                    <Controller
                      control={control}
                      name="progress_status"
                      defaultValue={projectDetails?.status.title}
                      render={({ field: { value } }) => (
                        <Dropdown
                          id="progress_status"
                          title=""
                          data={getItemDropdownValues(statuses)}
                          onSelect={handleSelect}
                          selectedId={`${value}`}
                          bgColor="var(--color-grey-100)"
                        />
                      )}
                    />
                  </LabelAndInput>{" "}
                </>
              )}
            </StyledThreeFieldSection>
          </SectionPart>
          <StyledThreeFieldSection>
            <h1>Comments</h1>
            <ExpandableDiv height={isCommentFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isCommentFieldExpanded ? "20rem" : ""}
                {...register("comments")}
                defaultValue={projectDetails?.comments}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsCommentFieldExpanded((prev) => !prev)}
              >
                {isCommentFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
          </StyledThreeFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
            Cancel
            <Discard />
          </ButtonCTA>
          <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
            Save
            <CheckMark />
          </ButtonCTA>
        </BottomNav>
      </form>
    </>
  );
};

export default CreateEditProject;
