import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectSuccessAPI,
  deleteProjectSuccessAPI,
  getProjectSuccessAPI,
  updateProjectSuccessAPI,
  updateSuccessRankingAPI,
} from "../../services/apiProjects";
import { UpsertProjectSuccessMeasure } from "../../types/projectTypes";

export const useGetProjectSuccess = (id: string) => {
  const {
    isPending: isSuccessLoading,
    data: successDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectSuccessAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_SUCCESS, id],
    select: (data) => data.sort((a, b) => a.sequence - b.sequence),
    retry: false,
  });

  return { isSuccessLoading, successDetails, error };
};

export const useCreateProjectSuccess = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectSuccessMeasure,
    error,
  } = useMutation({
    mutationFn: ({
      successDetails,
    }: {
      successDetails: UpsertProjectSuccessMeasure;
    }) => createProjectSuccessAPI(successDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SUCCESS],
      });
    },
  });

  return { isCreating, createProjectSuccessMeasure, error };
};

export const useEditProjectSuccess = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectSuccessMeasure,
    error,
  } = useMutation({
    mutationFn: ({
      successDetails,
      detailId,
    }: {
      successDetails: UpsertProjectSuccessMeasure;
      detailId: number;
    }) => updateProjectSuccessAPI(successDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SUCCESS],
      });
    },
  });

  return { isEditing, editProjectSuccessMeasure, error };
};

export const useDeleteProjectSuccess = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectSuccessMeasure,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectSuccessAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SUCCESS],
      });
    },
  });

  return { isDeleting, deleteProjectSuccessMeasure, error };
};

export const useUpdateSuccessRanking = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: editSuccessRanks,
    error,
  } = useMutation({
    mutationFn: (rankDetails: { id: number; sequence: number }[]) =>
      updateSuccessRankingAPI(rankDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_SUCCESS],
      });
    },
  });

  return { isLoading, editSuccessRanks, error };
};
