import styled from "styled-components";
import { BUTTON_SIZES, BUTTON_TYPES } from "../common/Constants";

/**
 * @description Styled reusable button CTA component, primarily used in the top nav
 * @param $buttonSize {string} small/large/default - optional
 * @param $buttonType {string} primary/secondary - optional
 * @param disabled {boolean}
 */
const ButtonCTA = styled.button<ButtonProps>`
  width: ${(props) => {
    if (props.$buttonSize === BUTTON_SIZES.SMALL) return "12.3rem";
    else if (props.$buttonSize === BUTTON_SIZES.MEDIUM_LARGE) return "20rem";
    else if (props.$buttonSize === BUTTON_SIZES.LARGE) return "30rem";
    else return "16.2rem";
  }};
  height: 4.8rem;
  background-color: ${(props) =>
    props.$buttonType === BUTTON_TYPES.SECONDARY
      ? "var(--color-button-secondary)"
      : "transparent"};
  color: ${(props) => (props.$buttonType === BUTTON_TYPES.SECONDARY ? "white" : "")};
  border: ${(props) =>
    props.$buttonType === BUTTON_TYPES.SECONDARY ? "unset" : "2px solid #22252A"};
  border-radius: var(--border-radius-2l);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  opacity: ${(props) => (props.disabled ? "0.7" : '1')};
`;

type ButtonProps = {
  $buttonSize?: string;
  $buttonType?: string;
  disabled?: boolean;
};

export default ButtonCTA;
