import moment from "moment";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Calendar from "../../assets/Calendar.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Expand from "../../assets/Expand.svg?react";

import { DayPicker } from "react-day-picker";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import styled from "styled-components";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import {
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  DROPDOWN_CATEGORIES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectFinance,
  useEditProjectFinance,
} from "../../hooks/projects/useProjectFinance";
import { useGetDropdownValues } from "../../hooks/useGetDropdownValues";
import { FinanceFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getDropdownValues } from "../../utils/GetDropdownValue";
import { getEndMonth, getMinDate } from "../../utils/common";

const StyledCurrencyInput = styled(CurrencyInput)`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  height: 4.8rem;
  font-weight: 500;
`;

const FinanceForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: FinanceFormValues;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const [isCommentFieldExpanded, setIsCommentFieldExpanded] = useState(false);

  const { setDayModalName } = useDayModalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setFocus,
    getValues,
    resetField,
  } = useForm<FinanceFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    setFocus("financial_type");
  }, [setFocus]);

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isCreating, createProjectFinance } = useCreateProjectFinance();
  const { isEditing, editProjectFinance } = useEditProjectFinance();

  const expenseTypeDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.EXPENSE_TYPE,
    dropdownItems
  );

  const expenseId =
    expenseTypeDropdown.find((value) => value.name === details?.financial_type)
      ?.id || "";

  const fundingSourceDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.FUNDING_SOURCE,
    dropdownItems
  );

  const fundingSourceId =
    fundingSourceDropdown.find(
      (value) => value.name === details?.funding_source
    )?.id || "";

  const onSubmit: SubmitHandler<FinanceFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectFinance(
        {
          financeDetails: {
            ...data,
            financial_type:
              expenseTypeDropdown.find(
                (value) => value.id === data.financial_type
              )?.name || "",
            funding_source:
              fundingSourceDropdown.find(
                (value) => value.id === data.funding_source
              )?.name || "",
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project finances saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectFinance(
        {
          financeDetails: {
            ...data,
            financial_type:
              expenseTypeDropdown.find(
                (value) => value.id === data.financial_type
              )?.name || "",
            funding_source:
              fundingSourceDropdown.find(
                (value) => value.id === data.funding_source
              )?.name || "",
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project finances saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof FinanceFormValues, id);
  };

  if (isDropdownLoading || isCreating || isEditing) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Type *</label>
            <Controller
              control={control}
              name="financial_type"
              defaultValue={expenseId}
              rules={{ required: "This field is required" }}
              render={({ field: { value } }) => (
                <Dropdown
                  id="financial_type"
                  title=""
                  data={expenseTypeDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                />
              )}
            />
            {errors?.financial_type && (
              <ErrorMessage>{errors.financial_type.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>Source of Funding *</label>
            <Controller
              control={control}
              name="funding_source"
              rules={{ required: "This field is required" }}
              defaultValue={fundingSourceId}
              render={({ field: { value } }) => (
                <Dropdown
                  id="funding_source"
                  title=""
                  data={fundingSourceDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                />
              )}
            />
            {errors?.funding_source && (
              <ErrorMessage>{errors.funding_source.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>Ongoing</label>
            <StyledInput
              type="text"
              {...register("ongoing")}
              defaultValue={details?.ongoing}
              autoComplete="off"
            />
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Estimated Amount *</label>
            <Controller
              control={control}
              name="est_amount"
              rules={{ required: "This field is required" }}
              defaultValue={details?.est_amount || 0}
              render={({ field: { onChange } }) => (
                <StyledCurrencyInput
                  id="amount"
                  placeholder="$"
                  allowDecimals={false}
                  onValueChange={onChange}
                  prefix={"$"}
                  step={10}
                  defaultValue={details?.est_amount || 0}
                  autoComplete="off"
                />
              )}
            />
            {errors?.est_amount && (
              <ErrorMessage>{errors.est_amount.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
          <LabelAndInput>
            <label>Cost Center *</label>
            <StyledInput
              type="text"
              {...register("cost_type", { required: "This field is required" })}
              defaultValue={details?.cost_type}
              autoComplete="off"
            />
            {errors?.cost_type && (
              <ErrorMessage>{errors.cost_type.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>% per year</label>
            <StyledInput
              type="number"
              {...register("percentage_per_year", {
                max: { value: 100, message: "Max value allowed is 100" },
              })}
              defaultValue={details?.percentage_per_year || 0}
              autoComplete="off"
            />
            {errors?.percentage_per_year && (
              <ErrorMessage>{errors.percentage_per_year.message}</ErrorMessage>
            )}
          </LabelAndInput>
        </StyledThreeFieldSection>
        {isEditingMode && (
          <StyledThreeFieldSection>
            <LabelAndInput>
              <label>Budgeted Amount</label>
              <Controller
                control={control}
                name="budgeted_amount"
                defaultValue={details?.budgeted_amount || 0}
                render={({ field: { onChange } }) => (
                  <StyledCurrencyInput
                    id="amount"
                    placeholder="$"
                    allowDecimals={false}
                    onValueChange={onChange}
                    prefix={"$"}
                    step={10}
                    defaultValue={details?.budgeted_amount || 0}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.budgeted_amount && (
                <ErrorMessage>{errors.budgeted_amount.message}</ErrorMessage>
              )}
            </LabelAndInput>{" "}
            <LabelAndInput>
              <label>Committed Amount</label>
              <Controller
                control={control}
                name="commited_amount"
                defaultValue={details?.commited_amount || 0}
                render={({ field: { onChange } }) => (
                  <StyledCurrencyInput
                    id="amount"
                    placeholder="$"
                    allowDecimals={false}
                    onValueChange={onChange}
                    prefix={"$"}
                    step={10}
                    defaultValue={details?.commited_amount || 0}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.commited_amount && (
                <ErrorMessage>{errors.commited_amount.message}</ErrorMessage>
              )}
            </LabelAndInput>{" "}
            <LabelAndInput>
              <label>Actual Amount</label>
              <Controller
                control={control}
                name="actual_amount"
                defaultValue={details?.actual_amount || 0}
                render={({ field: { onChange } }) => (
                  <StyledCurrencyInput
                    id="amount"
                    placeholder="$"
                    allowDecimals={false}
                    onValueChange={onChange}
                    prefix={"$"}
                    step={10}
                    defaultValue={details?.actual_amount || 0}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.actual_amount && (
                <ErrorMessage>{errors.actual_amount.message}</ErrorMessage>
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
        )}
        <StyledThreeFieldSection>
          <PositionedInput>
            <label>Start Year</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("start_year")
                  ? moment(getValues("start_year")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.start_year
                  ? moment(details?.start_year).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.start_year && (
              <ErrorMessage>{errors.start_year.message}</ErrorMessage>
            )}
            {getValues("start_year") || details?.start_year ? (
              <ClearMessage
                onClick={() => {
                  resetField("start_year");
                  if (details) {
                    details.start_year = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="start_year"
              defaultValue={details?.start_year}
              rules={{
                min: {
                  value: getMinDate(isEditingMode),
                  message: "Please enter a valid start year",
                },
                max: {
                  value: getValues("end_year")
                    ? moment(getValues("end_year")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )
                    : "",
                  message: "Please enter a valid start year",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().start_year)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "start_year",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().start_year
                        ? new Date(getValues().start_year)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <PositionedInput>
            <label>End Year</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("end_year")
                  ? moment(getValues("end_year")).format(DAY_FORMATS.DAY_FIRST)
                  : details?.end_year
                  ? moment(details?.end_year).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.end_year && (
              <ErrorMessage>{errors.end_year.message}</ErrorMessage>
            )}
            {getValues("end_year") || details?.end_year ? (
              <ClearMessage
                onClick={() => {
                  resetField("end_year");
                  if (details) {
                    details.end_year = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.END);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="end_year"
              defaultValue={details?.end_year}
              rules={{
                min: {
                  value:
                    (getValues("start_year") &&
                      moment(getValues("start_year")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )) ||
                    getMinDate(isEditingMode),
                  message: "Please enter a valid end year",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().end_year)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "end_year",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    showOutsideDays
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().end_year
                        ? new Date(getValues().end_year)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Details *</label>
            <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isBackgroundFieldExpanded ? "20rem" : ""}
                {...register("details", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                defaultValue={details?.details}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
              >
                {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
            {errors?.details && (
              <ErrorMessage>{errors.details.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Comments</label>
            <ExpandableDiv height={isCommentFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isCommentFieldExpanded ? "20rem" : ""}
                {...register("comments")}
                defaultValue={details?.comments}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsCommentFieldExpanded((prev) => !prev)}
              >
                {isCommentFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
          </LabelAndInput>
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default FinanceForm;
