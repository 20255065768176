import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Expand from "../../assets/Expand.svg?react";
import { DROPDOWN_CATEGORIES } from "../../common/Constants";
import {
  useCreateProjectStakeholder,
  useEditProjectStakeholder,
} from "../../hooks/projects/useProjectStakeholders";
import { useGetDropdownValues } from "../../hooks/useGetDropdownValues";
import { StakeholderFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getDropdownValues } from "../../utils/GetDropdownValue";
import { useEffect, useState } from "react";

const StakeholderForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: StakeholderFormValues;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setFocus,
  } = useForm<StakeholderFormValues>();

  useEffect(() => {
    setFocus("type");
  }, [setFocus]);

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isCreating, createProjectStakeholder } =
    useCreateProjectStakeholder();
  const { isEditing, editProjectStakeholder } = useEditProjectStakeholder();

  const roleDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.STAKEHOLDERS,
    dropdownItems
  );

  const roleId =
    roleDropdown.find((value) => value.name === details?.team_role)?.id || "";

  const onSubmit: SubmitHandler<StakeholderFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectStakeholder(
        {
          stakeholderDetails: {
            project_id: +projectId,
            project_team_role:
              roleDropdown.find((value) => value.id === data.team_role)?.name ||
              "",
            section_id: null,
            stakeholder_type: data.type,
            email: data.email,
            name: data.name,
            details: data.details,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project stakeholder details saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectStakeholder(
        {
          stakeholderDetails: {
            project_id: +projectId,
            project_team_role:
              roleDropdown.find((value) => value.id === data.team_role)?.name ||
              "",
            section_id: null,
            stakeholder_type: data.type,
            email: data.email,
            name: data.name,
            details: data.details,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project stakeholder details saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof StakeholderFormValues, id);
  };

  if (isDropdownLoading || isCreating || isEditing) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Type *</label>
            <StyledInput
              type="text"
              {...register("type", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.type}
              autoComplete="off"
            />
            {errors?.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
          </LabelAndInput>
          <LabelAndInput>
            <label>Project Team Role *</label>
            <Controller
              control={control}
              name="team_role"
              defaultValue={roleId}
              rules={{ required: "This field is required" }}
              render={({ field: { value } }) => (
                <Dropdown
                  id="team_role"
                  title=""
                  data={roleDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                />
              )}
            />
            {errors?.team_role && (
              <ErrorMessage>{errors.team_role.message}</ErrorMessage>
            )}
          </LabelAndInput>{" "}
          <LabelAndInput>
            <label>Name *</label>
            <StyledInput
              type="text"
              {...register("name", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.name}
              autoComplete="off"
            />
            {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
          </LabelAndInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Email *</label>
            <StyledInput
              type="text"
              {...register("email", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter at least 3 characters",
                },
              })}
              defaultValue={details?.email}
              autoComplete="off"
            />
            {errors?.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <div style={{ gridColumnStart: 2, gridColumnEnd: -1 }}>
            <LabelAndInput>
              <label>Details *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("details", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                  })}
                  defaultValue={details?.details}
                  autoComplete="off"
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.details && (
                <ErrorMessage>{errors.details.message}</ErrorMessage>
              )}
            </LabelAndInput>
          </div>
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default StakeholderForm;
