import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDayModalContext } from "../context/DayPickerModalContext";
import useOutsideClick from "../hooks/useOutsideClick";

const StyledModal = styled.div`
  position: absolute;
  border-radius: var(--border-radius-xl);
  border: 2px solid var(--color-grey-100);
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 99;
  padding: 2rem;
  top: 8rem;
  right: 0%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (this: HTMLElement, ev: KeyboardEvent) => any;
const useOnEscapeClick = (callback: () => void) => {
  useEffect(() => {
    const closeOnEscapeKey: Listener = (e) =>
      e.key === "Escape" ? callback() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [callback]);
};

function DayPickerModal({
  children,
  wrapperId,
}: {
  children: React.ReactNode;
  wrapperId: string;
}) {
  const nodeRef = useRef(null);
  useOnEscapeClick(() => setDayModalName(""));
  useOutsideClick(nodeRef, () => setDayModalName(""), true);

  const { currentModalName, setDayModalName } = useDayModalContext();

  if (currentModalName !== wrapperId) return null;

  return (
    <>
      <StyledModal ref={nodeRef}>
        <div>{children}</div>
      </StyledModal>
    </>
  );
}
export default DayPickerModal;
