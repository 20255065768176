import moment from "moment";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import ButtonCTA from "../../ui/ButtonCTA";

import { useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { DayPicker } from "react-day-picker";
import toast from "react-hot-toast";
import styled from "styled-components";
import {
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  DROPDOWN_CATEGORIES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectWorkforce,
  useEditProjectWorkforce,
} from "../../hooks/projects/useProjectWorkforce";
import { useGetDropdownValues } from "../../hooks/useGetDropdownValues";
import { WorkforceFormValues } from "../../types/formFields";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import { getDropdownValues } from "../../utils/GetDropdownValue";

const StyledEffortInput = styled(CurrencyInput)`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  height: 4.8rem;
  font-weight: 500;
`;

const WorkforceForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: WorkforceFormValues;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setFocus,
    getValues,
    resetField,
  } = useForm<WorkforceFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { setDayModalName } = useDayModalContext();

  useEffect(() => {
    setFocus("resource_type");
  }, [setFocus]);

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isCreating, createProjectWorkforce } = useCreateProjectWorkforce();
  const { isEditing, editProjectWorkforce } = useEditProjectWorkforce();

  const resourceDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.RESOURCE_TYPE,
    dropdownItems
  );

  const resourceId =
    resourceDropdown.find((value) => value.name === details?.resource_type)
      ?.id || "";

  const onSubmit: SubmitHandler<WorkforceFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectWorkforce(
        {
          workforceDetails: {
            ...data,
            resource_type:
              resourceDropdown.find((value) => value.id === data.resource_type)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project workforce saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectWorkforce(
        {
          workforceDetails: {
            ...data,
            resource_type:
              resourceDropdown.find((value) => value.id === data.resource_type)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project workforce saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof WorkforceFormValues, id);
  };

  if (isEditing || isCreating || isDropdownLoading) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <StyledThreeFieldSection>
          <LabelAndInput>
            <label>Employment Type *</label>
            <Controller
              control={control}
              name="resource_type"
              defaultValue={resourceId}
              rules={{ required: "This field is required" }}
              render={({ field: { value } }) => (
                <Dropdown
                  id="resource_type"
                  title=""
                  data={resourceDropdown}
                  onSelect={handleSelect}
                  selectedId={`${value}`}
                  bgColor="var(--color-grey-100)"
                />
              )}
            />
            {errors?.resource_type && (
              <ErrorMessage>{errors.resource_type.message}</ErrorMessage>
            )}
          </LabelAndInput>
          <LabelAndInput>
            <label>Name</label>
            <StyledInput
              type="text"
              {...register("staff")}
              defaultValue={details?.staff}
              autoComplete="off"
            />
          </LabelAndInput>
          <LabelAndInput>
            <label>Estimated Effort *</label>
            <Controller
              control={control}
              name="hours"
              rules={{ required: "This field is required" }}
              render={({ field: { onChange } }) => (
                <StyledEffortInput
                  id="hours"
                  placeholder="days"
                  allowDecimals
                  onValueChange={onChange}
                  suffix={" days"}
                  step={1}
                  defaultValue={details?.hours}
                  autoComplete="off"
                />
              )}
            />
            {errors?.hours && (
              <ErrorMessage>{errors.hours.message}</ErrorMessage>
            )}
          </LabelAndInput>
        </StyledThreeFieldSection>
        <StyledThreeFieldSection>
          <PositionedInput>
            <label>Start Date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("start_date")
                  ? moment(getValues("start_date")).format(
                      DAY_FORMATS.DAY_FIRST
                    )
                  : details?.start_date
                  ? moment(details?.start_date).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.start_date && (
              <ErrorMessage>{errors.start_date.message}</ErrorMessage>
            )}
            {getValues("start_date") || details?.start_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("start_date");
                  if (details) {
                    details.start_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.START);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="start_date"
              defaultValue={details?.start_date}
              rules={{
                min: {
                  value: getMinDate(isEditingMode),
                  message: "Please enter a valid start date",
                },
                max: {
                  value: getValues("end_date")
                    ? moment(getValues("end_date")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )
                    : "",
                  message: "Please enter a valid start date",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().start_date)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "start_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().start_date
                        ? new Date(getValues().start_date)
                        : new Date()
                    }
                    showOutsideDays
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <PositionedInput>
            <label>End date</label>
            <StyledInput
              aria-label="Date"
              type="text"
              value={
                getValues("end_date")
                  ? moment(getValues("end_date")).format(DAY_FORMATS.DAY_FIRST)
                  : details?.end_date
                  ? moment(details?.end_date).format(DAY_FORMATS.DAY_FIRST)
                  : ""
              }
              readOnly
            />
            {errors?.end_date && (
              <ErrorMessage>{errors.end_date.message}</ErrorMessage>
            )}
            {getValues("end_date") || details?.end_date ? (
              <ClearMessage
                onClick={() => {
                  resetField("end_date");
                  if (details) {
                    details.end_date = "";
                  }
                }}
              >
                Clear
              </ClearMessage>
            ) : (
              ""
            )}
            <PositionedCalendar>
              <Calendar
                onClick={() => {
                  setDayModalName(DATE_MODAL_NAMES.END);
                }}
              />
            </PositionedCalendar>
            <Controller
              control={control}
              name="end_date"
              defaultValue={details?.end_date}
              rules={{
                min: {
                  value:
                    (getValues("start_date") &&
                      moment(getValues("start_date")).format(
                        DAY_FORMATS.YEAR_FIRST
                      )) ||
                    getMinDate(isEditingMode),
                  message: "Please enter a valid end date",
                },
              }}
              render={({ field: { onChange, onBlur } }) => (
                <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                  <DayPicker
                    mode="single"
                    selected={new Date(getValues().end_date)}
                    onSelect={(e) => {
                      onChange(() =>
                        setValue(
                          "end_date",
                          moment(e).format(DAY_FORMATS.YEAR_FIRST)
                        )
                      );
                      setDayModalName("");
                    }}
                    showOutsideDays
                    onDayClick={onBlur}
                    captionLayout="dropdown"
                    endMonth={getEndMonth()}
                    defaultMonth={
                      getValues().end_date
                        ? new Date(getValues().end_date)
                        : new Date()
                    }
                  />
                </DayPickerModal>
              )}
            />
          </PositionedInput>
          <LabelAndInput>
            <label>Ongoing</label>
            <StyledInput
              type="text"
              {...register("ongoing")}
              defaultValue={details?.ongoing}
              autoComplete="off"
            />
          </LabelAndInput>{" "}
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default WorkforceForm;
