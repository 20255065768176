import styled from "styled-components";
import ArrowUpRight from "../../assets/ArrowUpRight.svg?react";
import BackButton from "../../assets/BackButton.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Edit from "../../assets/EditPencil.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Info from "../../assets/InfoSquare.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Paper from "../../assets/Paper.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import Search from "../../assets/Search.svg?react";

import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import Spinner from "../../ui/Spinner";
import ToggleButton from "../../ui/ToggleButton";
import ProfileLink from "../navigation/ProfileLink";
import { useGetMilestoneByProjectId } from "./useGetMilestoneByProjectId";
import { useGetProjectById } from "./useGetProjectById";

// import {
//   DragEndEvent,
//   PointerSensor,
//   useSensor,
//   useSensors,
// } from "@dnd-kit/core";
// import { arrayMove } from "@dnd-kit/sortable";
import moment from "moment";
import toast from "react-hot-toast";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
  MODAL_TYPE,
  PINNED_ITEM_TYPES,
  PROJECT_DETAILS_API_VALUES,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useActiveModalContext } from "../../context/ModalContext";
import { useGetProjectDetails } from "../../hooks/projects/useGetProjectDetails";
import { useGetProjectScope } from "../../hooks/projects/useProjectScope";
import { useGetProjectSuccess } from "../../hooks/projects/useProjectSuccess";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import {
  AddItemP,
  SectionTitle,
  StyledTableSection,
  StyledTableSectionPart,
} from "../../ui/FormElements";
import Modal from "../../ui/Modal";
import { useGetCurrentUser } from "../authentication/useGetCurrentUser";
import ConsultationForm from "../forms/ConsultationForm";
import DetailsForm from "../forms/DetailsForm";
import FinanceForm from "../forms/FinanceForm";
import ImpactForm from "../forms/ImpactForm";
import RiskForm from "../forms/RiskForm";
import ScopeForm from "../forms/ScopeForm";
import StakeholderForm from "../forms/StakeholderForm";
import SuccessMeasureForm from "../forms/SuccessMeasureForm";
import WorkforceForm from "../forms/WorkforceForm";
import MilestoneForm from "../milestones/MilestoneForm";
import TaskForm from "../tasks/TasksForm";
import CreateEditProject from "./CreateProject";
import {
  ConsultationTableRenderer,
  DetailsTableRenderer,
  FinanceTableRenderer,
  ImpactTableRenderer,
  MilestoneTableRenderer,
  RiskTableRenderer,
  ScopeTableRenderer,
  StakeholderTableRenderer,
  SuccessTableRenderer,
  TaskTableRenderer,
  WorkforceTableRenderer,
} from "./ProjectInfoTables";

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 4.2rem 0;
  align-items: center;
`;

const ProjectInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 2rem 0;
  align-items: end;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const Heading = styled.div`
  display: flex;
  gap: 2rem;
  & span {
    font-size: 1.4rem;
    font-weight: 400;
  }
  & h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const SummaryLayout = styled.div<{ $isSummaryExpanded: boolean }>`
  background-color: white;
  border-radius: var(--border-radius-xl);
  height: ${(props) => (props.$isSummaryExpanded ? "30rem" : "23rem")};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const ExpandContractContainer = styled.div<{ $isSummaryExpanded: boolean }>`
  justify-self: end;
  align-self: flex-end;
  cursor: pointer;
  & svg:hover {
    transform: ${(props) =>
      props.$isSummaryExpanded ? "scale(0.9)" : "scale(1.1)"};
  }
`;

// todo: make this reusable
const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledRefNo = styled.p`
  font-size: 1.2rem;
  color: var(--color-ref-no);
`;

const StyledProjectTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 3px dashed #ebeff6;
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const ToggleDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Toggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
  width: fit-content;
  margin-bottom: 2rem;
`;

const TOGGLE_MAP = {
  INFO: "Information",
  DETAILS: "Details",
  RISKS: "Consultation/Risks",
  RESOURCES: "Resources",
  MILESTONES: "Milestones",
  CLOSURE: "Closure",
  TASKS: "Tasks",
};

/**
 * @returns individual project summary by project ID
 */
const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setCurrentModalName } = useActiveModalContext();

  const activeToggle = searchParams.get("activeToggle") || TOGGLE_MAP.DETAILS;

  const handleSearchParams = (value: string) => {
    searchParams.set("activeToggle", value);
    setSearchParams(searchParams);
  };

  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  // API data
  const { isLoading: isSavingPin, pinItem } = useSavePinItem();
  const { isLoading: isRemovingPin, removePinItem } = useRemovePinItem();
  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || ""
  );
  const pinnedProjectIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.PROJECT)
        return { value: item.value, id: item.id };
    }) || [];

  const { isLoading: isProjectLoading, project } = useGetProjectById(
    projectId || "1",
    pinnedProjectIds
  );
  const {
    isLoading: isMilestoneLoading,
    milestones,
    error,
  } = useGetMilestoneByProjectId(projectId || "1");

  const {
    isDetailLoading: isRationaleLoading,
    projectDetails: rationaleDetails,
    error: rationaleError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.BACKGROUND
  );

  const {
    isDetailLoading: isGoalsLoading,
    projectDetails: goalsDetails,
    error: goalsError,
  } = useGetProjectDetails(projectId || "0", PROJECT_DETAILS_API_VALUES.GOALS);

  const {
    isDetailLoading: isSolutionsLoading,
    projectDetails: solutionsDetails,
    error: solutionsError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.SOLUTION
  );

  const {
    isDetailLoading: isAssumptionsLoading,
    projectDetails: assumptionsDetails,
    error: assumptionsError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.ASSUMPTION
  );

  const {
    isDetailLoading: isDependenciesLoading,
    projectDetails: dependenciesDetails,
    error: dependenciesError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.DEPENDENCY
  );

  const {
    isDetailLoading: isConstraintsLoading,
    projectDetails: constraintsDetails,
    error: constraintsError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.CONSTRAINT
  );

  const {
    isScopeLoading,
    scopeDetails,
    error: scopeError,
  } = useGetProjectScope(projectId || "0");

  const {
    isSuccessLoading,
    successDetails,
    error: successMeasureError,
  } = useGetProjectSuccess(projectId || "0");

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLSpanElement>,
    pinId?: number
  ) => {
    event?.stopPropagation();
    handlePinningFromContext(type, value, savePin, pinId);
  };

  const handlePinningFromContext = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        }
      );
    } else if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        }
      );
    }
  };

  const disablePin = isSavingPin || isRemovingPin;

  if (
    isProjectLoading ||
    isMilestoneLoading ||
    isPinnedItemsLoading ||
    isRationaleLoading ||
    isGoalsLoading ||
    isSolutionsLoading ||
    isAssumptionsLoading ||
    isDependenciesLoading ||
    isConstraintsLoading ||
    isScopeLoading ||
    isSuccessLoading
  )
    return <Spinner />;

  return (
    <>
      <ProjectHeader>
        <Heading>
          <Icons onClick={() => navigate(-1)}>
            <BackButton />
          </Icons>
          <div>
            <h1>
              Projects <span>| Details </span>
            </h1>
            <h2>{project?.title}</h2>
          </div>
        </Heading>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            $buttonSize={BUTTON_SIZES.MEDIUM_LARGE}
            onClick={() => navigate(`/projects/overview/${projectId}`)}
          >
            <span>Project Overview</span>
            <ArrowUpRight />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <ProjectInfoHeader>
        <h1>Project Information</h1>
        <SideContainer>
          {project?.isPinned ? (
            <Icons
              onClick={(event) =>
                handlePinning(
                  PINNED_ITEM_TYPES.PROJECT,
                  `${project?.id}`,
                  false,
                  event,
                  project?.pinId
                )
              }
              disabled={disablePin}
            >
              <PinFilled />
            </Icons>
          ) : (
            <Icons
              onClick={(event) =>
                handlePinning(
                  PINNED_ITEM_TYPES.PROJECT,
                  `${project?.id}`,
                  true,
                  event
                )
              }
              disabled={disablePin}
            >
              <PushPin />
            </Icons>
          )}
          <Icons
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.PROJECT);
              setIsProjectDetailsOpen(true);
            }}
          >
            <Edit />{" "}
          </Icons>
          <Icons>
            <Paper />{" "}
          </Icons>
        </SideContainer>
        <Modal
          isOpen={isProjectDetailsOpen}
          wrapperId={MODAL_NAMES.PROJECT}
          title={MODAL_NAMES.PROJECT}
          type={MODAL_TYPE.EDIT}
        >
          <CreateEditProject
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            projectDetails={project}
            isEditingMode
          />
        </Modal>
      </ProjectInfoHeader>
      {/* project basic information */}
      <SummaryLayout $isSummaryExpanded={summaryExpanded}>
        <TitleSection>
          <div>
            <StyledRefNo>{project?.businesscase_id}</StyledRefNo>
            <StyledProjectTitle> {project?.title} </StyledProjectTitle>
          </div>
          <Pill
            $progressStatus={
              project?.status?.title as keyof typeof SCHEDULE_MAP
            }
          >
            <span>{project?.status?.title}</span>
          </Pill>
        </TitleSection>
        <p>{project?.project_definition}</p>
        <ProjectInfoGrid>
          <ProjectGridItem>
            <h2>Start Date</h2>
            <h1>
              {project?.start_year
                ? moment(project?.start_year).format("DD/MM/YYYY")
                : "N/A"}
            </h1>
          </ProjectGridItem>
          <ProjectGridItem>
            <h2>End Date</h2>
            <h1>
              {project?.end_year
                ? moment(project?.end_year).format("DD/MM/YYYY")
                : "N/A"}
            </h1>
          </ProjectGridItem>
          <ProjectGridItem>
            <h2>Percentage Complete</h2>
            <h1>{project?.percentage_complete}</h1>
          </ProjectGridItem>
          <ProjectGridItem>
            <h2>Project Owner</h2>
            <h1>{`${project?.owner.first_name} ${project?.owner.last_name}`}</h1>
          </ProjectGridItem>
          <ProjectGridItem>
            <h2>Project Manager</h2>
            <h1>{`${project?.responsible.first_name} ${project?.responsible.last_name}`}</h1>
          </ProjectGridItem>
          {summaryExpanded && (
            <>
              <ProjectGridItem>
                <h2>Program</h2>
                <h1>Program</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Type</h2>
                <h1>{project?.project_type.title}</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Location</h2>
                <h1>{project?.location}</h1>
              </ProjectGridItem>
            </>
          )}
        </ProjectInfoGrid>
        <ExpandContractContainer
          $isSummaryExpanded={summaryExpanded}
          onClick={() => setSummaryExpanded((prev) => !prev)}
        >
          {summaryExpanded ? <Contract /> : <Expand />}
        </ExpandContractContainer>
      </SummaryLayout>
      <ToggleDiv>
        <Toggles>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.DETAILS}
            onClick={() => handleSearchParams(TOGGLE_MAP.DETAILS)}
          >
            {TOGGLE_MAP.DETAILS}
          </ToggleButton>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.RISKS}
            onClick={() => handleSearchParams(TOGGLE_MAP.RISKS)}
            $buttonSize="xl"
          >
            {TOGGLE_MAP.RISKS}
          </ToggleButton>{" "}
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.RESOURCES}
            onClick={() => handleSearchParams(TOGGLE_MAP.RESOURCES)}
          >
            {TOGGLE_MAP.RESOURCES}
          </ToggleButton>{" "}
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.MILESTONES}
            onClick={() => handleSearchParams(TOGGLE_MAP.MILESTONES)}
          >
            {TOGGLE_MAP.MILESTONES}
          </ToggleButton>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.TASKS}
            onClick={() => handleSearchParams(TOGGLE_MAP.TASKS)}
          >
            {TOGGLE_MAP.TASKS}
          </ToggleButton>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.CLOSURE}
            onClick={() => handleSearchParams(TOGGLE_MAP.CLOSURE)}
          >
            {TOGGLE_MAP.CLOSURE}
          </ToggleButton>
        </Toggles>
      </ToggleDiv>
      {/* project details */}
      {activeToggle === TOGGLE_MAP.DETAILS && (
        <>
          {/* bg/rationale */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.RATIONALE}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.RATIONALE);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.BACKGROUND}
              detailName={MODAL_NAMES.RATIONALE}
              projectDetails={rationaleDetails}
              error={rationaleError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.RATIONALE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.RATIONALE}
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.BACKGROUND}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  rationaleDetails ? rationaleDetails.slice(-1)[0].sequence : 0
                }
              />
            </Modal>
          </StyledTableSection>
          {/* goals */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.GOALS}</h1>
                <Info />
              </SideContainer>
              <div>
                <AddItemP
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.GOALS);
                    setIsProjectDetailsOpen(true);
                  }}
                >
                  + Add
                </AddItemP>
              </div>
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.GOALS}
              detailName={MODAL_NAMES.GOALS}
              projectDetails={goalsDetails}
              error={goalsError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.GOALS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.GOALS}
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.GOALS}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  goalsDetails ? goalsDetails.slice(-1)[0].sequence : 0
                }
              />
            </Modal>
          </StyledTableSection>
          {/* scopes */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.SCOPE}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.SCOPE);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <ScopeTableRenderer
              projectId={projectId || "0"}
              scopeDetails={scopeDetails}
              error={scopeError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.SCOPE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.SCOPE}
            >
              <ScopeForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                detailLength={
                  scopeDetails ? scopeDetails.slice(-1)[0].sequence : 0
                }
              />
            </Modal>
          </StyledTableSection>
          {/* solutions */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.SOLUTIONS}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.SOLUTIONS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.SOLUTION}
              detailName={MODAL_NAMES.SOLUTIONS}
              projectDetails={solutionsDetails}
              error={solutionsError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.SOLUTIONS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.SOLUTIONS}
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.SOLUTION}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  solutionsDetails ? solutionsDetails.slice(-1)[0].sequence : 0
                }
              />
            </Modal>
          </StyledTableSection>
          {/* assumptions */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.ASSUMPTIONS}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.ASSUMPTIONS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.ASSUMPTION}
              detailName={MODAL_NAMES.ASSUMPTIONS}
              projectDetails={assumptionsDetails}
              error={assumptionsError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.ASSUMPTIONS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.ASSUMPTIONS}
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.ASSUMPTION}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  assumptionsDetails
                    ? assumptionsDetails.slice(-1)[0].sequence
                    : 0
                }
              />
            </Modal>
          </StyledTableSection>{" "}
          {/* dependencies */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.DEPENDENCIES}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.DEPENDENCIES);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.DEPENDENCY}
              detailName={MODAL_NAMES.DEPENDENCIES}
              projectDetails={dependenciesDetails}
              error={dependenciesError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.DEPENDENCIES}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.DEPENDENCIES}
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.DEPENDENCY}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  dependenciesDetails
                    ? dependenciesDetails.slice(-1)[0].sequence
                    : 0
                }
              />
            </Modal>
          </StyledTableSection>{" "}
          {/* constraints */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.CONSTRAINTS}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.CONSTRAINTS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.CONSTRAINT}
              detailName={MODAL_NAMES.CONSTRAINTS}
              projectDetails={constraintsDetails}
              error={constraintsError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.CONSTRAINTS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.CONSTRAINTS}
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.CONSTRAINT}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  constraintsDetails
                    ? constraintsDetails.slice(-1)[0].sequence
                    : 0
                }
              />
            </Modal>
          </StyledTableSection>
          {/* measure success */}
          <StyledTableSection>
            <SectionTitle>
              <SideContainer>
                <h1>{MODAL_NAMES.SUCCESS}</h1>
                <Info />
              </SideContainer>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.SUCCESS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <SuccessTableRenderer
              projectId={projectId || "0"}
              successDetails={successDetails}
              error={successMeasureError}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.SUCCESS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.SUCCESS}
            >
              <SuccessMeasureForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                detailLength={
                  successDetails ? successDetails.slice(-1)[0].sequence : 0
                }
              />
            </Modal>
          </StyledTableSection>
        </>
      )}
      {/* consultations and risks */}
      {activeToggle === TOGGLE_MAP.RISKS && (
        <StyledTableSection>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.STAKEHOLDERS}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.STAKEHOLDERS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <StakeholderTableRenderer projectId={projectId || "0"} />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.STAKEHOLDERS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.STAKEHOLDERS}
            >
              <StakeholderForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
              />
            </Modal>
          </StyledTableSectionPart>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.CONSULTATION}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.CONSULTATION);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <ConsultationTableRenderer projectId={projectId || "0"} />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.CONSULTATION}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.CONSULTATION}
            >
              <ConsultationForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
              />
            </Modal>
          </StyledTableSectionPart>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.RISKS}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.RISKS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <RiskTableRenderer projectId={projectId || "0"} />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.RISKS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.RISKS}
            >
              <RiskForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
              />
            </Modal>
          </StyledTableSectionPart>
          <SectionTitle>
            <h1>{MODAL_NAMES.IMPACTS}</h1>
            <AddItemP
              onClick={() => {
                setCurrentModalName(MODAL_NAMES.IMPACTS);
                setIsProjectDetailsOpen(true);
              }}
            >
              + Add
            </AddItemP>
          </SectionTitle>
          <ImpactTableRenderer projectId={projectId || "0"} />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.IMPACTS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.IMPACTS}
          >
            <ImpactForm
              projectId={projectId || "0"}
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              isEditingMode={false}
            />
          </Modal>
        </StyledTableSection>
      )}
      {/* resources and finances */}
      {activeToggle === TOGGLE_MAP.RESOURCES && (
        <StyledTableSection>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.FINANCE}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.FINANCE);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <FinanceTableRenderer projectId={projectId || "0"} />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.FINANCE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.FINANCE}
            >
              <FinanceForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
              />
            </Modal>
          </StyledTableSectionPart>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.WORKFORCE}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.WORKFORCE);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <WorkforceTableRenderer projectId={projectId || "0"} />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.WORKFORCE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.WORKFORCE}
            >
              <WorkforceForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
              />
            </Modal>
          </StyledTableSectionPart>
        </StyledTableSection>
      )}
      {activeToggle === TOGGLE_MAP.MILESTONES && (
        <StyledTableSection>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.MILESTONES}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.MILESTONES);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <MilestoneTableRenderer
              projectId={projectId || "0"}
              milestones={milestones}
              error={error}
            />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.MILESTONES}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.MILESTONES}
            >
              <MilestoneForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
              />
            </Modal>
          </StyledTableSectionPart>
        </StyledTableSection>
      )}
      {activeToggle === TOGGLE_MAP.TASKS && (
        <StyledTableSection>
          <StyledTableSectionPart>
            <SectionTitle>
              <h1>{MODAL_NAMES.TASKS}</h1>
              <AddItemP
                onClick={() => {
                  setCurrentModalName(MODAL_NAMES.TASKS);
                  setIsProjectDetailsOpen(true);
                }}
              >
                + Add
              </AddItemP>
            </SectionTitle>
            <TaskTableRenderer milestones={milestones} />
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.TASKS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.TASKS}
            >
              <TaskForm
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                milestones={milestones}
              />
            </Modal>
          </StyledTableSectionPart>
        </StyledTableSection>
      )}
    </>
  );
};

export default ProjectDetails;
