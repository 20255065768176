import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNoData = styled.div<{ height?: string }>`
  height: ${(props) => props.height || "100%"};
  min-height: 8rem;
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 2rem;
  font-size: 1.4rem;
  font-style: italic;
  color: var(--color-no-data-text);
  background-color: var(--color-no-data-bg);
  border-radius: var(--border-radius-xl);
  cursor: pointer;

  & a {
    font-weight: 700;
    text-decoration: underline;
  }
  gap: 0.5rem;
`;

const NoDataCard = ({
  title,
  createLink,
  height,
  buttonLinked,
  handleClick
}: {
  title: string;
  createLink?: string;
  height?: string;
  buttonLinked?: boolean;
  handleClick?: () => void;
}) => {
  // todo: verify all create links being sent
  return (
    <StyledNoData height={height} onClick={handleClick}>
      {!buttonLinked && <p>No {title} to show currently.</p>}
      {buttonLinked && <span>Click to create.</span>}
      {createLink && <Link to={createLink}>{` Create >`}</Link>}
    </StyledNoData>
  );
};

export default NoDataCard;
