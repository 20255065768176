import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
    deleteProjectDetailsAPI
} from "../../services/apiProjects";

type DeleteDetail = {
  detailId?: number;
};

export const useDeleteProjectDetails = (type: string) => {
    const queryClient = useQueryClient();
    const {
      isPending: isDeleting,
      mutate: deleteProjectDetails,
      error,
    } = useMutation({
      mutationFn: ({ detailId }: DeleteDetail) =>
        deleteProjectDetailsAPI(detailId || 0, type),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PROJECT_DETAILS, type],
        });
      },
    });
  
    return { isDeleting, deleteProjectDetails, error };
  };