import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const StyledOverlay = styled.div`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-xl);
  margin: 0.8rem 0.8rem 0.8rem 0;
`;

const PaddingDiv = styled.div`
  padding: 4.2rem 3.5rem;
  height: 100%;
`;

/**
 *
 * @returns the main overlay for displaying data
 */
const ProjectOverlay: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledOverlay>
      <PaddingDiv>{children}</PaddingDiv>
    </StyledOverlay>
  );
};

export default ProjectOverlay;
