import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import { getAllContactsAPI } from "../services/apiContacts";

export const useGetContacts = () => {
  const {
    isPending: isContactsLoading,
    data: contacts,
    error,
  } = useQuery({
    queryFn: () => getAllContactsAPI({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.CONTACTS],
  });

  return { isContactsLoading, contacts, error };
};
