import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectRiskAPI,
  deleteProjectRiskAPI,
  getProjectRisksAPI,
  updateProjectRiskAPI,
} from "../../services/apiProjects";
import { UpsertProjectRisk } from "../../types/projectTypes";

export const useGetProjectRisks = (id: string) => {
  const {
    isPending: isRisksLoading,
    data: riskDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectRisksAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_RISKS, id],
    retry: false,
  });

  return { isRisksLoading, riskDetails, error };
};

export const useCreateProjectRisk = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectRisk,
    error,
  } = useMutation({
    mutationFn: ({
      riskDetails,
    }: {
      riskDetails: UpsertProjectRisk;
    }) => createProjectRiskAPI(riskDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_RISKS],
      });
    },
  });

  return { isCreating, createProjectRisk, error };
};

export const useEditProjectRisk = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectRisk,
    error,
  } = useMutation({
    mutationFn: ({
      riskDetails,
      detailId,
    }: {
      riskDetails: UpsertProjectRisk;
      detailId: number;
    }) => updateProjectRiskAPI(riskDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_RISKS],
      });
    },
  });

  return { isEditing, editProjectRisk, error };
};

export const useDeleteProjectRisk = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectRisk,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectRiskAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_RISKS],
      });
    },
  });

  return { isDeleting, deleteProjectRisk, error };
};
