import { SubmitHandler, useForm } from "react-hook-form";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";
import { SuccessMeasureFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useCreateProjectSuccess,
  useEditProjectSuccess,
} from "../../hooks/projects/useProjectSuccess";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
  StyledTwoFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";

const SuccessMeasureForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  detailLength = 0,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: SuccessMeasureFormValues;
  detailLength: number;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<SuccessMeasureFormValues>();

  useEffect(() => {
    setFocus("description");
  }, [setFocus]);

  const { isCreating, createProjectSuccessMeasure } = useCreateProjectSuccess();
  const { isEditing, editProjectSuccessMeasure } = useEditProjectSuccess();

  const onSubmit: SubmitHandler<SuccessMeasureFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectSuccessMeasure(
        {
          successDetails: {
            ...data,
            project_id: +projectId,
            section_id: null,
            sequence: detailLength + 1,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project success measure saved successfully.`);
            handleClose();
          },
        }
      );
    } else {
      editProjectSuccessMeasure(
        {
          successDetails: {
            ...data,
            project_id: +projectId,
            section_id: null,
            sequence: details?.sequence || 1,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project success measure saved successfully.`);
            handleClose();
          },
        }
      );
    }
  };

  if (isEditing || isCreating) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSection>
        <StyledTwoFieldSection>
          <LabelAndInput>
            <label>Source of Benefit</label>
            <StyledInput
              type="text"
              {...register("source_of_benefit")}
              autoComplete="off"
              defaultValue={details?.source_of_benefit}
            />
          </LabelAndInput>{" "}
        </StyledTwoFieldSection>
        <StyledThreeFieldSection>
          <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label>Description *</label>
            <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
              <StyledFullWidthInput
                height={isBackgroundFieldExpanded ? "20rem" : ""}
                {...register("description", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                defaultValue={details?.description}
                autoComplete="off"
              />
              <PositionedSvg
                onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
              >
                {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
              </PositionedSvg>
            </ExpandableDiv>
            {errors?.description && (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            )}
          </LabelAndInput>
        </StyledThreeFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA $buttonSize="small" type="button" onClick={handleClose}>
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA $buttonSize="small" $buttonType="secondary" type="submit">
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
    </form>
  );
};

export default SuccessMeasureForm;
