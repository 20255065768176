import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import { getAllServiceValuesAPI } from "../services/apiStatus";

export const useGetServiceValues = () => {
  const {
    isPending: isServiceLoading,
    data: services,
    error,
  } = useQuery({
    queryFn: () => getAllServiceValuesAPI({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.SERVICE],
  });

  return { isServiceLoading, services, error };
};
