import CurrencyDollar from "../assets/CurrencyDollar.svg?react";
import ProjectsLogo from "../assets/ProjectsOutline.svg?react";
import Drafts from "../assets/FileDashed.svg?react";
import WOLogo from "../assets/WOLogo.svg?react";
import Risks from "../assets/Risks.svg?react";
import TaskIcon from "../assets/TaskIcon.svg?react";
import MilestoneIcon from "../assets/MilestoneIcon.svg?react";
import ResourcesIcon from "../assets/ResourcesIcon.svg?react";

export const quickLinksMap = [
  {
    title: "Projects",
    to: "/projects",
    color: "#F0C015",
    imageComponent: () => ProjectsLogo,
  },
  {
    title: "Milestones",
    to: "/milestones",
    color: "#4DB7E4",
    imageComponent: () => MilestoneIcon,
  },
  {
    title: "Tasks",
    to: "/tasks",
    color: "#F57DCC",
    imageComponent: () => TaskIcon,
  },
  {
    title: "Finance",
    to: "",
    color: "#A5CC21",
    imageComponent: () => CurrencyDollar,
  },
  { title: "Drafts", to: "", color: "#F7A040", imageComponent: () => Drafts },

  {
    title: "Resources",
    to: "/resources",
    color: "#7573E2",
    imageComponent: () => ResourcesIcon,
  },
  { title: "Risks", to: "", color: "#FE642B", imageComponent: () => Risks },

  {
    title: "Worx.online",
    to: "",
    color: "#35A893",
    imageComponent: () => WOLogo,
  },
];
