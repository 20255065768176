import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import toast, { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { OverviewActiveContextProvider } from "./context/OverviewActiveContext";

import { DayModalContextProvider } from "./context/DayPickerModalContext";
import { ActiveModalContextProvider } from "./context/ModalContext";
import Documents from "./pages/Documents";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Milestones from "./pages/Milestones";
import Programs from "./pages/Programs";
import ProjectDetailsPage from "./pages/ProjectDetails";
import ProjectOverviewPage from "./pages/ProjectOverview";
import Projects from "./pages/Projects";
import Reports from "./pages/Reports";
import Tasks from "./pages/Tasks";
import GlobalStyles from "./styles/GlobalStyles";
import AppLayout from "./ui/AppLayout";
import ProtectedRoute from "./ui/ProtectedRoute";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000, // 30mins stale time
      retry: false,
      refetchOnWindowFocus: false
    },
    mutations: {
      onError: () => toast.error(`Something wasn't right 🫣, please retry.`),
    },
  },
});

function App() {
  return (
    <>
      <OverviewActiveContextProvider>
        <ActiveModalContextProvider>
          <DayModalContextProvider>
            <Toaster
              position="top-center"
              gutter={12}
              containerStyle={{ margin: "8px" }}
              toastOptions={{
                success: {
                  duration: 3000,
                },
                error: {
                  duration: 3000,
                },
                style: {
                  border: "2px solid #EB3C7C",
                  padding: "16px",
                  color: "var(--color-grey-900)",
                  backgroundColor: "#FFE8F0",
                },
                iconTheme: {
                  primary: "#EB3C7C",
                  secondary: "#FFFAEE",
                },
              }}
            />
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <GlobalStyles />
              <BrowserRouter>
                <Routes>
                  <Route path="login" element={<Login />} />
                  <Route
                    element={
                      <ProtectedRoute>
                        <AppLayout />
                      </ProtectedRoute>
                    }
                  >
                    <Route
                      index
                      element={<Navigate replace to="home" />}
                    ></Route>
                    <Route path="home" element={<Home />} />
                    <Route path="programs" element={<Programs />} />
                    <Route path="projects" element={<Projects />} />
                    <Route
                      path="projects/overview/:projectId"
                      element={<ProjectOverviewPage />}
                    />
                    <Route
                      path="projects/details/:projectId"
                      element={<ProjectDetailsPage />}
                    />
                    <Route path="milestones" element={<Milestones />} />
                    <Route path="tasks" element={<Tasks />} />
                    <Route path="reports" element={<Reports />} />
                    <Route path="documents" element={<Documents />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </QueryClientProvider>
          </DayModalContextProvider>
        </ActiveModalContextProvider>
      </OverviewActiveContextProvider>
    </>
  );
}

export default App;
