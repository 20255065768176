import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createProjectAPI,
  getAllProjectsAPI,
  updateProjectAPI,
} from "../../services/apiProjects";
import { QUERY_KEYS } from "../../common/Constants";
import { useCallback } from "react";
import { Project, UpsertProject } from "../../types/projectTypes";

export const useProjects = (
  pinnedProjectIds?: ({ value: string; id: number } | undefined)[],
  limit = -1
) => {
  // todo: modify to fetch all, or accept limit
  const {
    isPending: isLoading,
    data: projects,
    error,
  } = useQuery({
    queryFn: () => getAllProjectsAPI({ skip: 0, limit }),
    queryKey: [QUERY_KEYS.PROJECTS, limit],
    placeholderData: keepPreviousData,
    select: useCallback(
      (data: Project[]) =>
        data?.map((project) => {
          const pin = pinnedProjectIds?.find(
            (item) => item?.value && +item.value === project.id
          );
          return {
            ...project,
            isPinned: !!pin?.value,
            pinId: pin && pin.id,
          };
        }),
      [pinnedProjectIds]
    ),
  });

  return { isLoading, projects, error };
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProject,
    error,
  } = useMutation({
    mutationFn: ({ projectDetails }: { projectDetails: UpsertProject }) =>
      createProjectAPI(projectDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT],
      });
    },
  });

  return { isCreating, createProject, error };
};

export const useEditProject = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProject,
    error,
  } = useMutation({
    mutationFn: ({
      projectDetails,
      projectId,
    }: {
      projectDetails: UpsertProject;
      projectId: number;
    }) => updateProjectAPI(projectId, projectDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT],
      });
    },
  });

  return { isEditing, editProject, error };
};
