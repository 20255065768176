import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import { getProjectByProjectIdAPI } from "../../services/apiProjects";
import { Project } from "../../types/projectTypes";

export const useGetProjectById = (
  id: string,
  pinnedProjectIds?: ({ value: string; id: number } | undefined)[]
) => {
  const {
    isPending: isLoading,
    data: project,
    error,
  } = useQuery({
    queryFn: () => getProjectByProjectIdAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT, id],
    select: (data: Project) => {
      const pin = pinnedProjectIds?.find(
        (item) => item?.value && +item.value === data.id
      );
      return {
        ...data,
        isPinned: !!pin?.value,
        pinId: pin && pin.id,
      };
    },
  });

  return { isLoading, project, error };
};
