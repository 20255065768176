import { DropdownType } from "../types/dropdownTypes";
import { ProjectTypesType } from "../types/statusTypes";
import { client } from "./AxiosClient";

export const getAllDropdownsAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_DROPDOWN_ITEMS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as DropdownType[];
};

export const getProjectTypeDropdown = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_PROJECT_TYPE, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as ProjectTypesType[];
};
